import React, { useEffect, useState } from "react";
import { getWalletHisory } from "../../redux/features/user/actions";
import NothingToShow from "../globals/NothingToShow";
import moment from "jalali-moment";
import { Table } from "react-bootstrap";
import BoxLoading from "../globals/BoxLoading";

function WalletHistory({ wallet_id }) {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHistory = async () => {
    // start loading
    setLoading(true);

    // request
    await getWalletHisory(wallet_id).then((res) => {
      if (res) {
        setHistory(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div>
      {history.length ? (
        <Table responsive borderless className="w-100 wallet-table mb-0">
          <thead>
            <tr>
              <th>شناسه</th>
              <th>تغییر</th>
              <th>پس از تغییر</th>
              <th>تاریخ</th>
              <th>توضیحات</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => (
              <tr key={item.id}>
                <td>{item.id}#</td>
                <td className={`text-${+item.increase ? "success" : "danger"}`}>
                  {+item.increase || +item.decrease}
                  {+item.increase ? "+" : "-"}
                </td>
                <td>{item.amount}</td>
                <td>
                  <div className="max-content">
                    {moment(item.created_at, "YYYY/MM/DD")
                      .locale("fa")
                      .format("DD MMMM YYYY")}

                    <small>
                      {moment(item.created_at, "YYYY/MM/DD hh:mm")
                        .locale("fa")
                        .format("hh:mm")}
                    </small>
                  </div>
                </td>

                <td className="description">{item.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <>
          <NothingToShow text="موردی برای نمایش وجود ندارد" />
        </>
      )}

      {loading && <BoxLoading />}
    </div>
  );
}

export default WalletHistory;
