import React, { useState } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";

//
import useCurrency from "../../hooks/useCurrency";

//
import NumberFormat from "react-number-format";

//
import { chargeUserWallet } from "../../redux/features/user/actions";

function WalletCard({ item, openConvertModal }) {
  const [currencyTitle] = useCurrency();

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const chargeWallet = async () => {
    // start loading
    setLoading(true);

    // variable
    const id = item.id;
    let content = {
      amount: amount,
    };

    // request
    await chargeUserWallet({ id, content }).then((res) => {
      if (res) {
        localStorage.setItem("paymentBackPage", "/panel/wallets");
        localStorage.setItem("paymentBackPageText", "کیف پول ها");
        window.location = res.paymentUrl;
      }
    });

    // stop loading
    setLoading(false);
  };

  return (
    <Card className="wallet-card">
      <Card.Body className="p-4">
        <div className="d-flex align-items-center justify-content-between title">
          <h5 className="text-white ">{item.currency.title}</h5>

          <small className="text-white pointer" onClick={() => openConvertModal(item.id)}>تبدیل موجودی</small>
        </div>

        <p className="wallet-item-title text-white">
          موجودی {item.currency.postfix}:{" "}
          <NumberFormat
            value={+item.amount}
            displayType="text"
            thousandSeparator={true}
            className="price text-bold"
          />{" "}
          {item.currency.title}
        </p>
        <small className="convert-amount text-white">
          معادل{" "}
          <NumberFormat
            value={+item.convert_amount}
            displayType="text"
            thousandSeparator={true}
            className="price text-bold"
          />{" "}
          {currencyTitle()}
        </small>

        <div className="w-100 mt-4 mb-3">
          <Form.Control
            type="number"
            min="0.0000001"
            step="0.0000001"
            className="form-control-solid number"
            autoComplete="off"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            readOnly={item.currency.status === "disable"}
          />
          <small className="text-white">
            معادل{" "}
            <NumberFormat
              value={+amount * +item.currency.convert_rate}
              displayType="text"
              thousandSeparator={true}
            />{" "}
            {currencyTitle()}
          </small>
        </div>

        <Button
          variant="white"
          className="w-100"
          disabled={loading || !+amount}
          onClick={() => chargeWallet()}
        >
          افزایش اعتبار
          {loading && (
            <div className="spinner-holder">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          )}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default WalletCard;
