import React from "react";
import PropTypes from "prop-types";

//
import { Card } from "react-bootstrap";

//
import Delivery from "../../assets/img/delivery-box.svg";

function DeliveryType(props) {
  return (
    <Card className="delivery-box">
      <Card.Body>
        <img src={Delivery} alt="delivery" />
        <div>
          <small className="text-muted">توسط تامین کننده</small>
          <small className="text-bold">از 2 تا 5 روز کاری</small>
        </div>
      </Card.Body>
    </Card>
  );
}

DeliveryType.propTypes = {};

export default DeliveryType;
