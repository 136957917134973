import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//
import { Accordion, Col, Container, Row } from "react-bootstrap";

//
import Trust from "../../assets/img/trust-icone.png";

function WhyTrust({ configs }) {
  return (
    <div className="why-trust">
      <Container>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <img src={Trust} alt="trust" />
              چرا به {configs.BRAND_NAME} اعتماد کنیم ؟
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={6} className="item">
                  <Row>
                    <Col md={10}>
                      <p className="title">
                        <i className="fas fa-circle"></i>
                        ضمانت بازگشت وجه تا 7 روز
                      </p>
                      <p className="subject">
                        در صورتیکه محصول دارای آسیب باشد، یا مغایر با محصول
                        خریداری شده یا معیوب باشد، خریداران 7 روز مهلت بازگشت
                        محصول و دریافت وجه را خواهند داشت.
                      </p>
                    </Col>
                    <Col md={2}>
                      <img
                        src={Trust}
                        alt="trust"
                        className="img-fluid d-block m-auto"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6} className="item">
                  <Row>
                    <Col md={10}>
                      <p className="title">
                        <i className="fas fa-circle"></i>
                        ضمانت بازگشت وجه تا 7 روز
                      </p>
                      <p className="subject">
                        در صورتیکه محصول دارای آسیب باشد، یا مغایر با محصول
                        خریداری شده یا معیوب باشد، خریداران 7 روز مهلت بازگشت
                        محصول و دریافت وجه را خواهند داشت.
                      </p>
                    </Col>
                    <Col md={2}>
                      <img
                        src={Trust}
                        alt="trust"
                        className="img-fluid d-block m-auto"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6} className="item">
                  <Row>
                    <Col md={10}>
                      <p className="title">
                        <i className="fas fa-circle"></i>
                        ضمانت بازگشت وجه تا 7 روز
                      </p>
                      <p className="subject">
                        در صورتیکه محصول دارای آسیب باشد، یا مغایر با محصول
                        خریداری شده یا معیوب باشد، خریداران 7 روز مهلت بازگشت
                        محصول و دریافت وجه را خواهند داشت.
                      </p>
                    </Col>
                    <Col md={2}>
                      <img
                        src={Trust}
                        alt="trust"
                        className="img-fluid d-block m-auto"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
}

WhyTrust.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(WhyTrust);
