import React from "react";
import PropTypes from "prop-types";

//
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletopBox({ height, width, borderRadius = 0 }) {
  return (
    <SkeletonTheme baseColor="#b9b9b9" highlightColor="#fff">
      <Skeleton borderRadius={borderRadius} height={height} width={width} />
    </SkeletonTheme>
  );
}

SkeletopBox.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  borderRadius: PropTypes.number,
};

export default SkeletopBox;
