import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//
import moment from "jalali-moment";
import { Col, Form, Row, Button } from "react-bootstrap";

//
import BoxLoading from "../globals/BoxLoading";
import Pagination from "../globals/Pagination.jsx";
import NothingToShow from "../globals/NothingToShow.jsx";

//
import {
  getNotifications,
  getUnreadNotifications,
  changeNotificationStatus
} from "../../redux/features/notification/actions";

import { changeNotificationCount } from "../../redux/features/notification/slice";


function NotificationList({ unreaCount, changeNotificationCount }) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [notifications, setNotifications] = useState({});

  const changeStatus = (newstatus) => {
    setStatus(newstatus);

    get_notifications(1, newstatus);
  };

  const setRead = (id) => {
    changeNotificationStatus(id).then(res => {
      if (res) {
        changeNotificationCount(unreaCount - 1);

        // change store
        let notList = {...notifications}

        for (let i = 0; i < notList.data.length; i++) {
          const element = notList.data[i];
          if (element.id == id) {
            element.is_read = 1
            break
          }
        }

        setNotifications(notList)
      }
    })
  };

  const get_notifications = async (page, currentStatus = status) => {
    // start loading
    setLoading(true);

    // variable
    let params = new URLSearchParams();
    params.set("page", page);

    // request
    if (currentStatus) {
      await getUnreadNotifications(params).then((res) => {
        setNotifications(res);
      });
    } else {
      await getNotifications(params).then((res) => {
        setNotifications(res);
      });
    }

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_notifications(1);
  }, []);

  return (
    <div>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Check // prettier-ignore
            type="switch"
            label="فقط خوانده نشده ها"
            checked={status}
            onChange={(e) => changeStatus(!status)}
          />
        </Col>
      </Row>
      {notifications.data && notifications.data.length ? (
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th style={{ minWidth: "120px", width: "120px" }}>موضوع</th>
                <th style={{ minWidth: "200px", width: "100%" }}>توضیحات</th>
                <th style={{ minWidth: "140px", width: "140px" }}>تاریخ</th>
                <th style={{ minWidth: "120px", width: "120px" }}>وضعیت</th>
                <th style={{ minWidth: "120px", width: "120px" }}></th>
              </tr>
            </thead>
            <tbody>
              {notifications.data.map((item) => (
                <tr key={item.id}>
                  <td>{item.event.name}</td>
                  <td>{item.message}</td>
                  <td>
                    <span className="max-content">
                      {moment(item.updated_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("hh:mm YYYY/MM/DD")}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`text-${item.is_read ? "success" : "danger"}`}
                    >
                      خوانده {item.is_read ? "شده" : "نشده"}
                    </span>
                  </td>
                  <td>
                    {!item.is_read && (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setRead(item.id)}
                      >
                        خواندم
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NothingToShow text="اطلاعیه ای برای نمایش وجود ندارد" />
      )}

      {loading && <BoxLoading />}

      {notifications.last_page > 1 && (
        <Pagination
          currentPage={notifications.current_page - 1}
          lastPage={notifications.last_page}
          changePage={(page) => get_notifications(page.selected + 1)}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = {
  changeNotificationCount,
};

const mapStateToProps = (state) => ({
  unreaCount: state.notificationReducer.unreaCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
