import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";

//
import useSeo from "../../hooks/useSeo";

//
import { searchBlog } from "../../redux/features/blog/actions";

//
import BlogItem from "../../components/blog/BlogItem";
import Pagination from "../../components/globals/Pagination";
import SkeletopBox from "../../components/globals/SkeletopBox";
import NothingToShow from "../../components/globals/NothingToShow";
import MetaDecorator from "../../components/globals/MetaDecorator";

function MainPage(props) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState({});

  /**
   * Search Blog 
   */
  const search_blog = async (page) => {
    // set variables
    let params = new URLSearchParams();
    params.set("page", page);

    // request
    await searchBlog(params).then((res) => {
      if (res) {
        setBlogs(res);
      }
    });
  };

  useEffect(async () => {
    set_seo({} , 'بلاگ وی پلی')
    setLoading(true);
    await search_blog(1);
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div className="main-page">
        {loading ? (
          <Row className="gutter-b">
            <Col md={6}>
              <SkeletopBox height={264} borderRadius={6} />
              <SkeletopBox height={25} width={140} borderRadius={4} />
              <SkeletopBox height={30} width={200} />
              <SkeletopBox height={22} width={50} />
              <SkeletopBox height={20} />
              <SkeletopBox height={20} />
              <SkeletopBox height={20} />
            </Col>
            <Col md={6}>
              <SkeletopBox height={264} borderRadius={6} />
              <SkeletopBox height={25} width={140} borderRadius={4} />
              <SkeletopBox height={30} width={200} />
              <SkeletopBox height={22} width={50} />
              <SkeletopBox height={20} />
              <SkeletopBox height={20} />
              <SkeletopBox height={20} />
            </Col>
          </Row>
        ) : (
          <>
            {blogs.data && blogs.data.length ? (
              <Row>
                {blogs.data.map((item, index) => (
                  <BlogItem key={item.id} item={item} big={index < 2} />
                ))}
              </Row>
            ) : (
              <Card className="gutter-b">
                <Card.Body>
                  <NothingToShow text="موردی برای نمایش وجود ندارد" />
                </Card.Body>
              </Card>
            )}
          </>
        )}

        {blogs.meta && blogs.meta.last_page > 1 && (
          <div className="mt-3 mb-5">
            <Pagination
              styled
              currentPage={blogs.meta.current_page - 1}
              lastPage={blogs.meta.last_page}
              changePage={(page) => search_blog(page.selected + 1)}
            />
          </div>
        )}
      </div>
    </>
  );
}

MainPage.propTypes = {};

export default MainPage;
