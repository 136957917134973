import React from "react";
import PropTypes from "prop-types";

//
import Address from "../../pages/panel/Address";
import CartPriceDetail from "./CartPriceDetail";
import DeliveryAlert from "./DeliveryAlert";

function SendMethods({ step, nextStep }) {
  return (
    <div className="cart-send-methods">
      <div className="cart-heading-text">
        برای ادامه فرآیند خرید، آدرس خود را وارد کنید.
      </div>
      <Address cart />
      <CartPriceDetail step={step} nextStep={nextStep} />
    </div>
  );
}

SendMethods.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default SendMethods;
