import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import MegaMenu from "./MegaMenu";

function Navbar({ configs }) {
  return (
    <div className="navbar">
      <nav className=" container ">
        <div className="navbar-expand-lg">
          <div className="collapse navbar-collapse" id="navbar-main-menu">
            <ul className="navbar-nav ml-auto p-0">
              <li className="has-menu-icon productMenu">
                <i aria-hidden="true" className="vnicon-list-menu"></i>
                <span>دسته بندی محصولات</span>
                <MegaMenu />
              </li>

              <li className="menuChild-50">
                <a href="c/video.html" title="ویدیوها">
                  ویدیوها
                </a>
              </li>

              <li className="menuChild-1">
                <a href="c/ready.html" title="آماده ارسال">
                  آماده ارسال
                </a>
              </li>

              <li className="menuChild-4">
                <Link to="/app/faq/customer" title="سوالات متداول خریداران">
                  سوالی دارید؟
                </Link>
              </li>

              <li className="menuChild-5 blogLink">
                <a
                  href="blog/article.html"
                  title={`مجله ${configs.BRAND_NAME}`}
                >
                  مجله {configs.BRAND_NAME}
                </a>
              </li>

              {configs.SELLER_URL && (
                <li className="provider menuChild-6">
                  <a
                    href={configs.SELLER_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={configs.GET_SELLER_BLOCK_BUTTON}
                  >
                    {configs.GET_SELLER_BLOCK_BUTTON}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

Navbar.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(Navbar);
