import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import { getFeaturedPosts } from "../../../redux/features/blog/actions";

//
import SkeletopBox from "../../../components/globals/SkeletopBox";

function TitledBox({ title }) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  /**
   * Get Items
   */
  const getItems = async () => {
    // start loading
    setLoading(true);

    // request
    await getFeaturedPosts().then((res) => {
      if (res) {
        setItems(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    getItems();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="titled-box gutter-b">
      <p className="title text-shadow">{title}</p>
      <div className="content">
        {loading ? (
          <>
            <div className="item">
              <div className="ms-2">
                <SkeletopBox width={75} height={75} borderRadius={6} />
              </div>
              <SkeletopBox height={20} width={100} />
            </div>
            <div className="item">
              <div className="ms-2">
                <SkeletopBox width={75} height={75} borderRadius={6} />
              </div>
              <SkeletopBox height={20} width={100} />
            </div>
          </>
        ) : (
          <>
            {items.map((item) => (
              <div className="item" key={item.id}>
                <img src={item.main_image} alt={item.title} />
                <Link to={`/blog/post/${item.id}`} className="text-shadow">
                  {item.title}
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

TitledBox.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitledBox;
