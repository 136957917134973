import React from "react";
import PropTypes from "prop-types";

//
import ReactPaginate from "react-paginate";

function Pagination({ currentPage, lastPage, changePage, styled = false }) {
  return (
    <div className="d-flex justify-content-center">
      <ReactPaginate
        breakLabel=""
        onPageChange={changePage}
        pageRangeDisplayed={5}
        pageCount={lastPage}
        marginPagesDisplayed={0}
        forcePage={currentPage}
        className={`pagination ${styled ? "styled" : ""}`}
        previousClassName={!styled ? "d-none" : ""}
        nextClassName={!styled ? "d-none" : ""}
        previousLabel={
          styled ? <i className="fas fa-chevron-right"></i> : false
        }
        nextLabel={styled ? <i className="fas fa-chevron-left"></i> : false}
      />
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default Pagination;
