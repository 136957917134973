import React from "react";
import PropTypes from "prop-types";

function BeforeText({ text, simple = false, small = false }) {
  return (
    <div
      className={`before-title ${small ? "small" : ""} ${
        simple ? "simple" : ""
      }`}
    >
      <p>{text}</p>
    </div>
  );
}

BeforeText.propTypes = {
  text: PropTypes.string.isRequired,
  simple: PropTypes.bool,
  small: PropTypes.bool,
};

export default BeforeText;
