import React from "react";
import PropTypes from "prop-types";

//
import { Modal, Row, Col } from "react-bootstrap";

//
import BeforeText from "../../globals/BeforeText";
import Login from "../../../pages/auth/Login";

//
import AuthImage from "../../../assets/img/auth.png";
import { connect } from "react-redux";

function ProductInquireModal({ show, setShow, sendProductInquire }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => setShow(false)}
      id="new-question-comment-modal"
    >
      <Modal.Header className="no-border" closeButton>
        <BeforeText text="استعلام قیمت" small />
        <div className="extra-info">
          <small>جهت استعلام قیمت لطفا ابتدا وارد حساب خود شوید</small>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={6}>
            <Login loginModal closeModalAfterLogin={(user) => sendProductInquire(user)} />
          </Col>
          <Col md={6} className="d-none d-md-block">
            <img src={AuthImage} alt="comment-image" className="img-fluid" />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

ProductInquireModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  sendProductInquire: PropTypes.func.isRequired,
};


export default ProductInquireModal;
