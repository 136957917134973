import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Col, Container, Row } from "react-bootstrap";

//
import { getFAQ } from "../../redux/features/main/actions";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

function FAQ() {
  let { type } = useParams();
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  /**
   * Get FAQs
   */
  const get_faqs = async () => {
    // start loading
    setLoading(true);

    // set variable
    let params = new URLSearchParams();
    params.set("type", type);

    // request
    await getFAQ(params).then((res) => {
      if (res) {
        setFaqs(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_faqs();
    set_seo(
      {},
      type === "customer" ? "سوالات متداول" : " سوالات تامین کنندگان"
    );
  }, [type]);

  return (
    <div id="faq">
      <MetaDecorator seo={seo} />

      <Container>
        <div className="main-title">
          سوالات {type === "customer" ? "متداول" : "تامین کنندگان"}
        </div>

        <Card className="dark-border no-radius">
          <Card.Body>
            <Row>
              {faqs.map((item) => (
                <Col md={6} key={item.id}>
                  <div className="holder">
                    <p className="question">{item.question}</p>

                    <p className="answer">{item.answer}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default FAQ;
