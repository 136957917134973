import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Get Categories
 */
export const getBlogCategories = createAsyncThunk(
    "getBlogCategories",
    async() => {
        try {
            const { data } = await axios.get("blog/categories");

            //
            return data;
        } catch (error) {
            return false;
        }
    }
);

/**
 * Get Category Blogs
 */
export const getCategoryBlogs = async() => {
    try {
        const { data } = await axios.get('blog/categories/blocks');

        //
        return data
    } catch (error) {
        return false
    }
}

/**
 * Get Featured Posts
 */
export const getFeaturedPosts = async() => {
    try {
        const { data } = await axios.get("blog/posts/featured");

        //
        return data;
    } catch (error) {
        return false;
    }
};

/**
 * Search Blog
 * @param {search params} payload
 */
export const searchBlog = async(payload) => {
    try {
        const { data } = await axios.get("blog/posts", {
            params: payload,
        });

        //
        return data;
    } catch (error) {
        return false;
    }
};

/**
 * Get Post
 * @param {post slug} payload
 */
export const getBlogPost = async(payload) => {
    try {
        const { data } = await axios.get(`blog/posts/${payload}`);

        //
        return data;
    } catch (error) {
        return false;
    }
};