import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Row, Col } from "react-bootstrap";

//
import GradientCard from "../../components/globals/GradientCard";
import UserInfo from "../../components/panel/UserInfo";
import Orders from "./Orders";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Panel(props) {
  const [seo, set_seo] = useSeo();

  useEffect(() => {
    set_seo({}, "پروفایل");

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <Row className="d-none d-lg-flex card-links">
        <Col md={4}>
          <Link to="orders">
            <GradientCard
              text="لیست سفارشات"
              image="document"
              imageBackground="#b5b5b5"
            />
          </Link>
        </Col>

        <Col md={4}>
          <Link to="comments">
            <GradientCard
              text="نظرات"
              image="comments"
              imageBackground="#0989fb"
              imageWhite
            />
          </Link>
        </Col>

        <Col md={4}>
          <Link to="question-answer">
            <GradientCard text="پرسش و پاسخ" image="question-answer" />
          </Link>
        </Col>
      </Row>

      <UserInfo />

      <Orders title swiper moreInfo />
    </>
  );
}

export default Panel;
