import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function MobileHeader({ setShow, product, updateIsFavorite }) {
  const [showContent, setShowContent] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [y, setY] = useState(window.scrollY);

  const scrollToPriceChart = () => {
    setShowContent(false)

    let element = document.getElementById("price-chart");
    element.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * handle Scroll to top
   */
  const handleNavigation = (e) => {
    const window = e.currentTarget;

    // show scroll to top when page scroll is more than 100
    if (y < window.scrollY && window.pageYOffset > 100) {
      setShowHeader(false);
    }
    if (y > window.scrollY) {
      setShowHeader(true);
    }

    setY(window.scrollY);
  };

  // detect page scrool
  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  const changeState = (type) => {
    if (type === "share") {
      setShow(true);
    }

    setShowContent(false);
  };

  return (
    <>
      {/* header */}
      <div className={`mobile-header ${showHeader ? "show" : "hide"}`}>
        <i className="fas fa-times"></i>
        <div>
          <Link to='/cart'>
          <i className="fas fa-dolly"></i>
          </Link>
          <i
            className="fas fa-ellipsis-v me-4"
            onClick={() => setShowContent(true)}
          ></i>
        </div>
      </div>

      {/* footer */}
      {showContent && (
        <div className="click-box " onClick={() => setShowContent(false)}></div>
      )}

      <div className="share-section ">
        <div className={`share-section-content ${showContent ? "active" : ""}`}>
          <div className="item pt-0">
            <a onClick={updateIsFavorite}>
              <i
                className={`fas fa-heart ${
                  product.is_favorite ? "text-danger" : "text-muted"
                }`}
              ></i>
              <small>
                {product.is_favorite
                  ? "حذف از علاقه مندی ها"
                  : "افزودن به علاقه مندی ها"}
              </small>
            </a>
          </div>
          <div className="item ">
            <a onClick={() => changeState("share")}>
              <i className="fas fa-share-alt text-muted"></i>
              <small>اشتراک گذاری</small>
            </a>
          </div>

          <div className="item">
            <a  onClick={() => scrollToPriceChart()}>
              <i className="fas fa-chart-line text-muted"></i>
              <small>نمودار قیمت</small>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

MobileHeader.propTypes = {
  setShow: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  updateIsFavorite: PropTypes.func.isRequired,
};

export default MobileHeader;
