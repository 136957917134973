import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { changeTopNavSwiperState } from "../../redux/features/theme/slice";
import {
  getBanners,
  getCategoryBlocks,
} from "../../redux/features/main/actions";

//
import MainSlider from "../../components/dashboard/MainSlider";
import MiddleBanners from "../../components/dashboard/MiddleBanners";
import MostPopular from "../../components/dashboard/MostPopular";
import BanneredProduct from "../../components/dashboard/BanneredProduct";
import BigBanners from "../../components/dashboard/BigBanners";
import BanneredProductLoading from "../../components/dashboard/BanneredProductLoading";

import MetaDecorator from "../../components/globals/MetaDecorator";

function Dashboard({ changeTopNavSwiperState, configs }) {
  const [seo, set_seo] = useSeo();

  // Banners
  const [banners, setBanners] = useState({});
  const [bannerLoading, setBannerLoading] = useState(false);

  // Category Blocks
  const [categoryBlocks, setCategoryBlocks] = useState([]);
  const [categoryBlocksLoading, setCategoryBlocksLoading] = useState(false);

  /**
   * Check Even or Odd
   */
  const isEven = (n) => {
    return n % 2 == 0;
  };

  /**
   * Get Banners
   */
  const get_banners = async () => {
    // start loading
    setBannerLoading(true);

    // request
    await getBanners().then((res) => {
      if (res) {
        setBanners(res);
      }
    });

    // stop loading
    setBannerLoading(false);
  };

  /**
   * Get Category Blocks
   */
  const get_category_blocks = async () => {
    // start loading
    setCategoryBlocksLoading(true);

    // request
    await getCategoryBlocks().then((res) => {
      if (res) {
        setCategoryBlocks(res);
      }
    });

    // stop loading
    setCategoryBlocksLoading(false);
  };

  useEffect(() => {
    changeTopNavSwiperState(true);
    get_banners();
    get_category_blocks();

    return () => {
      changeTopNavSwiperState(false);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configs) {
      set_seo({
        title: configs.SEO_TITLE,
        description: configs.SEO_DESCRIPTION,
        canonical: configs.SEO_CANONICAL,
        keywords: configs.SEO_KEYWORDS
      });
    }

  }, [configs]);

  return (
    <div id="dashboard">
      <MetaDecorator seo={seo} />

      <MainSlider banners={banners} loading={bannerLoading} />

      <MiddleBanners banners={banners} loading={bannerLoading} />

      <MostPopular />

      {categoryBlocksLoading ? (
        <BanneredProductLoading />
      ) : (
        <>
          {categoryBlocks.map((item, index) => (
            <BanneredProduct
              key={item.id}
              category={item}
              bgGray={isEven(index)}
            />
          ))}
        </>
      )}

      <BigBanners banners={banners} loading={bannerLoading} />
    </div>
  );
}

Dashboard.propTypes = {
  changeTopNavSwiperState: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  changeTopNavSwiperState,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
