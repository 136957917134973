import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useCurrency from "../../hooks/useCurrency";

//
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import NumberFormat from "react-number-format";

//
import { getSplitedOrderItems } from "../../redux/features/cart/actions";
import {
  setSplitedOrderItems,
  setCartCoupon,
} from "../../redux/features/cart/slice";

//
import CartPriceDetail from "./CartPriceDetail";
import CartItems from "./CartItems";
import BoxLoading from "../globals/BoxLoading";

function ShowSplitedItems({
  step,
  nextStep,
  setSplitedOrderItems,
  splitedOrderItems,
  setCartCoupon,
  selectedAddressCart,
  configs,
}) {
  const [currencyTitle] = useCurrency();

  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [descriptionList, setDescriptionList] = useState({});

  const handleDescriptionChange = (e, key) => {
    let list = { ...descriptionList };

    list[key] = e.target.value;

    setDescriptionList(list);
  };

  const descriptions = (key) => {
    return descriptionList[key];
  };

  const checkCupon = (e) => {
    e.preventDefault();
    get_splited_order_items(coupon);
  };

  const get_splited_order_items = async (coupon) => {
    // start loading
    setLoading(false);

    //
    let customer_address_id = selectedAddressCart.id;

    // request
    await getSplitedOrderItems({ customer_address_id, coupon }).then((res) => {
      if (res) {
        setSplitedOrderItems(res);
        if (coupon) {
          setCartCoupon(coupon);
        }
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_splited_order_items();

    // eslint-disab;e0next-line
  }, []);

  return (
    <div className="cart-splited-items">
      {loading && <BoxLoading />}
      <div className="cart-heading-text">در صورت تایید به مرحله بعد بروید</div>
      <Row>
        <Col sm={8} md={5} className="discount-code">
          <Form onSubmit={checkCupon}>
            <Form.Label className="bold text-muted">
              در صورت داشتن کد تخفیف آن را وارد کنید.
            </Form.Label>
            <Form.Group className="form-group mb-0">
              <InputGroup>
                <FormControl
                  className="form-control-solid"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />

                <Button variant="danger" className="pill" type="submit">
                  ثبت کد تخفیف
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {splitedOrderItems.items &&
        Object.entries(splitedOrderItems.items).map(
          ([mainKey, mainValue], index) => (
            <div className="splited-order">
              <p className="seller-name">
                خرید از فروشگاه {mainValue.seller.company_name}
              </p>
              <div className="cart-products">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>محصول</th>
                      <th>تعداد</th>
                      <th>قیمت</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Object.entries(mainValue.items).map(
                      ([key, value], index) => (
                        <CartItems item={value} key={value.id} />
                      )
                    )}
                  </tbody>
                </table>
              </div>

              <div className="shipping-methods">
                <div className="delivery-alert">
                  <p className="title">طریقه ارسال</p>
                  {mainValue.shipping.machines.map((item) => (
                    <div className="method" key={item.id}>
                      <div>
                        <img src={item.icon} alt={item.description} />
                        <span>
                          {item.title} ({item.count} عدد)
                        </span>
                        <span className="mx-3">-</span>

                        <NumberFormat
                          value={
                            item.price_per_km * mainValue.shipping.distance
                          }
                          displayType="text"
                          thousandSeparator={true}
                          className="cart-item-price"
                        />

                        <span className="mx-1">{currencyTitle()}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="description-box">
                <p className="title">توضیحات سفارش</p>

                <Form.Group
                  className="form-group mb-0"
                  controlId="validationFormik02"
                >
                  <Form.Control
                    placeholder={
                      configs.ORDER_DESCRIPTION ||
                      "در صورتی که سفارش شما نیاز به توضیحات دارد در کادر زیر وارد کنید"
                    }
                    as="textarea"
                    rows="4"
                    type="text"
                    name="description"
                    onChange={(e) =>
                      handleDescriptionChange(e, mainValue.seller.id)
                    }
                    autoComplete="off"
                    value={descriptions(mainValue.seller.id)}
                  />
                </Form.Group>
              </div>
            </div>
          )
        )}

      <CartPriceDetail
        step={step}
        nextStep={nextStep}
        descriptionList={descriptionList}
      />
    </div>
  );
}

ShowSplitedItems.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  splitedOrderItems: PropTypes.object.isRequired,
  setSplitedOrderItems: PropTypes.func.isRequired,
  setCartCoupon: PropTypes.func.isRequired,
  selectedAddressCart: PropTypes.object,
  configs: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  setSplitedOrderItems,
  setCartCoupon,
};

const mapStateToProps = (state) => ({
  splitedOrderItems: state.cartReducer.splitedOrderItems,
  selectedAddressCart: state.cartReducer.selectedAddressCart,
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSplitedItems);
