import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function MegaMenu({ categories }) {
  const [mainCategories, setMainCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const set_mega_menu = () => {
    if (categories.length) {
      let main_categories = [];

      for (let i = 0; i < categories.length; i++) {
        const element = categories[i];
        let { id, slug, title } = element;
        main_categories.push({
          id,
          slug,
          title,
        });
      }

      setMainCategories(main_categories);
      setSelectedCategory(main_categories[0].id);
    }
  };

  useEffect(() => {
    set_mega_menu();
  }, [categories]);

  return (
    <div id="menuHtml">
      <div className="mega-menu-container" id="productMenu">
        <div className="mega-menu-category">
          {mainCategories.map((item) => (
            <Link
              key={item.id}
              className={selectedCategory == item.id ? "selected" : ""}
              to={`/app/category-info/${item.id}`}
              title={item.title}
              onMouseEnter={() => setSelectedCategory(item.id)}
            >
              {item.title}
              <i aria-hidden="true" className="fa fa-chevron-left"></i>
            </Link>
          ))}
        </div>
        <div className="mega-menu-items">
          {categories.length &&
            categories.map((cat) => (
              <div
                key={cat.id}
                className={`megamenu1 ${
                  selectedCategory === cat.id ? "selected" : ""
                }`}
              >
                <div className="row">
                  {cat.children &&
                    cat.children.map((subCat) => (
                      <div className="col-sm-3" key={subCat.id}>
                        <Link
                          className="prds-title"
                          to={`/app/category/${subCat.id}`}
                        >
                          {subCat.title}
                        </Link>

                        {subCat.children &&
                          subCat.children.slice(0, 7).map((item) => (
                            <>
                              <Link
                                key={item.id}
                                className="m-menu-item"
                                to={`/app/category/${item.id}`}
                              >
                                {item.title}
                              </Link>

                              <Link
                                className="m-menu-item-more"
                                to={`/app/category/${subCat.id}`}
                              >
                                مشاهده بیشتر
                              </Link>
                            </>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

MegaMenu.propTypes = {
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.mainReducer.categories,
});

export default connect(mapStateToProps)(MegaMenu);
