import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import Zoom from "react-img-zoom";
import { Col, Modal, Row } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/thumbs/thumbs.min.css";

import SwiperCore, { Pagination, Navigation, Thumbs } from "swiper/core";

SwiperCore.use([Pagination, Navigation, Thumbs]);

function ImageGalleryModal({ windowSize, show, setShow, gallery }) {
  const [selectedImage, setSelectedImage] = useState({});
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const onHide = () => {
    setThumbsSwiper(null);
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      setSelectedImage(gallery[0]);
    }
  }, [show, gallery]);

  return (
    <Modal
      show={show}
      size="xl"
      fullscreen="md-down"
      onHide={() => onHide()}
      id="image-gallery-modal"
    >
      <Modal.Header closeButton>
        <small>تصاویر محصولات</small>
      </Modal.Header>
      <Modal.Body>

          <Row className="desktop d-none d-md-flex">
            <Col xs={4} className="image-items">
              <Row>
                {gallery.map((item) => (
                  <Col
                    xs={4}
                    className="item pointer"
                    key={item.id}
                    onClick={() => setSelectedImage(item)}
                  >
                    <img src={item.path} alt="image" />
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xs={8} className="selected-image-holder">
              <Zoom
                key={selectedImage.id}
                img={selectedImage.path || ''}
                zoomScale={3}
                height={390}
                width={400}
                transitionTime={0.5}
              />
            </Col>
          </Row>

          <div className="mobile d-flex d-md-none">
            <Swiper
              spaceBetween={10}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Navigation, Thumbs]}
              className="mySwiper2"
            >
              {gallery.map((item, index) => (
                <SwiperSlide key={`main_${index}`}>
                  <div className="img-box">
                    <img src={item.path} alt="image" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[Navigation, Thumbs]}
              className="mySwiper"
            >
              {gallery.map((item, index) => (
                <SwiperSlide key={`thumb_${index}`}>
                  <img src={item.path} alt="image" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

      </Modal.Body>
    </Modal>
  );
}

ImageGalleryModal.propTypes = {
  windowSize: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  gallery: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(ImageGalleryModal);
