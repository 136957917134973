import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import { searchBlog } from "../../../redux/features/blog/actions";

//
import {
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";

//
import { blogSocials } from "../../../constances";

function BlogNavbar({ configs, blogCategories }) {
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  /**
   * Search Blog
   */
  const search_blog = async () => {
    if (search) {
      // start loading
      setLoading(true);

      // set variables
      let params = new URLSearchParams();
      params.set("query", search);
      params.set("page_size", 20);
      params.set("page", 1);

      // request
      await searchBlog(params).then((res) => {
        if (res) {
          setSearchResult(res);
        }
      });

      // stop loading
      setLoading(false);
    }
  };

  useEffect(() => {
    search_blog();

    // eslint-disable-next-line
  }, [search]);

  return (
    <Navbar bg="danger" className="d-none d-lg-block mb-4">
      <Container>
        <Nav className="ms-auto my-2 my-lg-0">
          <Nav.Link>
            <Link to="/blog">صفحه اصلی</Link>
          </Nav.Link>

          {blogCategories.map((item) => (
            <>
              {item.children.length ? (
                <NavDropdown title={item.title} key={item.id}>
                  {item.children.map((child) => (
                    <NavDropdown.Item key={child.id}>
                      <Link to={`/blog/topic/${child.id}`}>{child.title}</Link>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link key={item.id}>
                  <Link to={`/blog/topic/${item.id}`}>{item.title}</Link>
                </Nav.Link>
              )}
            </>
          ))}

          <i
            className="fas fa-search search-icon pointer"
            onClick={() => setShowSearch(true)}
          ></i>
        </Nav>
        <div className="socials">
          {blogSocials.map((item, index) => (
            <a
              href={configs[item.link]}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2 d-flex align-items-center"
            >
              <i className={`text-white ${item.icon}`}></i>
            </a>
          ))}
        </div>
      </Container>

      <div className={`fix-box ${showSearch ? "active" : ""}`}>
        <i
          className="fas fa-times close-icon pointer"
          onClick={() => setShowSearch(false)}
        ></i>

        <Row className="justify-content-center w-100 content">
          <Col xs={4}>
            <div className="input-holder">
              <Form.Control
                type="text"
                className="form-control-solid"
                onChange={(e) => setSearch(e.target.value)}
                autoComplete="off"
                value={search}
              />
              <div className="icon-holder">
                {loading ? (
                  <Spinner animation="border" variant="white" size="sm" />
                ) : (
                  <i className="fas fa-search"></i>
                )}
              </div>
            </div>
            {search && (
              <>
                {searchResult.length ? (
                  <>
                    {searchResult.map((item) => (
                      <Link
                        className="item"
                        to={`/blog/post/${item.id}`}
                        onClick={() => setShowSearch(false)}
                      >
                        <img src={item.main_image} alt={item.title} />
                        <p>{item.title}</p>
                      </Link>
                    ))}
                  </>
                ) : (
                  <div className="item">
                    <p>موردی یافت نشد</p>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </Navbar>
  );
}

BlogNavbar.propTypes = {
  configs: PropTypes.object.isRequired,
  blogCategories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
  blogCategories: state.blogReducer.blogCategories,
});

export default connect(mapStateToProps)(BlogNavbar);
