import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

//
import { setWindowSize } from "../redux/features/theme/slice";
import { getCurrentUser } from "../redux/features/user/actions";
import {
  getCurrencies,
  getConfigs,
  getCategories,
  getFooterMenus,
} from "../redux/features/main/actions";
import { getCart } from "../redux/features/cart/actions";

//
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Valuation from "./components/Valuation";
import Footer from "./components/Footer";
import MostVisitedCategury from "./components/MostVisitedCategury";
import BackToTop from "./components/BackToTop";
import ShairSection from "./components/ShairSection";
import MainLoading from "../components/globals/MainLoading";

function Main({
  windowSize,
  setWindowSize,
  topNavSwiperState,
  mobileHeaderState,
  getCurrentUser,
  getConfigs,
  getCategories,
  getFooterMenus,
  getCart,
  configs,
  categories,
  footerLinks,
  currencies,
  getCurrencies,
}) {
  const [loading, setLoading] = useState(false);

  /**
   * Set User if localStorage have user item
   */
  const get_current_user = async () => {
    let token = localStorage.getItem("token");

    if (token) {
      await getCurrentUser();
    }
  };

  useEffect(() => {
    setWindowSize(window.innerWidth);
    const handleResizeWindow = () => setWindowSize(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
  }, []);

  useEffect(async () => {
    getCart();
    get_current_user();
    setLoading(true);
    if (!currencies.length) {
      await getCurrencies();
    }
    if (!Object.keys(configs).length) {
      await getConfigs();
    }
    if (!categories.length) {
      await getCategories();
    }
    if (!Object.keys(footerLinks).length) {
      await getFooterMenus();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="main">
      {loading && <MainLoading />}

      {topNavSwiperState && <MostVisitedCategury />}

      <Header show={mobileHeaderState && windowSize <= 768 ? false : true} />

      <Navbar />

      <div className="main">
        <Outlet />
      </div>

      <BackToTop />
      <ShairSection />
      <Valuation />
      <Footer full />
    </div>
  );
}

Main.propTypes = {
  setWindowSize: PropTypes.func.isRequired,
  topNavSwiperState: PropTypes.bool.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getConfigs: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getFooterMenus: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  footerLinks: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  getCurrencies: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setWindowSize,
  getCurrentUser,
  getConfigs,
  getCategories,
  getFooterMenus,
  getCart,
  getCurrencies,
};

const mapStateToProps = (state) => ({
  topNavSwiperState: state.themeReducer.topNavSwiperState,
  windowSize: state.themeReducer.windowSize,
  mobileHeaderState: state.themeReducer.mobileHeaderState,
  configs: state.mainReducer.configs,
  footerLinks: state.mainReducer.footerLinks,
  categories: state.mainReducer.categories,
  currencies: state.mainReducer.currencies,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
