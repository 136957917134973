import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import ImageGalleryModal from "./ImageGalleryModal";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

function ImageGallery({ windowSize, gallery }) {
  const [selectedImage, setSelectedImage] = useState({});

  const [showGalleryModal, setShowGalleryModal] = useState(false);

  useEffect(() => {
    setSelectedImage(gallery[0])
    
  }, [gallery]);

  return (
    <div className="image-gallery">
      {windowSize > 768 ? (
        // Desktop Gallery with items
        <div className="desktop">
          <div className="image-items">
            {gallery.slice(0, 4).map((item, index) => (
              <div
                className={`item pointer ${index === 3 ? "more" : ""}`}
                key={item.id}
                onClick={() =>
                  index === 3
                    ? setShowGalleryModal(true)
                    : setSelectedImage(item)
                }
              >
                <img src={item.path} alt="image" />
              </div>
            ))}
          </div>

          <div
            className="selected-image-holder"
            onClick={() => setShowGalleryModal(true)}
          >
            <img src={selectedImage.path} alt="image" />
          </div>
        </div>
      ) : (
        // Mobile Gallery with items
        <div className="mobile">
          <Swiper
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
          >
            {gallery.map((item) => (
              <SwiperSlide
                key={item.id}
                onClick={() => setShowGalleryModal(true)}
              >
                <img src={item.path} alt="image" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <ImageGalleryModal
        show={showGalleryModal}
        setShow={setShowGalleryModal}
        gallery={gallery}
      />
    </div>
  );
}

ImageGallery.propTypes = {
  windowSize: PropTypes.number.isRequired,
  gallery: PropTypes.array,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(ImageGallery);
