import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// React Dropzone
import { useDropzone } from "react-dropzone";
import { Button } from "react-bootstrap";

//
import AddImageSvg from "../../assets/img/addphoto.svg";

function ReturnOrderDropzone({updateFileArray, index }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  /**
   * Remove Image
   */
  const removeImage = (image) => {
    let filesArray = [...files];

    filesArray = filesArray.filter((item) => item != image);

    setFiles(filesArray);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    updateFileArray(files , index)
  
  }, [files])
  

  return (
    <section className="dropzone">
      <div>
        {files.length ? (
          <div className="thump-box">
            {files.map((file, index) => (
              <div className="thump" key={file.name}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="item">
                          <img src={file.preview} alt="" />
                        </div>
                      </td>
                      <td className="text-end">
                        <span className="size">{formatBytes(file.size)}</span>
                      </td>
                      <td>
                        <div className="icons">
                          <i
                            className="fas fa-times-circle text-danger"
                            onClick={() => removeImage(file)}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ) : (
          <div {...getRootProps({ className: "input-text" })}>
            <input {...getInputProps()} />
            <img src={AddImageSvg} alt="add-image" />
            <p className="title">تصویر محصول</p>
            <Button variant="dark">اضافه کردن</Button>
          </div>
        )}
      </div>
    </section>
  );
}

ReturnOrderDropzone.propTypes = {
    updateFileArray: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default ReturnOrderDropzone;
