import React from "react";
import PropTypes from "prop-types";

//
import useCurrency from "../../hooks/useCurrency";

//
import NumberFormat from "react-number-format";

function ProductSellers({ seller, selectedSeller, setSelectedSeller }) {
  const [currencyTitle] = useCurrency();

  return (
    <tr onClick={() => setSelectedSeller(seller)}>
      <td>
        <div className="d-flex align-items-center">
          <div
            className={`selected-seller ${
              selectedSeller.id === seller.id ? "active" : ""
            }`}
          ></div>
          <h6>{seller.seller.company_name}</h6>
        </div>
      </td>
      <td>
        <h6 className="d-flex align-items-center justify-content-center gap-1">
          <small>{currencyTitle()}</small>
          <NumberFormat
            value={+seller.original_price}
            displayType="text"
            thousandSeparator={true}
            className="text-bold ms-1"
          />
        </h6>
      </td>
    </tr>
  );
}

ProductSellers.propTypes = {
  seller: PropTypes.object.isRequired,
  selectedSeller: PropTypes.object.isRequired,
  setSelectedSeller: PropTypes.func.isRequired,
};

export default ProductSellers;
