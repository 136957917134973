import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Get Categories
 */
export const getNotificationsUnreadCount = createAsyncThunk(
  "getNotificationsUnreadCount",
  async () => {
    try {
      const { data } = await axios.get("profile/notifications/unread-count");

      //
      return data;
    } catch (error) {
      return false;
    }
  }
);

/**
 * Get Notifications
 */
export const getNotifications = async (payload) => {
  try {
    const { data } = await axios.get("/profile/notifications", {
      params: payload,
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Unread Notifications
 */
export const getUnreadNotifications = async (payload) => {
  try {
    const { data } = await axios.get("/profile/notifications/unread", {
      params: payload,
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Change Notification Status
 */
export const changeNotificationStatus = async (payload) => {
  try {
    await axios.post("/profile/notifications/set-read", {
      ids: [payload],
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Norifications Config
 */
export const getNotiticationsConfig = async () => {
  try {
    const { data } = await axios.get("/profile/notifications/config");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Update Norifications Config
 */
export const updateNotiticationsConfig = async (payload) => {
  try {
    await axios.post("/profile/notifications/config", payload);

    //
    return true;
  } catch (error) {
    return false;
  }
};
