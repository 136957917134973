import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useCurrency from "../../hooks/useCurrency";

//
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//
import { Button, Col, Row, Spinner, Collapse, Form } from "react-bootstrap";

//
import NumberFormat from "react-number-format";
import {
  finalizeCart,
  paymentWithWallet,
} from "../../redux/features/cart/actions";
import { setCartOrder } from "../../redux/features/cart/slice";
import {
  getUserWallets,
  chargeUserWallet,
} from "../../redux/features/user/actions";

function CartPriceDetail({
  step,
  nextStep,
  descriptionList,
  cart,
  selectedAddressCart,
  coupon,
  setCartOrder,
  cartOrder,
  splitedOrderItems,
  decreaceCredit = false,
}) {
  const [currencyTitle] = useCurrency();

  let navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [wallets, setWallets] = useState([]);

  const [walletAmounts, setWalletAmounts] = useState({});
  const [walletPays, setWalletPays] = useState({});

  const total_price = () => {
    return step < 3 ? totalPrice : +splitedOrderItems.subtotal;
  };

  const computingCartPrice = () => {
    if (cart.length) {
      let total = cart.reduce((total, num) => {
        return total + +num.final_price;
      }, 0);

      setTotalPrice(total);
    }
  };

  const payablePrice = () => {
    let { shipping, discount, subtotal } = splitedOrderItems;

    if (decreaceCredit)
      return shipping + subtotal + (+cartOrder.vat_price || 0);
    else return shipping + subtotal + (+cartOrder.vat_price || 0);
  };

  const disablePay = (key, amount) => {
    if (
      !+walletAmounts[key].payAmount ||
      +walletAmounts[key].payAmount > amount
    )
      return true;

    return false;
  };

  const amountConvert = (mainKey, amountKey) => {
    if (+walletAmounts[mainKey][amountKey])
      return walletAmounts[mainKey][amountKey] * walletAmounts[mainKey].rate;
    return 0;
  };

  const handleAmounts = (e, mainKey, valueKey) => {
    let infos = { ...walletAmounts };
    infos[mainKey][valueKey] = e;

    setWalletAmounts(infos);
  };

  const setWalletPayAmount = (key) => {
    let infos = { ...walletPays };

    // variable
    const content = { ...walletAmounts[key] };

    if (infos[key]) {
      infos[key].payAmount = content.payAmount;
    } else {
      infos[key] = content;
    }

    setWalletPays(infos);
  };

  const totelWalletPayAmount = () => {
    return Object.keys(walletPays).reduce(function (previous, key) {
      return previous + +walletPays[key].payAmount * +walletPays[key].rate;
    }, 0);
  };

  const payWithWallet = async () => {
    let wallets = [];
    for (const [key, value] of Object.entries(walletPays)) {
      wallets.push({
        wallet_id: value.id,
        amount: +value.payAmount,
      });
    }

    const order_id = cartOrder.id;

    const content = {
      wallets: wallets,
    };

    // request
    await paymentWithWallet({ order_id, content }).then((res) => {
      if (res) {
        setWalletPays({});
        navigate("/panel/orders");
      }
    });
  };

  const convertCartToOrder = async () => {
    // variable
    let list = [];
    for (const [key, value] of Object.entries(descriptionList)) {
      list.push({
        id: key,
        description: value,
      });
    }

    let splitedListIds = [];
    for (const [key, value] of Object.entries(splitedOrderItems.items)) {
      splitedListIds.push(key);
    }

    if (splitedListIds.length == list.length) {
      // start loading
      setLoading(true);

      let content = {
        customer_address_id: selectedAddressCart.id,
        descriptions: list,
      };
      if (coupon) {
        content.coupon = coupon;
      }

      // request
      finalizeCart(content).then((res) => {
        if (res) {
          setLoading(false);
          setCartOrder(res);
          nextStep();
        } else {
          setLoading(false);
        }
      });
    } else {
      toast.error("توضیحات سفارش اجباری است", {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"> </i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons"></i>
        ),
      });
    }
  };

  const getWalletList = async () => {
    await getUserWallets().then((res) => {
      let walletAmountInfos = {};
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        walletAmountInfos[element.currency.code] = {
          chargeAmount: "",
          payAmount: "",
          id: element.id,
          rate: Number(+element.currency.convert_rate)
            .toFixed(+element.currency.decimal_count)
            .replace(/\.?0+$/, ""),
        };
      }

      setWalletAmounts(walletAmountInfos);
      setWallets(res);
    });
  };

  const chargeWallet = async (key) => {
    // variable
    const id = walletAmounts[key].id;
    let content = {
      amount: walletAmounts[key].chargeAmount,
    };

    // request
    await chargeUserWallet({ id, content }).then((res) => {
      if (res) {
        window.open(res.paymentUrl, "_blank", "noreferrer");
      }
    });
  };

  useEffect(() => {
    computingCartPrice();
  }, [cart]);

  useEffect(() => {
    getWalletList();
  }, []);

  return (
    <div className="cart-price-detail">
      <Row className="align-items-end">
        <Col md={step === 4 ? 12 : 6} className="change-step">
          {step > 3 && (
            <div className="pre-invoice">
              دانلود پیش فاکتور
              <i className="fas fa-download mx-2"></i>
            </div>
          )}
          {step !== 4 && (
            <Button
              variant="danger"
              size="lg"
              className="next-step pill"
              onClick={() => (step === 3 ? convertCartToOrder() : nextStep())}
              disabled={step === 2 && !selectedAddressCart.id}
            >
              {step === 1 ? "ادامه" : "تکمیل"} فرآیند خرید
              {loading && (
                <div className="spinner-holder">
                  <Spinner animation="border" variant="white" size="sm" />
                </div>
              )}
            </Button>
          )}
        </Col>
        <Col md={step === 4 ? 12 : 6} className="prices">
          <div className="item">
            <span className="title">مبلغ کل:</span>
            {total_price() > 0 && (
              <NumberFormat
                value={total_price()}
                displayType="text"
                thousandSeparator={true}
                className="price text-bold"
              />
            )}
            <small>{total_price() > 0 ? currencyTitle() : "رایگان"}</small>
          </div>

          {step >= 3 && (
            <>
              <div className="item">
                <span className="title">هزینه ارسال:</span>
                {+splitedOrderItems.shipping > 0 && (
                  <NumberFormat
                    value={+splitedOrderItems.shipping}
                    displayType="text"
                    thousandSeparator={true}
                    className="price text-bold"
                  />
                )}

                <small>
                  {+splitedOrderItems.shipping > 0 ? currencyTitle() : "رایگان"}
                </small>
              </div>

              <div className="item text-danger">
                <span className="title">تخفیف:</span>
                <NumberFormat
                  value={+splitedOrderItems.discount}
                  displayType="text"
                  thousandSeparator={true}
                  className="price text-bold"
                />

                <small>{currencyTitle()}</small>
              </div>

              <div className="item">
                <span className="title">مالیات:</span>
                <NumberFormat
                  value={+cartOrder.vat_price || 0}
                  displayType="text"
                  thousandSeparator={true}
                  className="price text-bold"
                />

                <small>{currencyTitle()}</small>
              </div>

              <div className="item total">
                <span className="title">مبلغ قابل پرداخت:</span>
                {payablePrice() > 0 && (
                  <NumberFormat
                    value={payablePrice()}
                    displayType="text"
                    thousandSeparator={true}
                    className="text-success price text-bold"
                  />
                )}

                <small>{payablePrice() > 0 ? currencyTitle() : "رایگان"}</small>
              </div>

              {step === 4 && (
                <div className="item wallets-box">
                  <Form.Check
                    label="استفاده از کیف پول"
                    type="checkbox"
                    checked={walletOpen}
                    onChange={() => setWalletOpen(!walletOpen)}
                  />

                  <Collapse in={walletOpen}>
                    <div id="wallet-collapse">
                      {wallets.map((item) => (
                        <Row key={item.id} className="wallet-item">
                          <Col
                            md="4"
                            className="d-flex justify-content-between mb-3 mb-md-0"
                          >
                            <div>
                              <p className="wallet-item-title">
                                موجودی {item.currency.postfix}:
                                <NumberFormat
                                  value={+item.amount}
                                  displayType="text"
                                  thousandSeparator={true}
                                  className="price text-bold"
                                />
                                {item.currency.title}
                              </p>

                              <small className="convert-amount">
                                معادل{" "}
                                <NumberFormat
                                  value={+item.convert_amount}
                                  displayType="text"
                                  thousandSeparator={true}
                                  className="price text-bold"
                                />
                                {currencyTitle()}
                              </small>
                            </div>

                            <i
                              class="fas fa-sync-alt refresh-icon pointer"
                              onClick={() => getWalletList()}
                            ></i>
                          </Col>

                          <Col
                            md="4"
                            className="d-flex align-items-start gap-2 mb-3 mb-md-0"
                          >
                            <div className="w-100">
                              <Form.Control
                                type="number"
                                min="0.0000001"
                                step="0.0000001"
                                className="form-control-solid number"
                                autoComplete="off"
                                readOnly={item.currency.status === 'disable'}
                                value={
                                  walletAmounts[item.currency.code].payAmount
                                }
                                onChange={(e) =>
                                  handleAmounts(
                                    e.target.value,
                                    item.currency.code,
                                    "payAmount"
                                  )
                                }
                              />
                              <small>
                                معادل{" "}
                                <NumberFormat
                                  value={amountConvert(
                                    item.currency.code,
                                    "payAmount"
                                  )}
                                  displayType="text"
                                  thousandSeparator={true}
                                />{" "}
                                {currencyTitle()}
                              </small>
                            </div>

                            <Button
                              variant="primary"
                              className="max-content"
                              disabled={disablePay(
                                item.currency.code,
                                item.amount
                              )}
                              onClick={() =>
                                setWalletPayAmount(item.currency.code)
                              }
                            >
                              اعمال شود
                            </Button>
                          </Col>
                        </Row>
                      ))}

                      <Row>
                        <Col md="8">
                          <h5 className="mb-4 mt-3">
                            مبلغ استفاده شده از کیف پول{" "}
                            <NumberFormat
                              value={totelWalletPayAmount()}
                              displayType="text"
                              thousandSeparator={true}
                            />
                          </h5>
                        </Col>

                        <Col md="4">
                          <Button
                            variant="primary"
                            size="lg"
                            className="w-100 pill"
                            disabled={totelWalletPayAmount() < payablePrice()}
                            onClick={() => payWithWallet()}
                          >
                            پرداخت
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

CartPriceDetail.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
  coupon: PropTypes.string.isRequired,
  splitedOrderItems: PropTypes.object,
  decreaceCredit: PropTypes.bool,
  cartOrder: PropTypes.object.isRequired,
  descriptionList: PropTypes.object,
};

const mapDispatchToProps = {
  setCartOrder,
};

const mapStateToProps = (state) => ({
  cart: state.cartReducer.cart,
  selectedAddressCart: state.cartReducer.selectedAddressCart,
  coupon: state.cartReducer.coupon,
  splitedOrderItems: state.cartReducer.splitedOrderItems,
  cartOrder: state.cartReducer.cartOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPriceDetail);
