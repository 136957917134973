import React from "react";
import PropTypes from "prop-types";

//
import { Col, Container, Row } from "react-bootstrap";

//
import SkeletopBox from "../globals/SkeletopBox";

function BigBanners({ loading, banners }) {
  return (
    <div className="big-banner">
      <Container>
        {loading ? (
          <Row>
            <Col md={6} className="gutter-b">
              <SkeletopBox height={200} />
            </Col>

            <Col md={6} className="gutter-b">
              <SkeletopBox height={200} />
            </Col>
          </Row>
        ) : (
          <Row>
            {banners.home &&
              banners.home.map((item) => (
                <Col md={6} className="gutter-b" key={item.id}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="d-flex">
                    <img src={item.image} alt="banner" className="img-fluid" />
                  </a>
                </Col>
              ))}
          </Row>
        )}
      </Container>
    </div>
  );
}

BigBanners.propTypes = {
  loading: PropTypes.bool.isRequired,
  banners: PropTypes.object.isRequired,
};

export default BigBanners;
