import React, { useEffect } from "react";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Tab, Tabs } from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";
import NotificationList from "../../components/panel/NotificationList";
import NotificationConfig from "../../components/panel/NotificationConfig";

//
import { getNotificationsUnreadCount } from "../../redux/features/notification/actions";


function Notification({ getNotificationsUnreadCount }) {
  const [seo, set_seo] = useSeo();

  useEffect(() => {
    set_seo({}, "اطلاعیه ها");

    getNotificationsUnreadCount();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div id="notifications">
        <div className="page-title mb-3 mt-5 mt-md-3">اطلاعیه ها</div>

        <Card className="no-radius dark-border">
          <Tabs
            defaultActiveKey="list"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="list" title="لیست">
              <NotificationList />
            </Tab>
            <Tab eventKey="config" title="تنظیمات">
              <NotificationConfig />
            </Tab>
          </Tabs>
        </Card>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getNotificationsUnreadCount,
};

export default connect(null, mapDispatchToProps)(Notification);
