import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//
import { Col, Container, Row } from "react-bootstrap";

function Valuation({ configs }) {
  const items = [1, 2, 3, 4, 5, 6];

  return (
    <div className="valuation">
      <Container>
        <Row>
          {items.map((item) => (
            <Col xs={6} sm={4} lg={2} className="item gutter-b" key={item}>
              <img src={configs[`SHOP_FEATURES_ICON${item}`]} alt="icon" />
              <p>{configs[`SHOP_FEATURES_TITLE${item}`]}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

Valuation.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(Valuation);
