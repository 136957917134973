import React from "react";
import PropTypes from "prop-types";

//
import { Modal } from "react-bootstrap";

//
import Telegram from "../../assets/img/socials/Telegram.png";
import Whatapp from "../../assets/img/socials/whatapp.png";

function ShareProductModal({ slug , show, setShow }) {
  const copy = () => {
    let input = document.getElementById("link");
    input.select();
    document.execCommand("copy");
  };

  const productLink = `${window.origin}/app/product/${slug}`

  return (
    <Modal
      id="share-product-modal"
      size="lg"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton className="no-border"></Modal.Header>
      <Modal.Body>
        <h5 className="text-center">با همکاران خود به اشتراک بگذارید</h5>

        <div className="socials">
          <a
            href={`sms:?body=${productLink}`}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex d-md-none"
          >
            <span>SMS</span>
          </a>

          <a
            href={`https://telegram.me/share/url?url=${productLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Telegram} alt="telegram" />
          </a>

          <a
            href={`https://api.whatsapp.com/send?text=${productLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Whatapp} alt="whataspp" />
          </a>
        </div>

        <div className="link">
          <input
            type="text"
            value={productLink}
            readonly
            id="link"
          />
          <span onClick={() => copy()}>کپی</span>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ShareProductModal.propTypes = {
  slug: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ShareProductModal;
