import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

//
import { Button, Form, Spinner } from "react-bootstrap";

//
import { login } from "../../../redux/features/user/actions";
import { setUser, setToken } from "../../../redux/features/user/slice";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema = yup.object().shape({
  username: yup
    .string()
    .required("لطفا تلفن همراه یا ایمیل خود را وارد کنید")
    .trim()
    .test(
      "is-mobile-or-email",
      "تلفن همراه یا ایمیل وارد شده صحیح نیست",
      (value) => {
        const mobileRegex = /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return mobileRegex.test(value) || emailRegex.test(value);
      }
    ),
  password: yup.string().required("لطفا رمز عبور خود را وارد کنید"),
});

function PasswordLogin({
  configs,
  setUser,
  setToken,
  cart,
  loginModal,
  setAuthStep,
  closeModalAfterLogin,
}) {
  const [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect");

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /**
   * Login
   */
  const login_user = (e) => {
    // start loading
    setLoading(true);

    // variable
    let content = e;

    // request
    login({ content }).then((res) => {
      if (res) {
        setLoading(false);
        setUser(res.customer);
        setToken(res.token.access_token);
        if (!cart && !loginModal) {
          navigate(redirect ? redirect : "/app");
        } else {
          if (cart) {
            if (!res.customer.first_name) {
              setAuthStep("noName");
            }
          }

          if (closeModalAfterLogin) {
            closeModalAfterLogin(res.customer);
          }
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={login_user}
      initialValues={{
        username: "",
        password: "",
      }}
    >
      {({ handleSubmit, handleChange, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="form-group" controlId="validationFormik04">
            <Form.Label className="bold text-muted">موبایل یا ایمیل</Form.Label>
            <Form.Control
              type="text"
              name="username"
              className="form-control-solid number"
              onChange={handleChange}
              autoComplete="off"
              isInvalid={touched.username && !!errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group" controlId="validationFormik04">
            <Form.Label className="bold text-muted">رمز عبور</Form.Label>
            <Form.Control
              type="password"
              name="password"
              className="form-control-solid number"
              onChange={handleChange}
              autoComplete="off"
              isInvalid={touched.password && !!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            type="submit"
            variant="danger"
            size="lg"
            className="w-100 pill"
          >
            ورود به {configs.BRAND_NAME}
            {loading && (
              <div className="spinner-holder">
                <Spinner animation="border" variant="white" size="sm" />
              </div>
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

PasswordLogin.propTypes = {
  setUser: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  cart: PropTypes.bool.isRequired,
  loginModal: PropTypes.bool.isRequired,
  setAuthStep: PropTypes.func,
  closeModalAfterLogin: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

const mapDispatchToProps = {
  setUser,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordLogin);
