import { configureStore } from "@reduxjs/toolkit";

//
import themeReducer from "./features/theme/slice";
import userReducer from "./features/user/slice";
import mainReducer from "./features/main/slice";
import cartReducer from "./features/cart/slice";
import blogReducer from "./features/blog/slice";
import notificationReducer from "./features/notification/slice";

export const store = configureStore({
    reducer: {
        themeReducer,
        userReducer,
        mainReducer,
        cartReducer,
        blogReducer,
        notificationReducer
    },
});