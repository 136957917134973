import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { Button, Card } from "react-bootstrap";

//
import AddEditeAddress from "./AddEditeAddress";

//
import { deleteAddress, getAddress } from "../../redux/features/user/actions";
import { setSelectedAddressCart } from "../../redux/features/cart/slice";

//
import NothingToShow from "../../components/globals/NothingToShow";
import BoxLoading from "../../components/globals/BoxLoading";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Address({
  cart = false,
  setSelectedAddressCart,
  selectedAddressCart,
}) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState([]);
  const [selectedAdress, setSelectedAdress] = useState({});
  const [createAddress, setCreateAddress] = useState(false);

  /**
   * Get Address
   */
  const get_address = async () => {
    // start loading
    setLoading(true);

    // request
    await getAddress().then((res) => {
      if (res) {
        setAddress(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    set_seo({}, "آدرس ها");
    get_address();

    // eslint-disable-next-line
  }, []);

  /**
   * Close Address Box
   */
  const closeAddress = () => {
    setCreateAddress(false);
    setSelectedAdress({});
  };

  const editAddress = (address) => {
    setSelectedAdress(address);

    setCreateAddress(true);
  };

  /**
   * Delete Address
   */
  const delete_address = async (id) => {
    // start loading
    setLoading(true);

    // request
    await deleteAddress(id).then((res) => {
      if (res) {
        let addressArray = [...address];

        addressArray = addressArray.filter((item) => item.id !== id);

        setAddress(addressArray);
      }
    });

    // stop loading
    setLoading(false);
  };

  /**
   * Refresh Address
   */
  const refreshAddress = () => {
    setCreateAddress(false);
    setSelectedAdress({});

    get_address();
  };

  return (
    <>
      <MetaDecorator seo={seo} />
      
      <div className="address">
        {!cart && <div className="page-title">آدرس ها</div>}

        <Card className="dark-border gutter-b">
          <Card.Body className="panel-item-box">
            {address.length ? (
              <table>
                <thead>
                  <tr>
                    <th width="15%"></th>
                    <th width="65%"></th>
                    <th width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  {address.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() => cart && setSelectedAddressCart(item)}
                    >
                      <td className="text-center">
                        <i className="fas fa-map-marker-alt text-danger"></i>
                      </td>
                      <td>
                        <p className="mb-2">
                          <span className="text-bold"> آدرس :</span>{" "}
                          <span className="text-muted">{item.address}</span>
                        </p>
                        <p>
                          <span className="text-bold">کد پستی :</span>{" "}
                          <span className="text-muted">{item.postal_code}</span>
                        </p>
                      </td>
                      <td className="text-start">
                        {cart ? (
                          <div
                            className={`selected-address ${
                              selectedAddressCart.id === item.id ? "active" : ""
                            }`}
                          ></div>
                        ) : (
                          <>
                            <i
                              className="fas fa-trash-alt text-danger ms-2 pointer"
                              onClick={() => delete_address(item.id)}
                            ></i>
                            <i
                              className="fas fa-edit text-primary pointer"
                              onClick={() => editAddress(item)}
                            ></i>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NothingToShow text="آدرسی ثبت نشده" />
            )}

            {loading && <BoxLoading />}
          </Card.Body>
        </Card>

        <Button
          variant="primary"
          className="text-white mb-3"
          onClick={() => setCreateAddress(true)}
        >
          افزودن آدرس
        </Button>

        {createAddress && (
          <AddEditeAddress
            address={selectedAdress}
            refreshAddress={refreshAddress}
            closeAddress={closeAddress}
          />
        )}
      </div>
    </>
  );
}

Address.propTypes = {
  cart: PropTypes.bool,
  setSelectedAddressCart: PropTypes.func,
  selectedAddressCart: PropTypes.object,
};

const mapDispatchToProps = {
  setSelectedAddressCart,
};

const mapStateToProps = (state) => ({
  selectedAddressCart: state.cartReducer.selectedAddressCart,
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
