import React from "react";
import PropTypes from "prop-types";

function GeneralSpecifications({infos}) {
  return (
    <div className="general-specifications">
      <p className="title">مشخصات کلی</p>
      <div className="right-space">
        <table>
          <tbody>
            {Object.entries(infos).map(([key , value], i) => (
              <tr key={i}>
                <td className="pe-0 text-muted">{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

GeneralSpecifications.propTypes = {
  infos: PropTypes.object.isRequired,
};

export default GeneralSpecifications;
