import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import CartPriceDetail from "./CartPriceDetail";
import CartItems from "./CartItems";

//
import { getCart } from "../../redux/features/cart/actions";
import BoxLoading from "../globals/BoxLoading";

function CartProducts({ step, nextStep, getCart, cart }) {
  const [loading, setLoading] = useState(false);

  const get_cart = async () => {
    // start loading
    setLoading(true);

    // request
    await getCart();

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_cart();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="cart-products">
      {loading && <BoxLoading />}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>محصول</th>
            <th>تعداد</th>
            <th>قیمت</th>
          </tr>
        </thead>

        <tbody>
          {cart.map((item) => (
            <CartItems item={item} key={item.id} change={item.type !== 'quote'} />
          ))}
        </tbody>
      </table>

      <CartPriceDetail step={step} nextStep={nextStep} />
    </div>
  );
}

CartProducts.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  getCart,
};

const mapStateToProps = (state) => ({
  cart: state.cartReducer.cart,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartProducts);
