import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//

import { useNavigate, useSearchParams } from "react-router-dom";

//
import { setToken } from "../../redux/features/user/slice";

function ThirdPartyLogin({ setToken }) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      setToken(token);
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return <div>منتطر بمانید</div>;
}

ThirdPartyLogin.propTypes = {
  setToken: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setToken,
};

export default connect(null, mapDispatchToProps)(ThirdPartyLogin);
