import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Send Guest Code
 * @param {type: request-otp || request-guest-otp, content: {mobile}} payload
 * @returns
 */
export const sendVerificationCode = async (payload) => {
  try {
    const { data } = await axios.post(`auth/${payload.type}`, payload.content);

    //
    let customer_id = data.customer_id;
    if (customer_id) return customer_id;

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Login
 * @param {otp: boolian, content: form} payload
 */
export const login = async (payload) => {
  try {
    const { data } = await axios.post(
      `auth/login${payload.otp ? "-otp" : ""}`,
      payload.content
    );

    //
    let user = data;

    //
    return user;
  } catch (error) {
    return false;
  }
};

/**
 * Register
 * @param {form} payload
 */
export const register = async (payload) => {
  try {
    const { data } = await axios.post(
      `auth/register${payload.otp ? "-otp" : ""}`,
      payload.content
    );

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Register
 * @param {form} payload
 */
export const passwordRegister = async (payload) => {
  try {
    const { data } = await axios.post(`auth/register-simple`, payload);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Send Recovery Email
 * @param {email} payload
 */
export const sendRecoveryEmail = async (payload) => {
  try {
    await axios.post("auth/recovery", payload);

    //
    toast.success("لینک بازیابی به ایمیل شما ارسال شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Otp Recover Password
 * @param {code, userID, form} payload
 */
export const otpRecoverPassword = async (payload) => {
  try {
    await axios.post(
      `auth/change-password-otp/${payload.userID}/${payload.code}`,
      payload.content
    );

    //
    toast.success("رمز عبور شما با موفقیت تغییر کرد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Email Recover Password
 * @param {code, userID, form} payload
 */
export const emailRecoverPassword = async (payload) => {
  try {
    await axios.post(`auth/change-password/${payload.token}`, payload.content);

    //
    toast.success("رمز عبور شما با موفقیت تغییر کرد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Change Password
 * @param {form} payload
 */
export const changePassword = async (payload) => {
  try {
    await axios.post("profile/account/change-password", payload);

    //
    toast.success("رمز عبور شما با موفقیت تغییر کرد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Current User
 */
export const getCurrentUser = createAsyncThunk("getCurrentUser", async () => {
  try {
    const { data } = await axios.get("auth/info");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Update User Info
 * @param {form data} payload
 */
export const updateUserInfo = async (payload) => {
  try {
    const { data } = await axios.post("profile/account", payload.fd);

    if (!payload.register) {
      toast.success("اطلاعات شما با موفقیت بروزرسانی شد", {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"> </i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons">
            {" "}
          </i>
        ),
      });
    }

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Update User Info
 * @param {form data} payload
 */
export const changeAvatar = async (payload) => {
  try {
    const { data } = await axios.post("profile/account/avatar", payload);

    toast.success("تضویر پروفایل شما با موفقیت بروزرسانی شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Address
 */
export const getAddress = async () => {
  try {
    const { data } = await axios.get("profile/address-book");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Add New Address
 * @param {form} payload
 */
export const addNewAddress = async (payload) => {
  try {
    await axios.post("profile/address-book", payload);

    //
    toast.success("آدرس شما با موفقیت ثبت شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Update Address
 * @param {id, form} payload
 */
export const updateAddress = async (payload) => {
  try {
    await axios.put(`profile/address-book/${payload.id}`, payload.content);

    //
    toast.success("آدرس شما با موفقیت ویرایش شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Delete Address
 * @param {id} payload
 */
export const deleteAddress = async (payload) => {
  try {
    await axios.delete(`profile/address-book/${payload}`);

    //
    toast.success("آدرس شما با موفقیت حذف شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Send Product Inquire
 * @param {product_id, option_id, quantity} payload
 */
export const sendQoute = async (payload) => {
  try {
    await axios.post("profile/quotes", payload);

    //
    toast.success("استعلام قیمت شما با موفقیت ارسال شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Qoutes
 * @param {page} payload
 */
export const getQoutes = async (payload) => {
  try {
    const { data } = await axios.get("profile/quotes", {
      params: {
        page: payload,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get favorites
 */
export const getFavorites = async () => {
  try {
    const { data } = await axios.get("profile/favorite-products");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Add Favorite
 * @param {product_id} payload
 */
export const addFavorite = async (payload) => {
  try {
    await axios.get(`products/${payload}/favorite`);

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Delete Favorite
 * @param {product_id} payload
 */
export const deleteFavorite = async (payload) => {
  try {
    await axios.delete(`products/${payload}/favorite`);

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Orders
 * @param {page} payload
 */
export const getOrderList = async (payload) => {
  try {
    const { data } = await axios.get("profile/orders", {
      params: payload,
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Change Order Status To Done
 * @param {order Id} payload
 */
export const setOrderDone = async (payload) => {
  try {
    await axios.get(`/profile/orders/set-done/${payload}/`);

    //
    toast.success("وضعیت سفارش شما با موفقیت تغییر کرد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get User Comments
 * @param {page} payload
 */
export const getUserComments = async (payload) => {
  try {
    const { data } = await axios.get("profile/comments", {
      params: {
        page: payload,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get User Questions
 * @param {page} payload
 */
export const getUserQuestions = async (payload) => {
  try {
    const { data } = await axios.get("profile/questions", {
      params: {
        page: payload,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Send Contact-Us Form
 * @param {form} payload
 */
export const sendContactForm = async (payload) => {
  try {
    await axios.post("contacts", payload);

    //
    toast.success("پیام شما با موفقیت ارسال شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Send Return Order Request
 * @param {form data} payload
 */
export const sendReturnOrderRequest = async (payload) => {
  try {
    await axios.post("profile/returned-orders", payload);

    //
    toast.success("درخواست شما با موفقیت ارسال شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"> </i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

export const getUserWallets = async () => {
  try {
    const { data } = await axios.get("wallets");

    //
    return data;
  } catch (error) {
    return false;
  }
};

export const chargeUserWallet = async (payload) => {
  try {
    const { data } = await axios.post(`wallets/${payload.id}`, payload.content);

    //
    toast.success("کیف پول شما با موفقیت شارژ شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"></i>
      ),
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

export const convertWalletBalance = async (payload) => {
  try {
    await axios.post("wallets/convert", payload);

    //
    toast.success("تبدیل موجودی با موفقیت انجام شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"> </i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"></i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

export const getWalletHisory = async (payload) => {
  try {
    const { data } = await axios.get(`wallets/histories/${payload}`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Set Product Comment Question
 * @param {email} payload
 */
export const joinNewsLetter = async (payload) => {
  try {
    await axios.post(`newsletters/join`, payload);

    //
    toast.success("ایمیل شما با موفقیت صبت شد", {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"></i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"></i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};
