import React from "react";
import PropTypes from "prop-types";
import SidebarCategories from "./SidebarCategories";
import TitledBox from "./TitledBox";
import LastComments from "./LastComments";

function BlogSidebar(props) {
  return (
    <div className="sidebar">
      <SidebarCategories />

      {/* <TitledBox title="محبوب ترین های هفته اخیر" /> */}

      <TitledBox title="آخرین مطالب ها" />

      {/* <LastComments/> */}
    </div>
  );
}

BlogSidebar.propTypes = {};

export default BlogSidebar;
