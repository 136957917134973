import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const { origin } = window.location

const envBaseURL = process.env.REACT_APP_BASE_URL_API
const baseURL = envBaseURL.includes('http') ? envBaseURL : `${origin}/${envBaseURL}`;

const mainApi = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "content-language": "fa",
  },
});

mainApi.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    let cartSession = localStorage.getItem("cartSession");
    let selectedCurrency = JSON.parse(localStorage.getItem('selectedCurrency') || "{}")
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    if (cartSession) {
      config.headers.common["X-Cart-ID"] = cartSession;
    }
    if (selectedCurrency.id) {
      config.headers.common["x-currency"] = selectedCurrency.id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
mainApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    let status = error.response.status;

    let errors = error.response.data.errors;
    let message = error.response.data.message;

    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        toast.error(value[0], {
          theme: "colored",
          icon: <i className="fas fa-bell toast_icons"> </i>,
          closeButton: (
            <i className="fas fa-times-circle toast_close_button toast_icons"></i>
          ),
        });
      }
    } else {
      toast.error(message, {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"> </i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons"></i>
        ),
      });
    }

    switch (status) {
      case 401:
        let pathName = window.location.pathname;
        localStorage.removeItem("token");
        if (!pathName.includes("auth") && !pathName.includes("app") && !pathName.includes("cart")) {
          window.location = `/auth/login?redirect=${pathName}`;
        }

        break;

      case 422:
        console.log("12");
        // localStorage.setItem('path' , '/subscription/pricing')
        break;
      default:
        break;
    }

    return Promise.reject(error);
  }
);

export default mainApi;
