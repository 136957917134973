import React, { useState, useEffect } from "react";

function BackToTop() {
  const [showButton, setShowButton] = useState(false);
  const [y, setY] = useState(window.scrollY);

  /**
   * handle Scroll to top
   */
  const handleNavigation = (e) => {
    const window = e.currentTarget;

    // show scroll to top when page scroll is more than 300 and user scrolling page to top
    if (y > window.scrollY && window.pageYOffset > 300) {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      }
    } else {
      setShowButton(false);
    }

    setY(window.scrollY);
  };

  // detect page scrool
  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
   
        <button onClick={scrollToTop} className={`back-to-top ${showButton ? 'show' : 'hide'}`}>
          <i className="fas fa-chevron-up"></i>
        </button>
  
    </>
  );
}

export default BackToTop;
