import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { getBlogPost } from "../../redux/features/blog/actions";

//
import moment from "jalali-moment";
import { Card } from "react-bootstrap";

//
import Breadcrumb from "../../components/globals/Breadcrumb";
import MetaDecorator from "../../components/globals/MetaDecorator";
import SkeletopBox from "../../components/globals/SkeletopBox";

function SinglePost(props) {
  let { slug } = useParams();
  const [seo, set_seo] = useSeo();

  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);

  const [breadcrump, setBreadcrump] = useState([]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://static.addtoany.com/menu/page.js";
    script.async = true;

    document.body.appendChild(script);

    // eslint-disable-next-line
  }, []);

  /**
   * Set Breacrump
   */
  const create_breadcrump = (blog) => {
    let array = [];

    for (let i = 0; i < blog.categories.length; i++) {
      const element = blog.categories[i];
      array.push({
        id: element.id,
        type: "topic",
        slug: element.slug,
        title: element.title,
      });
    }

    setBreadcrump(array);
  };

  /**
   * Get Blog Post
   */
  const get_post = async () => {
    // start loading
    setLoading(true);

    // request
    await getBlogPost(slug).then((res) => {
      setBlog(res);
      set_seo(res.seo, res.title);
      create_breadcrump(res);
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_post();
  }, [slug]);

  return (
    <>
      <MetaDecorator seo={seo} />
      {loading ? (
        <div className="post">
          <Card className=" no-radius">
            <Card.Body className="p-0">
              <header>
                <div className="breadcrumb">
                  <SkeletopBox width={60} height={16} />
                </div>
                <SkeletopBox height={50} width={250} />
              </header>

              <SkeletopBox height={300} />

              <div className="content">
                <SkeletopBox height={20} />
                <SkeletopBox height={20} />
                <SkeletopBox height={20} />
                <SkeletopBox height={20} />
                <SkeletopBox height={20} />
                <SkeletopBox height={20} />
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <article className="post gutter-b">
          <Card className=" no-radius">
            <Card.Body className="p-0">
              <header>
                <Breadcrumb main="blog" array={breadcrump} home={false} />
                <h3>{blog.title}</h3>
              </header>
              <div className="img-box">
                <img src={blog.main_image} alt={blog.title} />
                {blog.created_at && (
                  <div className="date">
                    <h6 className="day">
                      {moment(blog.created_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("DD")}
                    </h6>
                    <p className="month">
                      {moment(blog.created_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("MMMM")}
                    </p>
                  </div>
                )}
              </div>
              <div className="content align-items-start">
                {/* <div className="reading-time">زمان مطالعه : 8 دقیقه</div> */}

                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: blog.body }}
                ></div>

                {/* Share To Any */}
                <div className="share-section">
                  <p className="mb-3">این صفحه را به اشتراک بگذارید:</p>
                  <div
                    class="a2a_kit a2a_kit_size_32 a2a_default_style"
                    data-a2a-icon-color="#e2374d"
                  >
                    <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                    <a class="a2a_button_facebook"></a>
                    <a class="a2a_button_twitter"></a>
                    <a class="a2a_button_email"></a>
                    <a class="a2a_button_whatsapp"></a>
                    <a class="a2a_button_linkedin"></a>
                    <a class="a2a_button_telegram"></a>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </article>
      )}
    </>
  );
}

SinglePost.propTypes = {};

export default SinglePost;
