import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Modal, Button, Row, Col, Form, Spinner } from "react-bootstrap";

//
import BeforeText from "../../globals/BeforeText";
import Login from "../../../pages/auth/Login";

//
import Comment from "../../../assets/img/comment.png";
import AuthImage from "../../../assets/img/auth.png";

//
import { setProductCommentQuestion } from "../../../redux/features/main/actions";

//
import { Formik } from "formik";
import * as yup from "yup";


const schema = yup.object().shape({
  body: yup.string().required(),
});

function NewCommentQuestionModal({ id, type, user }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Submit Form
   */
  const submit_form = (e) => {
    // start loading
    setLoading(true);

    // variable
    let content = e;

    // request
    setProductCommentQuestion({ id, type, content }).then((res) => {
      if (res) {
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="new-question-comment"
        onClick={() => setShow(true)}
      >
        ثبت {type === "questions" ? "پرسش" : "نظر"}
      </Button>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        id="new-question-comment-modal"
      >
        <Modal.Header className="no-border" closeButton>
          <BeforeText
            text={`ثبت ${type === "questions" ? "پرسش" : "نظر"}`}
            small
          />
          <div className="extra-info">
            {user.id ? (
              <>
                <small className="text-muted">
                  پیام شما بعد از تایید در سایت نمایش داده میشود.
                </small>
                <small className="text-muted">لطفا فارسی بنویسید.</small>
              </>
            ) : (
              <small>
                جهت ارسال {type === "questions" ? "پرسش" : "نظر"} لطفا ابتدا
                وارد حساب خود شوید
              </small>
            )}
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              {user.id ? (
                <Formik
                  validationSchema={schema}
                  onSubmit={submit_form}
                  initialValues={{
                    body: "",
                  }}
                >
                  {({ handleSubmit, handleChange, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {/* Message */}
                      <Form.Group
                        className="form-group"
                        controlId="validationFormik03"
                      >
                        <Form.Control
                          as="textarea"
                          rows="5"
                          type="text"
                          name="body"
                          placeholder={`متن ${type === "questions" ? "پرسش" : "نظر"} را وارد کنید ...`}
                          className="form-control-solid round red-focus no-resize"
                          onChange={handleChange}
                          autoComplete="off"
                          isInvalid={touched.body && !!errors.body}
                        />
                      </Form.Group>

                      <Button
                        size="lg"
                        type="submit"
                        variant="danger"
                        className="w-100 text-white pill"
                      >
                        ثبت {type === "questions" ? "پرسش" : "نظر"}
                        {loading && (
                          <div className="spinner-holder">
                            <Spinner
                              animation="border"
                              variant="white"
                              size="sm"
                            />
                          </div>
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Login loginModal />
              )}
            </Col>
            <Col md={6} className="d-none d-md-block">
              {user.id ? (
                <img src={Comment} alt="comment-image" className="img-fluid" />
              ) : (
                <img
                  src={AuthImage}
                  alt="comment-image"
                  className="img-fluid"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

NewCommentQuestionModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(NewCommentQuestionModal);
