import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { getGateways } from "../../redux/features/cart/actions.js";

//
import { Card } from "react-bootstrap";

//
import CartPriceDetail from "./CartPriceDetail";
import BoxLoading from "../globals/BoxLoading";
import PaymentGateWays from "../globals/PaymentGateWays.jsx";

function PaymentMethods({ step, nextStep, cartOrder, selectedAddressCart }) {
  const [gateWays, setGateWays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGateways, setSelectedGateways] = useState({});

  /**
   * Get Gateways
   */
  const get_gateways = async () => {
    // start loading
    setLoading(true);

    // variables
    let id = selectedAddressCart.id;

    // request
    await getGateways(id).then((res) => {
      if (res) {
        setGateWays(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_gateways();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="cart-payment-methods">
      {loading && <BoxLoading />}
      <div className="cart-heading-text">نوع پرداخت را انتخاب کنید.</div>

      <CartPriceDetail
        step={step}
        nextStep={nextStep}
        decreaceCredit={selectedGateways.type === "online"}
      />

      <div className="pay-methods">
        <Card className="dark-border">
          <Card.Body>
            <PaymentGateWays
              order_id={cartOrder.id}
              gateWays={gateWays}
              set_selected_gateways={setSelectedGateways}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

PaymentMethods.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  cartOrder: PropTypes.object.isRequired,
  selectedAddressCart: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cartOrder: state.cartReducer.cartOrder,
  selectedAddressCart: state.cartReducer.selectedAddressCart,
});

export default connect(mapStateToProps)(PaymentMethods);
