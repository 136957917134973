import { useContext } from "react";
import { ReactReduxContext } from "react-redux";

const useCurrency = () => {
    const { store } = useContext(ReactReduxContext);
    const { getState, dispatch } = store;
    const state = getState();

    const selectedCurrency = state.mainReducer.selectedCurrency;
    const storedCurrency = JSON.parse(localStorage.getItem('selectedCurrency') || "{}")

    /**
     * Check User Telegram for Connected Or Not
     */
    const currencyTitle = () => {
        return selectedCurrency.title ? selectedCurrency.title : storedCurrency.title || ""
    };

    return [currencyTitle];
};

export default useCurrency;