import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { sendContactForm } from "../../redux/features/user/actions";

//
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

//
import { Formik } from "formik";
import * as yup from "yup";

const unautorized_schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "نام باید حداقل 3 کاراکتر باشد")
    .max(64, "نام باید حداکثر 64 کاراکتر باشد")
    .required("لطفا نام خود را وارد کنید"),
  mobile: yup
    .string()
    .required("لطفا تلفن همراه خود را وارد کنید")
    .trim()
    .matches(
      /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g,
      "تلفن همراه وارد شده صحیح نیست"
    ),
  message: yup
    .string()
    .min(3, "پیام باید حداقل 3 کاراکتر باشد")
    .required("لطفا پیام خود را وارد کنید"),
});

const autorized_schema = yup.object().shape({
  message: yup
    .string()
    .min(3, "پیام باید حداقل 3 کاراکتر باشد")
    .required("لطفا پیام خود را وارد کنید"),
});

function ContactUs({ configs, user }) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);

  /**
   * Send Message
   */
  const send_message = async (e, { resetForm }) => {
    // start loading
    setLoading(true);

    // request
    await sendContactForm(e).then((res) => {
      if (res) {
        resetForm({
          values: "",
        });
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    set_seo({}, "تماس با ما");
    // eslint-disable-next-line
  }, []);

  return (
    <div id="contact-us">
      <MetaDecorator seo={seo} />

      <Container>
        <div className="main-title">تماس با ما</div>

        <Card className="dark-border no-radius">
          <Card.Body>
            <Row>
              <Col md={6}>
                <iframe
                  src="https://maps.google.com/maps?q=%D9%BE%D8%A7%D8%B1%DA%86%D9%87%20%D9%81%D8%B1%D9%88%D8%B4%DB%8C%20%D8%A2%D9%86%D9%84%D8%A7%DB%8C%D9%86%20%D8%B4%D9%87%D8%B1%D9%BE%D8%A7%D8%B1%DA%86%D9%87,%20Tehran,%20%D8%B3%D8%B1%D8%A7%DB%8C%20%D8%A7%D9%85%DB%8C%D8%B1%DA%A9%D8%A8%DB%8C%D8%B1%D8%8C,%20Iran&t=&z=9&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style={{ border: 0, borderRadius: "inherit" }}
                  allowfullscreen="true"
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </Col>

              <Col md={6}>
                {/* Address & Phone */}
                <div className="text-content">
                  <p>
                    ما همواره مشتاقانه پذیرای انتقادات و پیشنهادات شما کاربران
                    عزیز، جهت بهبود کیفیت ارائه خدمات هستیم.
                  </p>
                  <p>لطفا از طریق کانال های زیر با ما در ارتباط باشید:</p>
                </div>

                <div className="text-content mb-0">
                  <p className="title">آدرس : </p>
                  <p className="mb-2">{configs.CO_ADDRESS}</p>
                  <a
                    className="contact-phone text-primary"
                    title="تماس با ما"
                    href={`tel:${configs.CO_TELEPHONE}`}
                  >
                    {configs.CO_TELEPHONE}
                  </a>
                </div>

                {/* Contact Form */}
                <Formik
                  validationSchema={
                    user.id ? autorized_schema : unautorized_schema
                  }
                  onSubmit={send_message}
                  initialValues={{
                    name: "",
                    mobile: "",
                    message: "",
                  }}
                >
                  {({ handleSubmit, handleChange, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {!user.id && (
                        <Row>
                          {/* First Name */}
                          <Col sm={6}>
                            <Form.Group
                              className="form-group"
                              controlId="validationFormik01"
                            >
                              <Form.Label className="bold text-muted">
                                نام
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                className="form-control-solid"
                                onChange={handleChange}
                                autoComplete="off"
                                isInvalid={touched.name && !!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          {/* Mobile */}
                          <Col sm={6}>
                            <Form.Group
                              className="form-group"
                              controlId="validationFormik00"
                            >
                              <Form.Label className="bold text-muted">
                                موبایل
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="mobile"
                                className="form-control-solid number"
                                onChange={handleChange}
                                autoComplete="off"
                                isInvalid={touched.mobile && !!errors.mobile}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.mobile}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {/* Message */}

                      <Form.Group
                        className="form-group"
                        controlId="validationFormik03"
                      >
                        <Form.Control
                          as="textarea"
                          rows={user.id ? "7" : "5"}
                          type="text"
                          name="message"
                          placeholder="متن پیام را وارد کنید ..."
                          className="form-control-solid no-resize"
                          onChange={handleChange}
                          autoComplete="off"
                          isInvalid={touched.message && !!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button
                        type="submit"
                        variant="danger"
                        size="lg"
                        className="w-100 pill"
                      >
                        ارسال پیام
                        {loading && (
                          <div className="spinner-holder">
                            <Spinner
                              animation="border"
                              variant="white"
                              size="sm"
                            />
                          </div>
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

ContactUs.propTypes = {
  configs: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(ContactUs);
