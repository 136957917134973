import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//
import { Container, Row, Col, Card } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getFeaturedProducts } from "../../redux/features/main/actions";
import SkeletopBox from "../globals/SkeletopBox";

function MostPopular() {
  const title = "کالاهای پرطرفدار";
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  /**
   * Get Most Popular
   */
  const get_featured_products = async () => {
    // start loading
    setLoading(true);

    // request
    await getFeaturedProducts().then((res) => {
      if (res) {
        setItems(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_featured_products();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="most-popular home-top-box home-bottom-box">
      {loading ? (
        <>
          <Container className="mt-3">
            <Card>
              <Card.Body>
                <SkeletopBox height={30} width={50} />
                <SkeletopBox height={100} />
              </Card.Body>
            </Card>
          </Container>
        </>
      ) : (
        <>
          {/* Box For Up 768px */}
          <Container className="d-none d-sm-block">
            <Card className="no-border">
              <Card.Body>
                <div id="box-title">{title}</div>
                <Row className="justify-content-between">
                  {items.map((item) => (
                    <Col sm={4} md={2} className="item mb-3" key={item.id}>
                      <Link
                        to={`product/${item.id}`}
                        className="move-up-animate"
                      >
                        <div className="image-box image-item-size ">
                          <img
                            alt={item.title}
                            className="lazyload"
                            src={item.main_image}
                          />
                        </div>
                        <p>{item.title}</p>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Container>

          {/* Slider For Down 768px */}
          <div className="d-block d-sm-none mostpopular-slider-box">
            <div id="box-title">{title}</div>
            <Swiper
              slidesPerView={1.2}
              spaceBetween={10}
              breakpoints={{
                400: {
                  slidesPerView: 1.8,
                },
                500: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              }}
            >
              {items.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card>
                    <Card.Body>
                      <div className="item">
                        <Link to={`product/${item.id}`}>
                          <div className="image-box">
                            <img
                              alt={item.title}
                              className="lazyload"
                              src={item.main_image}
                            />
                          </div>
                          <p>{item.title}</p>
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}

export default MostPopular;
