import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { searchBlog } from "../../redux/features/blog/actions";
import { setBlogTopicTitle } from "../../redux/features/theme/slice";

//
import moment from "jalali-moment";
import { Card, Col, Row } from "react-bootstrap";

//
import Pagination from "../../components/globals/Pagination";
import SkeletopBox from "../../components/globals/SkeletopBox";
import NothingToShow from "../../components/globals/NothingToShow";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Topic({ setBlogTopicTitle, blogCategories }) {
  let { slug } = useParams();
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState({});

  const search_blog = async (page) => {
    // set variables
    let params = new URLSearchParams();
    params.set("page", page);
    params.set("blog_category_id", slug);

    // request
    await searchBlog(params).then((res) => {
      if (res) {
        setBlogs(res);
      }
    });
  };

  const set_category_title_seo = () => {
    let category = {};

    for (let i = 0; i < blogCategories.length; i++) {
      const element = blogCategories[i];

      if (slug == element.id) {
        category = element;
      } else {
        if (element.children) {
          for (let i = 0; i < element.children.length; i++) {
            const child = element.children[i];
            if (slug == child.id) {
              category = child;
            }
          }
        }
      }
    }
    setBlogTopicTitle(category.title);

    set_seo(category.seo, category.title);
  };

  useEffect(() => {
    set_category_title_seo();

    return () => {
      setBlogTopicTitle("");
    };

    // eslint-disable-next-line
  }, [slug]);

  useEffect(async () => {
    setLoading(true);
    await search_blog(1);
    setLoading(false);

    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div className="topic">
        {loading ? (
          <Card className="no-radius box-shadow dark-border gutter-b item">
            <Card.Body className="p-0">
              <Row>
                <Col sm={4} className="d-none d-sm-block">
                  <SkeletopBox height={126} borderRadius={6} />
                </Col>
                <Col sm={4} className="d-block d-sm-none">
                  <SkeletopBox height={260} borderRadius={6} />
                </Col>
                <Col sm={8}>
                  <div className="text-box">
                    <p className="title">
                      <SkeletopBox height={25} />
                    </p>
                    <p className="desc">
                      <SkeletopBox height={22} />
                    </p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <>
            {blogs.data && blogs.data.length ? (
              <>
                {blogs.data.map((item) => (
                  <article key={item.id}>
                    <Link to={`/blog/post/${item.id}`}>
                      <Card className="no-radius box-shadow dark-border gutter-b item">
                        <Card.Body className="p-0">
                          <Row>
                            <Col sm={4}>
                              <img
                                src={item.main_image}
                                alt="blog"
                                className="img-fluid"
                              />
                              <div className="date">
                                <h6 className="day">
                                  {moment(item.created_at, "YYYY/MM/DD")
                                    .locale("fa")
                                    .format("DD")}
                                </h6>
                                <p className="month">
                                  {moment(item.created_at, "YYYY/MM/DD")
                                    .locale("fa")
                                    .format("MMMM")}
                                </p>
                              </div>
                            </Col>
                            <Col sm={8}>
                              <div className="text-box">
                                <p className="title">{item.title}</p>
                                <p
                                  className="desc"
                                  dangerouslySetInnerHTML={{
                                    __html: item.body_short,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Link>
                  </article>
                ))}
              </>
            ) : (
              <Card className="gutter-b">
                <Card.Body>
                  <NothingToShow text="موردی برای نمایش وجود ندارد" />
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </div>

      {blogs.meta && blogs.meta.last_page > 1 && (
        <div className="mt-3 mb-5">
          <Pagination
            styled
            currentPage={blogs.meta.current_page - 1}
            lastPage={blogs.meta.last_page}
            changePage={(page) => search_blog(page.selected + 1)}
          />
        </div>
      )}
    </>
  );
}

Topic.propTypes = {
  setBlogTopicTitle: PropTypes.func.isRequired,
  blogCategories: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  setBlogTopicTitle,
};

const mapStateToProps = (state) => ({
  blogCategories: state.blogReducer.blogCategories,
});

export default connect(mapStateToProps, mapDispatchToProps)(Topic);
