import React from 'react'
import { Spinner } from 'react-bootstrap'

function BoxLoading() {
    return (
        <div id='box-loading'>
            <Spinner animation="grow" variant="black" />
        </div>
    )
}

export default BoxLoading

