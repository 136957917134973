import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import moment from "jalali-moment";
import { Col, Row } from "react-bootstrap";

function BlogItem({ item, big = false }) {
  return (
    <Col md={big ? 6 : 12} className="blog-item gutter-b">
      <article>
        <Link to={`/blog/post/${item.id}`}>
          <Row>
            <Col md={big ? 12 : 4}>
              <img src={item.main_image} alt={item.title} className="img-fluid" />
            </Col>
            <Col md={big ? 12 : 8} className="text-box">
              <div className="category">
                {item.categories.length > 1
                  ? item.categories[1].title
                  : item.categories[0].title}
              </div>
              <p className="title text-danger">{item.title}</p>
              <p className="date">
                {moment(item.created_at, "YYYY/MM/DD")
                  .locale("fa")
                  .format("DD MMMM YYYY")}
              </p>
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: item.body_short }}
              ></p>
            </Col>
          </Row>
        </Link>
      </article>
    </Col>
  );
}

BlogItem.propTypes = {
  big: PropTypes.bool,
  item: PropTypes.object.isRequired,
};

export default BlogItem;
