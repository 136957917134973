import React from "react";
import PropTypes from "prop-types";

//
import { Card } from "react-bootstrap";

function GradientCard({ text, image, imageBackground, imageWhite = false }) {
  return (
    <Card className="dark-gradient dark-border box-shadow ">
      <Card.Body>
        <div
          className="icon-holder"
          style={{ backgroundColor: imageBackground }}
        >
          <img
            src={process.env.PUBLIC_URL + `/assets/svg-icons/${image}.svg`}
            alt="icon"
            className={imageWhite ? "white" : ""}
          />
        </div>
        <span>{text}</span>
      </Card.Body>
    </Card>
  );
}

GradientCard.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageBackground: PropTypes.string,
  imageWhite: PropTypes.bool,
};

export default GradientCard;
