import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Col, Container, Row } from "react-bootstrap";

//
import BigBanners from "../../components/dashboard/BigBanners";
import BeforeText from "../../components/globals/BeforeText";
import SkeletopBox from "../../components/globals/SkeletopBox";

//
import { getCategoryInfo, getBanners } from "../../redux/features/main/actions";

//
import MetaDecorator from "../../components/globals/MetaDecorator";


function CategoryInfo(props) {
  let { slug } = useParams();
  let navigate = useNavigate();

  const [seo, set_seo] = useSeo();

  // category
  const [categoryInfo, setCategoryInfo] = useState({});
  const [categoryInfoLoading, setCategoryInfoLoading] = useState(false);

  // Banners
  const [banners, setBanners] = useState({});
  const [bannerLoading, setBannerLoading] = useState(false);

  /**
   * Get Category Info
   * this page just show parent category
   * check this in if with parent parameter
   */
  const get_category_info = () => {
    if (slug) {
      // start loading
      setCategoryInfoLoading(true);

      // request
      getCategoryInfo(slug).then((res) => {
        if (res) {
          if (res.parent) {
            navigate(`/app/category/${slug}`);
          } else {
            setCategoryInfo(res);
            set_seo(res.seo, res.title);
          }
          setCategoryInfoLoading(false);
        }
      });
    }
  };

  /**
   * Get Banners
   */
  const get_banners = async () => {
    // start loading
    setBannerLoading(true);

    // request
    await getBanners().then((res) => {
      if (res) {
        setBanners(res);
      }
    });

    // stop loading
    setBannerLoading(false);
  };

  useEffect(() => {
    get_category_info();
  }, [slug]);

  useEffect(() => {
    get_banners();

    // eslint-disable-next-line
  }, []);

  return (
    <div id="main-category">
      <MetaDecorator seo={seo} />
      <Container>
        {categoryInfo.horizontal_cover_image && (
          <header>
            <img
              src={categoryInfo.horizontal_cover_image}
              alt="banner"
              className="w-100"
            />

            <p className="title">{categoryInfo.title}</p>
          </header>
        )}

        <div
          className={`d-block d-sm-${
            categoryInfo.horizontal_cover_image ? "none" : "block"
          }`}
        >
          <BeforeText simple text={categoryInfo.title || ""} />
        </div>
        <Card className="gutter-b main-card no-radius no-border">
          <Card.Body>
            <Row>
              {categoryInfoLoading ? (
                <Col md={6} lg={4}>
                  <Card className="item">
                    <Card.Header>
                      <SkeletopBox height={180} borderRadius={5} />
                    </Card.Header>

                    <Card.Body>
                      <p className="title">
                        <SkeletopBox height={24} width={150} />
                      </p>
                      <p className="main-link">
                        <SkeletopBox height={21} width={100} />
                      </p>
                      <p className="description">
                        <SkeletopBox height={57} />
                      </p>
                      <div className="childrens">
                        <Row>
                          <Col sm={6} md={12}>
                            <SkeletopBox height={15} width={100} />
                          </Col>
                          <Col sm={6} md={12}>
                            <SkeletopBox height={15} width={100} />
                          </Col>
                          <Col sm={6} md={12}>
                            <SkeletopBox height={15} width={100} />
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <>
                  {categoryInfo.children &&
                    categoryInfo.children.map((item) => (
                      <Col md={6} lg={4} key={item.id} className="gutter-b">
                        <Card className="item">
                          <Card.Header>
                            {item.horizontal_cover_image ? (
                              <img
                                src={item.horizontal_cover_image}
                                alt="item"
                                className="img-fluid"
                              />
                            ) : (
                              <SkeletopBox height={180} borderRadius={5} />
                            )}
                          </Card.Header>

                          <Card.Body>
                            <p className="title">{item.title}</p>
                            <Link
                              to={`/app/category/${item.id}`}
                              className="main-link"
                            >
                              {item.children_count} دسته بندی
                              <i className="fa fa-chevron-left me-1"></i>
                            </Link>
                            <p className="description">
                              {item.short_description}
                            </p>
                            <div className="childrens">
                              <Row>
                                {item.children.map((child) => (
                                  <Col sm={6} md={12} key={child.id}>
                                    <Link to={`/app/category/${child.id}`}>
                                      {child.title}
                                    </Link>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <BigBanners banners={banners} loading={bannerLoading} />
    </div>
  );
}

CategoryInfo.propTypes = {};

export default CategoryInfo;
