import { createSlice } from "@reduxjs/toolkit";
import { getCurrencies, getConfigs, getCategories, getFooterMenus } from "./actions";

const initialState = {
    currencies: [],
    selectedCurrency: [],
    configs: {},
    categories: [],
    footerLinks: {},
};

export const mainSlice = createSlice({
    name: "Main",
    initialState,
    reducers: {},

    extraReducers: {
        [getCurrencies.fulfilled]: (state, action) => {
            let currencies = action.payload;

            if (currencies) {
                state.currencies = currencies;

                for (let i = 0; i < currencies.length; i++) {
                    const element = currencies[i];
                    if (element.is_default) {
                        state.selectedCurrency = element
                        localStorage.setItem('selectedCurrency', JSON.stringify(element))
                        break
                    }
                }
            }
        },

        [getConfigs.fulfilled]: (state, action) => {
            let configs = action.payload;

            if (configs) {
                state.configs = configs;
            }
        },

        [getCategories.fulfilled]: (state, action) => {
            let categories = action.payload;

            if (categories) {
                state.categories = categories;
            }
        },

        [getFooterMenus.fulfilled]: (state, action) => {
            let links = action.payload;

            if (links) {
                state.footerLinks = links;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {} = mainSlice.actions;

export default mainSlice.reducer;