import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { Accordion, Button, Form } from "react-bootstrap";

//
import RangeSlider from "./RangeSlider";
import SkeletopBox from "../globals/SkeletopBox";


function Filter({
  info,
  loading,
  min_price,
  max_price,
  setSelectedMinMax,
  setSelectedAttributes,
  availables,
  setAvailables,
}) {
  const [currencyTitle] = useCurrency();
  
  const [attributes, setAttributes] = useState([]);
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    if (info && info.attributes) {
      let newAttributes = [];

      for (let i = 0; i < info.attributes.length; i++) {
        const element = info.attributes[i];
        let current = { ...element };
        let available_values = [];

        for (let i = 0; i < current.available_values.length; i++) {
          const attr = current.available_values[i];

          available_values.push({
            name: attr,
            value: false,
          });
        }

        current.available_values = available_values;
        newAttributes.push(current);
      }

      setAttributes(newAttributes);
    }
  }, [info]);

  const changeAttr = (parent, index) => {
    let attr = [...attributes];

    attr[parent].available_values[index].value =
      !attr[parent].available_values[index].value;

    setAttributes(attr);

    // send selected values to parent
    let selectedAttributes = [];
    for (let i = 0; i < attributes.length; i++) {
      const element = attributes[i];
      let active_values = [];

      for (let i = 0; i < element.available_values.length; i++) {
        const val = element.available_values[i];

        if (val.value) {
          active_values.push(val.name);
        }
      }

      if (active_values.length) {
        selectedAttributes.push({
          id: element.id,
          values: active_values,
        });
      }
    }

    setSelectedAttributes(selectedAttributes);
  };

  return (
    <>
      {loading ? (
        <SkeletopBox height={300} />
      ) : (
        <div className="filters">
          {/* Children */}
          {!!(info.children && info.children.length) && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header> زیر دسته های {info.title}</Accordion.Header>
                <Accordion.Body className="children">
                  {info.children.map((item) => (
                    <Link to={`/app/category/${item.id}`}>
                      {item.title} (
                      {item.products_count ? item.products_count : 0})
                    </Link>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/* Price */}
          {!!max_price && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  قیمت
                  <span>({currencyTitle()})</span>
                </Accordion.Header>
                <Accordion.Body>
                  <RangeSlider
                    key={min_price}
                    min={min_price}
                    max={max_price}
                    onChange={({ min, max }) => setMinMax({ min, max })}
                  />
                  <Button
                    variant="primary"
                    className="price-btn"
                    onClick={() => setSelectedMinMax(minMax)}
                  >
                    اعمال فیلتر قیمت
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/* Just availables */}
          <div className="filter-box">
            <Form.Check
              type="switch"
              id="just-available"
              label="فقط کالاهای موجود"
              checked={availables}
              onChange={() => setAvailables(!availables)}
            />
          </div>

          {/* Attribiutes */}
          {attributes.map((item, index) => (
            <>
              {item.available_values.length > 1 && (
                <Accordion key={item.id}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>
                      {item.available_values.map((item, i) => (
                        <Form.Check
                          key={i}
                          type="switch"
                          id={i}
                          label={item.name}
                          checked={item.value}
                          onChange={() => changeAttr(index, i)}
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
}

Filter.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  min_price: PropTypes.number.isRequired,
  max_price: PropTypes.number.isRequired,
  setSelectedMinMax: PropTypes.func.isRequired,
  setSelectedAttributes: PropTypes.func.isRequired,
};

export default Filter;
