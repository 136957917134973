import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getCategoryBlogs } from "../../../redux/features/blog/actions";

const colors = [
  "#e25737",
  "#e29637",
  "#4db32c",
  "#b32c6f",
  "#2cb3b3",
  "#2c5cb3",
  "#a42cb3",
  "#f8d800",
];
function SidebarCategories(props) {
  const [categoryBlog, setCategoryBlog] = useState([]);

  /**
   * Get Category Blogs
   */
  const get_category_blog = () => {
    getCategoryBlogs().then((res) => {
      if (res) {
        console.log(res);
        setCategoryBlog(res);
      }
    });
  };

  useEffect(() => {
    get_category_blog();

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!!categoryBlog.length && (
        <div className="categories gutter-b">
          {categoryBlog.map((item, index) => (
            <Link
              key={item.id}
              to="/blog"
              className="item"
              style={{ backgroundColor: colors[index] }}
            >
              <p className="text-shadow">{item.title}</p>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

SidebarCategories.propTypes = {};

export default SidebarCategories;
