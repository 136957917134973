import React from "react";
import PropTypes from "prop-types";

//
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

function PaginationBox({ windowSize, currentPage, lastPage, changePage }) {
  const change_page = (type) => {
    let selected = type === "next" ? currentPage + 1 : currentPage - 1;

    changePage({ selected });
  };
  return (
    <Card className="pagination-box gutter-b">
      <Card.Body>
        <div className="change-page">
          {currentPage > 0 && (
            <div onClick={() => change_page("prev")}>
              <i className="fas fa-arrow-right"></i>
              &nbsp; صفحه قبل
            </div>
          )}
        </div>

        {windowSize > 576 ? (
          <ReactPaginate
            breakLabel=""
            onPageChange={changePage}
            pageRangeDisplayed={5}
            pageCount={lastPage}
            marginPagesDisplayed={0}
            forcePage={currentPage}
            className="pagination"
            previousClassName="d-none"
            nextClassName="d-none"
          />
        ) : (
          <h6 className="text-bold">صفحه {currentPage + 1}</h6>
        )}

        <div className="change-page">
          {currentPage + 1 != lastPage && (
            <div onClick={() => change_page("next")}>
              صفحه بعد &nbsp;
              <i className="fas fa-arrow-left"></i>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

PaginationBox.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(PaginationBox);
