export const categorySort = [
  {
    name: "جدیدترین",
    value: "newest",
  },
  {
    name: "پربازدیدترین",
    value: "views",
  },
  {
    name: "ارزان ترین",
    value: "price",
    type: "asc",
  },
  {
    name: "گران ترین",
    value: "price",
    type: "desc",
  },
];

export const findBoxOptions = [
  {
    name: "لطفا مسئله خود را انتخاب کنید",
    value: 0,
  },
  {
    name: "محصول مورد نظر موجود نیست",
    value: 1,
  },
  {
    name: "تنوع کافی نیست",
    value: 2,
  },
  {
    name: "نتایج بی ربط است",
    value: 3,
  },
  {
    name: "بالاتر از بودجه من است",
    value: 4,
  },
  {
    name: "گزینه مرتب سازی / فیلتر مفید نیست",
    value: 5,
  },
];

export const profileMenuLinks = [
  {
    name: "داشبورد",
    link: "",
    imgName: "dashboard",
  },
  {
    name: "کیف پول ها",
    link: "wallets",
    imgName: "wallet",
  },
  {
    name: "آدرس ها",
    link: "address",
    imgName: "map-location",
  },
  {
    name: "لیست سفارشات",
    link: "orders",
    imgName: "list",
  },
  {
    name: "لیست استعلامات",
    link: "quotes",
    imgName: "clipboard-list",
  },
  {
    name: "اطلاعیه ها",
    link: "notification",
    imgName: "bell",
    counterKey: "unreaCount"
  },
  {
    name: "علاقه مندی ها",
    link: "favorites",
    imgName: "heart",
  },
  {
    name: "نظرات",
    link: "comments",
    imgName: "comments",
  },
  {
    name: "پرسش و پاسخ",
    link: "question-answer",
    imgName: "question-answer",
  },
];

export const cartSteps = [
  {
    name: "سبد خرید",
    step: 1,
  },
  {
    name: "اطلاعات و نحوه ارسال",
    step: 2,
  },
  {
    name: "تایید سفارش",
    step: 3,
  },
  {
    name: "پرداخت",
    step: 4,
  },
];

export const orderListSteps = [
  {
    name: "در انتظار پرداخت",
    step: 1,
    image: true,
  },
  {
    name: "پرداخت شده",
    step: 2,
    image: true,
  },
  {
    name: "در حال آماده سازی",
    otherName: "لغو شده",
    step: 3,
    image: true,
  },
  {
    name: "ارسال شده",
    step: 4,
    image: true,
  },
  {
    name: "تحویل شده",
    otherName: "تحویل شده (سالم)",
    step: 5,
    image: true,
  },
  {
    name: "اتمام خرید",
    step: 6,
    image: true,
  },
];

export const socials = [
  {
    title: "اینستاگرام",
    link: "SN_INSTAGRAM",
    icon: "fab fa-instagram",
  },
  {
    title: "لینکدین",
    link: "SN_LINKEDIN",
    icon: "fab fa-linkedin",
  },
  {
    title: "تلگرام",
    link: "SN_TELEGRAM",
    icon: "fab fa-telegram",
  },
  {
    title: "آپارات",
    link: "SN_APARAT",
    image: "aparat",
  },
  {
    title: "یوتوب",
    link: "SN_YOUTUBE",
    icon: "fab fa-youtube",
  },
];

export const blogSocials = [
  {
    title: "اینستاگرام",
    link: "SN_INSTAGRAM",
    icon: "fab fa-instagram",
  },
  {
    title: "لینکدین",
    link: "SN_LINKEDIN",
    icon: "fab fa-linkedin",
  },
  {
    title: "یوتوب",
    link: "SN_YOUTUBE",
    icon: "fab fa-youtube",
  },
];
