import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import { searchBlog } from "../../../redux/features/blog/actions";

//
import { Form, Spinner } from "react-bootstrap";

function MobileMenu({ blogCategories }) {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const toggleClass = (id) => {
    let element = document.getElementById(id);

    element.classList.toggle("active");
  };

  /**
   * Search Blog
   */
  const search_blog = async () => {
    if (search) {
      // start loading
      setLoading(true);

      // set variables
      let params = new URLSearchParams();
      params.set("query", search);
      params.set("page_size", 20);
      params.set("page", 1);

      // request
      await searchBlog(params).then((res) => {
        if (res) {
          setSearchResult(res);
        }
      });

      // stop loading
      setLoading(false);
    }
  };

  useEffect(() => {
    search_blog();

    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="mobile-menu d-block d-lg-none">
      <i className="fas fa-bars text-muted" onClick={() => setShow(true)}></i>

      <div className={`fix-box ${show ? "active" : ""}`}>
        <i
          className="fas fa-times close-icon pointer"
          onClick={() => setShow(false)}
        ></i>
        <div className="content w-100">
          <div className="search-content">
            <div className="input-holder">
              <Form.Control
                type="text"
                className="form-control-solid"
                onChange={(e) => setSearch(e.target.value)}
                autoComplete="off"
                value={search}
              />
              <div className="icon-holder">
                {loading ? (
                  <Spinner animation="border" variant="white" size="sm" />
                ) : (
                  <i className="fas fa-search"></i>
                )}
              </div>
            </div>

            {search && (
              <div className="search-result">
                {searchResult.length ? (
                  <>
                    {searchResult.map((item) => (
                      <Link
                        className="item"
                        to={`/blog/post/${item.id}`}
                        onClick={() => setShow(false)}
                      >
                        <img src={item.main_image} alt={item.title} />
                        <p>{item.title}</p>
                      </Link>
                    ))}
                  </>
                ) : (
                  <div className="item">
                    <p>موردی یافت نشد</p>
                  </div>
                )}
              </div>
            )}
          </div>

          <ul className="menu">
            <li className="menu-item">
              <Link
                to="/blog"
                className="menu-item-link"
                onClick={() => setShow(false)}
              >
                صفحه اصلی
              </Link>
            </li>

            {blogCategories.map((item) => (
              <>
                {item.children.length ? (
                  <li
                    id={`menu-item-${item.id}`}
                    className="menu-item"
                    key={item.id}
                  >
                    <a
                      className="menu-item-link"
                      onClick={() => toggleClass(`menu-item-${item.id}`)}
                    >
                      {item.title}

                      <i className="fas fa-chevron-down"></i>
                    </a>

                    <ul className="children">
                      {item.children.map((child) => (
                        <li key={child.id}>
                          <Link
                            to={`/blog/topic/${child.id}`}
                            onClick={() => setShow(false)}
                          >
                            {child.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li className="menu-item" key={item.id}>
                    <Link
                      to={`/blog/topic/${item.id}`}
                      className="menu-item-link"
                      onClick={() => setShow(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  blogCategories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  blogCategories: state.blogReducer.blogCategories,
});

export default connect(mapStateToProps)(MobileMenu);
