import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//
import Countdown from "react-countdown";
import { Button, Form, Spinner } from "react-bootstrap";

//
import BeforeText from "../../components/globals/BeforeText";

//
import { Formik } from "formik";
import * as yup from "yup";

//
import {
  otpRecoverPassword,
  sendVerificationCode,
} from "../../redux/features/user/actions";

//
const schema_step_1 = yup.object().shape({
  mobile: yup
    .string()
    .required("لطفا تلفن همراه خود را وارد کنید")
    .trim()
    .matches(
      /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g,
      "تلفن همراه وارد شده صحیح نیست"
    ),
});

const schema_step_2 = yup.object().shape({
  code: yup
    .number()
    .required("لطفا کد تایید ارسال شده به تلفن همراه خود را وارد کنید"),
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
  password_confirmation: yup
    .string()
    .required("لطفا تکرار رمز عبور خود را وارد کنید")
    .oneOf([yup.ref("password"), null], "رمز عبور ها باید یکسان باشند"),
});

function OtpRecovery({ cart = false, setAuthStep }) {
  let navigate = useNavigate();

  //
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);

  //
  const [sendCode, setSendCode] = useState(false);
  const [timerKey, setTimerKey] = useState(0);

  useEffect(() => {
    setStep(1);

    // eslint-disable-next-line
  }, []);

  /**
   * Renderer callback with condition
   */
  const renderer = ({ minutes, seconds }) => {
    return (
      <p className="text-muted">
        دریافت کد تایید جدید در {minutes}:{seconds}
      </p>
    );
  };

  const timerComplete = () => {
    setSendCode(true);
  };

  /**
   * Send Code
   */
  const send_code = (e) => {
    // start loading
    setLoading(true);

    // variables
    let type = "request-otp";
    let content = e;

    // request
    sendVerificationCode({ type, content }).then((res) => {
      if (res) {
        setLoading(false);
        setStep(2);
        setUserId(res);
        setTimerKey(timerKey + 1);
        setSendCode(false);
      } else {
        setLoading(false);
      }
    });
  };

  /**
   * Change Password
   */
  const change_password = (e) => {
    // start loading
    setLoading(true);

    // variable
    let code = e.code;
    let content = {
      password: e.password,
      password_confirmation: e.password_confirmation,
    };
    let userID = userId;

    // request
    otpRecoverPassword({ code, content, userID }).then((res) => {
      if (res) {
        setLoading(false);
        if (cart) {
          setAuthStep("login");
        } else {
          navigate("/auth/login");
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className={`recovery ${cart ? 'mt-3' : ''}`}>
      {!cart && <BeforeText text="بازیابی رمز عبور با موبایل" />}

      {step === 1 ? (
        <Formik
          validationSchema={schema_step_1}
          key={step}
          onSubmit={send_code}
          initialValues={{
            mobile: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Mobile */}
              <Form.Group className="form-group" controlId="validationFormik00">
                <Form.Label className="bold text-muted">موبایل</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.mobile && !!errors.mobile}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobile}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                variant="danger"
                size="lg"
                className="w-100 pill"
              >
                ارسال کد تایید
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          validationSchema={schema_step_2}
          key={step}
          onSubmit={change_password}
          initialValues={{
            code: "",
            password: "",
            password_confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Password */}
              <Form.Group className="form-group" controlId="validationFormik06">
                <Form.Label className="bold text-muted">رمز عبور</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Password Confirmation */}
              <Form.Group className="form-group" controlId="validationFormik07">
                <Form.Label className="bold text-muted">
                  تکرار رمز عبور
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password_confirmation"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={
                    touched.password_confirmation &&
                    !!errors.password_confirmation
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Code */}
              <Form.Group className="form-group" controlId="validationFormik08">
                <Form.Label className="bold text-muted">کد تایید</Form.Label>
                <Form.Control
                  type="number"
                  name="code"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.code && !!errors.code}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-2">
                {sendCode ? (
                  <p
                    className="text-muted pointer"
                    onClick={() => send_code({ mobile })}
                  >
                    دریافت کد تایید
                  </p>
                ) : (
                  <Countdown
                    date={Date.now() + 180000}
                    renderer={renderer}
                    zeroPadTime={2}
                    onComplete={timerComplete}
                  />
                )}
              </div>

              <Button
                type="submit"
                variant="danger"
                size="lg"
                className="w-100 pill"
              >
                تغییر رمز عبور
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      )}

      {!cart && (
        <p
          className="pointer mt-3 text-danger d-inline-block"
          onClick={() => navigate("/auth/email-recovery")}
        >
          بازیابی با ایمیل
        </p>
      )}

      {cart && (
        <p
          className="text-center my-4 pointer"
          onClick={() => setAuthStep("login")}
        >
          بازگشت
        </p>
      )}
    </div>
  );
}

OtpRecovery.propTypes = {};

export default OtpRecovery;
