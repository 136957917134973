import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//
import { Button, Form, Spinner } from "react-bootstrap";

//
import { updateUserInfo } from "../../redux/features/user/actions";
import { setUser } from "../../redux/features/user/slice";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema_password = yup.object().shape({
  type: yup.string().required(),
  email: yup.string().email("ایمیل وارد شده صحیح نیست"),
  mobile: yup.string().required("لطفا شماره تلفن همراه خود را وارد کنید"),
  first_name: yup
    .string()
    .min(3, "نام باید حداقل 3 کاراکتر باشد")
    .max(64, "نام باید حداکثر 64 کاراکتر باشد")
    .required("لطفا نام خود را وارد کنید"),
  last_name: yup
    .string()
    .min(3, "نام باید حداقل 3 کاراکتر باشد")
    .max(64, "نام باید حداکثر 64 کاراکتر باشد")
    .required("لطفا نام خانوادگی خود را وارد کنید"),
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
  password_confirmation: yup
    .string()
    .required("لطفا تکرار رمز عبور خود را وارد کنید")
    .oneOf([yup.ref("password"), null], "رمز عبور ها باید یکسان باشند"),
  identify_code: yup.string().required("لطفا کد شناسایی خود را وارد کنید"),
});

const schema = yup.object().shape({
  type: yup.string().required(),
  email: yup.string().email("ایمیل وارد شده صحیح نیست"),
  mobile: yup.string().required("لطفا شماره تلفن همراه خود را وارد کنید"),
  first_name: yup
    .string()
    .min(3, "نام باید حداقل 3 کاراکتر باشد")
    .max(64, "نام باید حداکثر 64 کاراکتر باشد")
    .required("لطفا نام خود را وارد کنید"),
  last_name: yup
    .string()
    .min(3, "نام باید حداقل 3 کاراکتر باشد")
    .max(64, "نام باید حداکثر 64 کاراکتر باشد")
    .required("لطفا نام خانوادگی خود را وارد کنید"),
  identify_code: yup.string().required("لطفا کد شناسایی خود را وارد کنید"),
});

function UserNeedInfo({
  cart = false,
  password = false,
  configs,
  user,
  setUser,
}) {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    type: "natural",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    password_confirmation: "",
    identify_code: "",
  });

  const updateInitialValues = () => {
    let values = { ...initialValues };

    for (const [userKey, userValue] of Object.entries(user)) {
      for (const [key, value] of Object.entries(values)) {
        if (userValue && userKey === key) {
          values[key] = userValue;
        }
      }
    }

    setInitialValues(values);
  };

  useEffect(() => {
    updateInitialValues();
  }, [user]);

  const skip = () => {
    navigate("/app");
  };

  /**
   * Set Password
   */
  const set_profile = async (e) => {
    // start laoding
    setLoading(true);

    // variable
    let fd = new FormData();

    fd.append("type", "natural");
    fd.append("_method", "put");

    for (const [key, value] of Object.entries(e)) {
      fd.append(key, value);
    }

    // request
    await updateUserInfo({ fd, register: true }).then((res) => {
      if (res) {
        setLoading(false);
        setUser(res);
        if (!cart) {
          navigate("/app");
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="need-info">
        {cart && (
          <>
            <div className="cart-heading-text">
              برای تکمیل فرآیند خرید ابتدا می بایست پروفایل خود را تکمیل کنید.
            </div>
          </>
        )}

        <Formik
          validationSchema={password ? schema_password : schema}
          onSubmit={set_profile}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* First Name */}
              <Form.Group className="form-group" controlId="validationFormik01">
                <Form.Label className="bold text-muted">نام</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.first_name}
                  isInvalid={touched.first_name && !!errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Last Name */}
              <Form.Group className="form-group" controlId="validationFormik02">
                <Form.Label className="bold text-muted">
                  نام خانوادگی
                </Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.last_name}
                  isInvalid={touched.last_name && !!errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Email */}
              <Form.Group className="form-group" controlId="validationFormik03">
                <Form.Label className="bold text-muted">ایمیل</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.email}
                  disabled={initialValues.email ? true : false}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Mobile */}
              <Form.Group className="form-group" controlId="validationFormik04">
                <Form.Label className="bold text-muted">تلفن همراه</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.mobile}
                  disabled={initialValues.mobile ? true : false}
                  isInvalid={touched.mobile && !!errors.mobile}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobile}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Identify Code */}
              <Form.Group className="form-group" controlId="validationFormik05">
                <Form.Label className="bold text-muted">کد ملی</Form.Label>
                <Form.Control
                  type="number"
                  name="identify_code"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.identify_code}
                  isInvalid={touched.identify_code && !!errors.identify_code}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.identify_code}
                </Form.Control.Feedback>
              </Form.Group>

              {password && (
                <>
                  {/* Password */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik06"
                  >
                    <Form.Label className="bold text-muted">
                      رمز عبور
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      className="form-control-solid number"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Password Confirmation */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik07"
                  >
                    <Form.Label className="bold text-muted">
                      تکرار رمز عبور
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password_confirmation"
                      className="form-control-solid number"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={
                        touched.password_confirmation &&
                        !!errors.password_confirmation
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password_confirmation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <div className="d-flex align-items-center flex-column flex-sm-row gap-3">
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  className="w-100 pill"
                >
                  بروز رسانی پروفایل
                  {loading && (
                    <div className="spinner-holder">
                      <Spinner animation="border" variant="white" size="sm" />
                    </div>
                  )}
                </Button>

                {!cart && (
                  <Button
                    type="button"
                    variant="light"
                    size="lg"
                    className="pill max-content skip-btn"
                    onClick={() => skip()}
                  >
                    رد کردن
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

UserNeedInfo.propTypes = {
  cart: PropTypes.bool,
  setAuthStep: PropTypes.func,
  setUser: PropTypes.func.isRequired,
  cart: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  setUser,
};

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProp, mapDispatchToProps)(UserNeedInfo);
