import React from "react";
import PropTypes from "prop-types";

//
import { Card, Col, Row } from "react-bootstrap";

//
import InfiniteScroll from "react-infinite-scroll-component";

//
import Product from "./Product";
import NothingToShow from "../globals/NothingToShow";
import SkeletopBox from "../globals/SkeletopBox";

function Products({ products, currentPage, lastPage, getMoreProduct }) {
  const fetchMoreData = () => {
    console.log(currentPage, lastPage);
    if (currentPage < lastPage) {
      getMoreProduct(+currentPage + 1);
    } else {
      return;
    }
  };

  return (
    <>
      {products.length ? (
        <InfiniteScroll
          dataLength={products.length}
          next={fetchMoreData}
          hasMore={currentPage < lastPage}
          loader={
            <Col sm={12} className="gutter-b products">
              <p className="mb-0 text-center">در حال دریافت...</p>
            </Col>
          }
          className="row pt-2"
        >
          {products.map((item) => (
            <Col sm={4} className="gutter-b products" key={item.id}>
              <Product item={item} showCustomer responsive />
            </Col>
          ))}
        </InfiniteScroll>
      ) : (
        <Row>
          <Col xs={12} className="gutter-b">
            <Card>
              <Card.Body>
                <NothingToShow text="محصولی برای نمایش وجود ندارد" />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

Products.propTypes = {
  Products: PropTypes.array.isRequired,
};

export default Products;
