import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { changeAvatar, getCurrentUser } from "../../redux/features/user/actions";

//
import { Button, Spinner } from "react-bootstrap";

// React Dropzone
import { useDropzone } from "react-dropzone";

//
import AddImageSvg from "../../assets/img/addphoto.svg";

function ChangeAvatar({getCurrentUser}) {
  const [loading, setLoading] = useState(false);

  /**
   * Update User Info
   */
  const update_avatar = async () => {
    // start loading
    setLoading(true);
    if (files.length) {
      // variable
      let fd = new FormData();
      fd.append("avatar", files[0]);

      // request
      await changeAvatar(fd).then((res) => {
        if (res) {
          setFiles([]);
          getCurrentUser()
        }
      });

      // stop loading
      setLoading(false);
    }
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  /**
   * Remove Image
   */
  const removeImage = (image) => {
    let filesArray = [...files];

    filesArray = filesArray.filter((item) => item != image);

    setFiles(filesArray);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <div className="update-user-info">
      <section className="dropzone">
        <div>
          {files.length ? (
            <div className="thump-box">
              {files.map((file, index) => (
                <div className="thump" key={file.name}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="item">
                            <img src={file.preview} alt="" />
                          </div>
                        </td>
                        <td className="text-end">
                          <span className="size">{formatBytes(file.size)}</span>
                        </td>
                        <td>
                          <div className="icons">
                            <i
                              className="fas fa-times-circle text-danger"
                              onClick={() => removeImage(file)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : (
            <div {...getRootProps({ className: "input-text" })}>
              <input {...getInputProps()} />
              <img src={AddImageSvg} alt="add-image" />
              <p className="title">تصویر پروفایل</p>
              <Button variant="dark">اضافه کردن</Button>
            </div>
          )}
        </div>
      </section>

      <Button
        onClick={() => update_avatar()}
        type="submit"
        variant="primary"
        className="text-white submit-button d-block me-auto"
      >
        ذخیره
        {loading && (
          <div className="spinner-holder">
            <Spinner animation="border" variant="white" size="sm" />
          </div>
        )}
      </Button>
    </div>
  );
}

ChangeAvatar.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(null, mapDispatchToProps)(ChangeAvatar);
