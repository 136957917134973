import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

//
import SkeletopBox from "../../globals/SkeletopBox";
import Pagination from "../../globals/Pagination";

//
import { getProductComments } from "../../../redux/features/main/actions";
import NewCommentQuestionModal from "./NewCommentQuestionModal";

function BuyersComments({ setCommentsCount }) {
  let { slug } = useParams();

  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(false);

  /**
   * Get Comments
   */
  const get_product_comments = async (page) => {
    // request
    await getProductComments({ slug, page }).then((res) => {
      if (res) {
        setComments(res);
        setCommentsCount(res.meta.total_items);
      }
    });
  };

  useEffect(async () => {
    // start loading
    setLoading(true);

    await get_product_comments(1);

    // stop loading
    setLoading(false);
  }, [slug]);

  return (
    <div className="buyers-comment">
      <p className="title">نظر خریداران</p>

      <NewCommentQuestionModal id={slug} type="comments" />

      {loading ? (
        <>
          <p className="mb-1">
            <SkeletopBox height={25} width={150} />
          </p>
          <p>
            <SkeletopBox height={25} width={250} />
          </p>
        </>
      ) : (
        <>
          {comments.result &&
            comments.result.map((item) => (
              <div className="comment" key={item.id}>
                {!Array.isArray(item.customer) && (
                  <small className="name text-muted">
                    {item.customer.first_name} {item.customer.last_name}
                  </small>
                )}
                <p>{item.body}</p>
              </div>
            ))}

          {comments.meta && comments.meta.max_page > 1 && (
            <Pagination
              currentPage={+comments.meta.current_page - 1}
              lastPage={comments.meta.max_page}
              changePage={(page) => get_product_comments(page.selected + 1)}
            />
          )}
        </>
      )}
    </div>
  );
}

BuyersComments.propTypes = {
  setCommentsCount: PropTypes.func.isRequired,
};

export default BuyersComments;
