import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../hooks/useSeo";

//
import { getConfigs, getCurrencies } from "../redux/features/main/actions";
import { getCart } from "../redux/features/cart/actions";
import { getCurrentUser } from "../redux/features/user/actions";

//
import ShairSection from "./components/ShairSection";
import Valuation from "./components/Valuation";
import Footer from "./components/Footer";
import CartHeader from "./components/CartHeader";
import CartBox from "../components/cart/CartBox";
import WhyTrust from "../components/cart/WhyTrust";
import MainLoading from "../components/globals/MainLoading";
import MetaDecorator from "../components/globals/MetaDecorator";

//
import EmptyCart from "../assets/img/emptycart.png";
function Cart({ cart, configs, getConfigs, getCart, getCurrentUser, getCurrencies , currencies }) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);

  /**
   * Set User if localStorage have user item
   */
  const get_current_user = async () => {
    let token = localStorage.getItem("token");

    if (token) {
      await getCurrentUser();
    }
  };

  useEffect(async () => {
    set_seo({}, "سبد خرید");
    setLoading(true);
    if (!currencies.length) {
      await getCurrencies();
    }
    await get_current_user();
    if (!Object.keys(configs).length) {
      await getConfigs();
    }
    await getCart();
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  return (
    <div id="cart-page">
      <MetaDecorator seo={seo} />

      {loading && <MainLoading />}

      <CartHeader />

      {cart.length ? (
        <>
          <CartBox />
          <WhyTrust />
        </>
      ) : (
        <div className="empty-cart">
          <small className="mb-4">سبد خرید شما خالی است</small>
          <img src={EmptyCart} alt="سبد خرید" />
        </div>
      )}

      <ShairSection />
      <Valuation />
      <Footer />
    </div>
  );
}

Cart.propTypes = {
  cart: PropTypes.array.isRequired,
  getConfigs: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  getCurrencies: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getConfigs,
  getCart,
  getCurrentUser,
  getCurrencies,
};

const mapStateToProps = (state) => ({
  cart: state.cartReducer.cart,
  configs: state.mainReducer.configs,
  currencies: state.mainReducer.currencies,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
