import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//
import Select from "react-select";
import { Button, Card, Form, Row, Col, Spinner } from "react-bootstrap";

//
import NeshanMap from "../../components/globals/NeshanMap";

//
import {
  getCountries,
  getProvinces,
  getCities,
} from "../../redux/features/main/actions";
import {
  addNewAddress,
  updateAddress,
} from "../../redux/features/user/actions";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema = yup.object().shape({
  country: yup.object().required(),
  province: yup.object().required(),
  city: yup.object().required(),
  title: yup.string().required(),
  receiver_name: yup.string().required(),
  address: yup.string().required(),
  postal_code: yup.string().required(),
  phone: yup
    .string()
    .required()
    .trim()
    .matches(/^0\d{2,3}\d{8}$/, "Phone number is not valid"),
});

const mapKey = process.env.REACT_APP_NESHAN_MAP_KEY

function AddEditeAddress({ address, refreshAddress, closeAddress }) {
  const [latlng, setLatlng] = useState([35.699739, 51.338097]);
  const [center, setCenter] = useState([35.699739, 51.338097]);

  //
  const [loading, setLoading] = useState(false);
  const [contries, setContries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  //
  const [initialValues, setInitialValues] = useState({
    country: {},
    province: {},
    city: {},
    title: "",
    receiver_name: "",
    address: "",
    postal_code: "",
    phone: "",
    address:''
  });

  const setValue = (e) => {
    let values = { ...initialValues };
    let name = e.target.name;
    let value = e.target.value;

    values[name] = value;

    setInitialValues(values);
  };

  const changeAnswerValue = (name, value) => {
    let values = { ...initialValues };

    values[name] = value;

    setInitialValues(values);
  };

  const set_initial_values = () => {
    if (address.title) {
      // set lat lng for default map
      let lat = +address.lat;
      let lng = +address.lng;
      setLatlng([lat, lng]);
      setCenter([lat, lng]);

      // set intial values
      let initial = { ...initialValues };

      initial = {
        country: {
          value: address.city.province.country.id,
          label: address.city.province.country.title,
        },
        province: {
          value: address.city.province.id,
          label: address.city.province.name,
        },
        city: { value: address.city.id, label: address.city.name },
        title: address.title,
        receiver_name: address.receiver_name,
        address: address.address,
        postal_code: address.postal_code,
        phone: address.phone,
      };


      setInitialValues(initial);
    }
  };

  useEffect(() => {
    set_initial_values();

    // eslint-disable-next-line
  }, [address]);

  /**
   * Get Contries
   */
  const get_contries = () => {
    getCountries().then((res) => {
      if (res) {
        setContries(res);
      }
    });
  };

  useEffect(() => {
    get_contries();

    // eslint-disable-next-line
  }, []);

  /**
   * Get Province
   */
  const get_province = () => {
    let country = initialValues.country.value;

    if (country) {
      getProvinces(country).then((res) => {
        if (res) {
          setProvinces(res);
        }
      });
    }
  };

  useEffect(() => {
    get_province();

    // eslint-disable-next-line
  }, [initialValues.country]);

  /**
   * Get Cities
   */
  const get_cities = () => {
    let province = initialValues.province.value;

    if (province) {
      getCities(province).then((res) => {
        if (res) {
          setCities(res);
        }
      });
    }
  };

  useEffect(() => {
    get_cities();

    // eslint-disable-next-line
  }, [initialValues.province]);

  /**
   * Update Lat Long
   */
  const updateLatLng = (e) => {
    let lat_lng = [...latlng];
    lat_lng = [e.lat, e.lng];

    setLatlng(lat_lng);
  };

  /**
   * Add or Edit Address
   */
  const add_edit_address = async (e) => {
    // start loading
    setLoading(true);

    // variable
    let id = address.id ? address.id : 0;
    let content = {
      country_id: e.country.value,
      province_id: e.province.value,
      city_id: e.city.value,
      title: e.title,
      receiver_name: e.receiver_name,
      address: e.address,
      postal_code: e.postal_code,
      phone: e.phone,
      lat: latlng[0],
      lng: latlng[1],
    };

    // request
    if (id) {
      await updateAddress({ id, content }).then((res) => {
        if (res) {
          refreshAddress();
        }
      });
    } else {
      await addNewAddress(content).then((res) => {
        if (res) {
          refreshAddress();
        }
      });
    }

    // stop loading
    setLoading(false);
  };

  return (
    <Card className="dark-border edit-address">
      <Card.Body>
        <NeshanMap
          key={center}
          options={{
            key: mapKey,
            center: center,
            zoom: 13,
          }}
          onInit={(L, myMap) => {
            let marker = L.marker(latlng).addTo(myMap);
            myMap.on("click", function (e) {
              updateLatLng(e.latlng);
              marker.setLatLng(e.latlng);
            });
          }}
        />

        <Formik
          validationSchema={schema}
          onSubmit={add_edit_address}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => (
            <Form noValidate onSubmit={handleSubmit} className="pt-3">
              <Row>
                <Col md={6}>
                  {/* Title */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik01"
                  >
                    <Form.Label className="bold">
                      عنوان آدرس
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      className="form-control-solid big"
                      onChange={(e) => {
                        handleChange(e);
                        setValue(e);
                      }}
                      autoComplete="off"
                      value={values.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                  </Form.Group>
                </Col>

                {/* Postal Code */}
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik02"
                  >
                    <Form.Label className="bold">
                      کد پستی
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="postal_code"
                      className="form-control-solid big number"
                      onChange={(e) => {
                        handleChange(e);
                        setValue(e);
                      }}
                      autoComplete="off"
                      value={values.postal_code}
                      isInvalid={touched.postal_code && !!errors.postal_code}
                    />
                  </Form.Group>
                </Col>

                {/* Phone */}
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik03"
                  >
                    <Form.Label className="bold">
                      تلفن ثابت
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      className="form-control-solid big number"
                      onChange={(e) => {
                        handleChange(e);
                        setValue(e);
                      }}
                      autoComplete="off"
                      value={values.phone}
                      isInvalid={touched.phone && !!errors.phone}
                    />
                  </Form.Group>
                </Col>

                {/* Reciever Name */}
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik03"
                  >
                    <Form.Label className="bold">
                      تحویل گیرنده
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="receiver_name"
                      className="form-control-solid big"
                      onChange={(e) => {
                        handleChange(e);
                        setValue(e);
                      }}
                      autoComplete="off"
                      value={values.receiver_name}
                      isInvalid={
                        touched.receiver_name && !!errors.receiver_name
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  {/* Country */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik04"
                  >
                    <Form.Label className="bold">
                      کشور
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Select
                      className="react-select"
                      isRtl={true}
                      isSearchable={true}
                      name="country"
                      options={contries}
                      placeholder=""
                      value={values.country}
                      noOptionsMessage={() => "کشور یافت نشد"}
                      onChange={(e) => changeAnswerValue("country", e)}
                    />
                  </Form.Group>
                </Col>

                {/* Province */}
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik04"
                  >
                    <Form.Label className="bold">
                      استان
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Select
                      className="react-select"
                      isRtl={true}
                      isSearchable={true}
                      name="province"
                      options={provinces}
                      placeholder=""
                      noOptionsMessage={() => "استان یافت نشد"}
                      value={values.province}
                      onChange={(e) => changeAnswerValue("province", e)}
                    />
                  </Form.Group>
                </Col>

                {/* City */}
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik04"
                  >
                    <Form.Label className="bold">
                      شهر
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Select
                      className="react-select"
                      isRtl={true}
                      isSearchable={true}
                      name="city"
                      options={cities}
                      placeholder=""
                      value={values.city}
                      noOptionsMessage={() => "شهر یافت نشد"}
                      onChange={(e) => changeAnswerValue("city", e)}
                    />
                  </Form.Group>
                </Col>

                {/* Address */}
                <Col md={12}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik05"
                  >
                    <Form.Label className="bold">
                      آدرس
                      <span className="text-danger me-1">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="6"
                      type="text"
                      name="address"
                      onChange={(e) => {
                        handleChange(e);
                        setValue(e);
                      }}
                      autoComplete="off"
                      className="form-control-solid big no-resize"
                      value={values.address}
                      isInvalid={touched.address && !!errors.address}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="mb-3 d-flex align-items-center justify-content-end">
                <Button
                  type="submit"
                  variant="outline-secondary"
                  className="submit-button ms-2"
                  onClick={closeAddress}
                >
                  انصراف
                </Button>

                <Button
                  type="submit"
                  variant="primary"
                  className="text-white submit-button"
                >
                  ذخیره
                  {loading && (
                    <div className="spinner-holder">
                      <Spinner animation="border" variant="white" size="sm" />
                    </div>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

AddEditeAddress.propTypes = {
  address: PropTypes.object,
  refreshAddress: PropTypes.func.isRequired,
  closeAddress: PropTypes.func.isRequired,
};

export default AddEditeAddress;
