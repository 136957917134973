import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Button } from "react-bootstrap";

//
import BeforeText from "../../components/globals/BeforeText";
import PasswordLogin from "../../components/auth/login/PasswordLogin";
import OtpLogin from "../../components/auth/login/OtpLogin";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Login({
  configs,
  loginModal = false,
  cart = false,
  closeModalAfterLogin = false,
  setAuthStep,
}) {
  let navigate = useNavigate();
  const [seo, set_seo] = useSeo();

  const [type, setType] = useState("password");

  useEffect(() => {
    if (!cart && configs.BRAND_NAME) {
      set_seo({}, `ورود به ${configs.BRAND_NAME}`);
    }

    // eslint-disable-next-line
  }, [configs]);

  return (
    <>
      {!cart && <MetaDecorator seo={seo} />}
      <div className="login">
        {cart && (
          <div className="cart-heading-text">
            برای تکمیل فرآیند خرید، وارد شوید.
          </div>
        )}
        {!cart && !loginModal && <BeforeText text={`ورود به ${configs.BRAND_NAME}`} />}

        {type === "password" && (
          <PasswordLogin
            cart={cart}
            loginModal={loginModal}
            setAuthStep={setAuthStep}
            closeModalAfterLogin={closeModalAfterLogin}
          />
        )}

        {type === "otp" && (
          <OtpLogin
            cart={cart}
            loginModal={loginModal}
            setAuthStep={setAuthStep}
            closeModalAfterLogin={closeModalAfterLogin}
          />
        )}

        <p
          className="pointer mt-3 text-danger d-inline-block"
          onClick={() => setType(type === "password" ? "otp" : "password")}
        >
          ورود با {type === "password" ? "کد تایید" : "رمز عبور"}
        </p>

        {cart && (
          <p
            className="text-center my-4 pointer"
            onClick={() => setAuthStep("forget")}
          >
            فراموشی رمز عبور ؟
          </p>
        )}

        {!loginModal && (
          <p className="text-center my-4">
            حساب کاربری ندارید؟
            <Button
              variant="outline-secondary"
              size="sm"
              className="me-2"
              onClick={() =>
                cart ? setAuthStep("register") : navigate("/auth/register")
              }
            >
              ثبت نام
            </Button>
          </p>
        )}
      </div>
    </>
  );
}

Login.propTypes = {
  cart: PropTypes.bool,
  setAuthStep: PropTypes.func,
  loginModal: PropTypes.bool,
  closeModalAfterLogin: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(Login);
