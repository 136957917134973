import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import useCurrency from "../../hooks/useCurrency";

//
import NumberFormat from "react-number-format";

//
import {
  changeItemQuantity,
  deleteCartItem,
} from "../../redux/features/cart/actions";
import { updateCartItemQuantity } from "../../redux/features/cart/slice";

function CartItems({
  item,
  updateCartItemQuantity,
  deleteCartItem,
  change = false,
}) {
  const [currencyTitle] = useCurrency();

  const [count, setCount] = useState(1);

  const changeCount = (type) => {
    if (type === "plus" && count < item.inventory) {
      changeQuantity(count + 1);
      setCount(count + 1);
    }
    if (type === "minus" && count != 1) {
      changeQuantity(count - 1);
      setCount(count - 1);
    }
  };

  const changeQuantity = (count) => {
    let content = {};
    content[`${item.id}`] = count;

    changeItemQuantity(content).then((res) => {
      if (res) {
        let id = item.id;
        let quantity = count;
        updateCartItemQuantity({ id, quantity });
      } else {
        setCount(Math.floor(item.quantity));
      }
    });
  };

  useEffect(() => {
    setCount(Math.floor(item.quantity));

    // eslint-disable-next-line
  }, []);

  return (
    <tr>
      <td>
        {change && (
          <i
            className="fas fa-times boxed-icon"
            onClick={() => deleteCartItem(item.id)}
          ></i>
        )}
      </td>

      <td className="text-end">
        <div className="d-flex align-items-center">
          <Link
            to={`/app/product/${item.product_id}`}
            className="cart-item-image"
          >
            <img src={item.image} alt={item.title} />
          </Link>
          <Link
            to={`/app/product/${item.product_id}`}
            className="cart-item-title"
          >
            {item.title}
            {item.product_option && (
              <small>
                (
                {Object.entries(
                  typeof item.product_option.options_summary === "string"
                    ? JSON.parse(item.product_option.options_summary)
                    : item.product_option.options_summary
                ).map(([key, value], index) => (
                  <span key={index}>
                    {key}: {value}
                  </span>
                ))}
                )
              </small>
            )}
          </Link>
        </div>
      </td>

      <td>
        {change ? (
          <div className="cart-item-count-box">
            <div className="counter">
              <div className="icon-hoder">
                <i
                  className="fas fa-plus boxed-icon small"
                  onClick={() => changeCount("plus")}
                ></i>
              </div>
              <span>{count}</span>
              <div className="icon-holder">
                <i
                  className="fas fa-minus boxed-icon small"
                  onClick={() => changeCount("minus")}
                ></i>
              </div>
            </div>
            {item.product.packaging && (
              <p className="mx-1">
                <span>{item.product.packaging.title}</span>
                <span className="mx-1">{+item.product.packaging.stepper}</span>
                <span>{item.product.packaging.measurement_title}</span>
              </p>
            )}
          </div>
        ) : (
          <p>
            {count} عدد
            {item.product.packaging && (
              <p className="mx-1">
                <span>{item.product.packaging.title}</span>
                <span className="mx-1">{+item.product.packaging.stepper}</span>
                <span>{item.product.packaging.measurement_title}</span>
              </p>
            )}
          </p>
        )}
      </td>

      <td>
        <div className="d-flex align-items-center justify-content-end gap-1">
          <small className="text-muted">{currencyTitle()}</small>
          <p>
            <NumberFormat
              value={+item.quantity * +item.unit_price}
              displayType="text"
              thousandSeparator={true}
              className="cart-item-price"
            />
          </p>
        </div>
      </td>
    </tr>
  );
}

CartItems.propTypes = {
  updateCartItemQuantity: PropTypes.func.isRequired,
  deleteCartItem: PropTypes.func.isRequired,
  change: PropTypes.bool,
};

const mapDispatchToProps = {
  updateCartItemQuantity,
  deleteCartItem,
};

export default connect(null, mapDispatchToProps)(CartItems);
