import { createSlice } from "@reduxjs/toolkit";
import { getCart, deleteCartItem } from "./actions";

const initialState = {
    cart: [],
    splitedOrderItems: {},
    selectedAddressCart: {},
    coupon: '',
    cartOrder: {}
};

export const mainSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addNewCartItem: (state, action) => {
            state.cart.push(action.payload);
        },

        updateCartItemQuantity: (state, action) => {
            let { id, quantity } = action.payload;

            for (let i = 0; i < state.cart.length; i++) {
                const element = state.cart[i];
                if (element.id === id) {
                    element.quantity = quantity;
                    element.final_price = +quantity * +element.unit_price;
                }
            }
        },

        setSplitedOrderItems: (state, action) => {
            state.splitedOrderItems = action.payload;
        },

        setSelectedAddressCart: (state, action) => {
            state.selectedAddressCart = action.payload;
        },

        setCartCoupon: (state, action) => {
            state.coupon = action.payload;
        },

        setCartOrder: (state, action) => {
            state.cartOrder = action.payload
        }
    },

    extraReducers: {
        [getCart.fulfilled]: (state, action) => {
            let cart = action.payload;

            if (cart) {
                state.cart = cart;
            }
        },

        [deleteCartItem.fulfilled]: (state, action) => {
            let id = action.payload;

            if (id) {
                state.cart = state.cart.filter((item) => item.id != id);
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addNewCartItem,
    updateCartItemQuantity,
    setSplitedOrderItems,
    setSelectedAddressCart,
    setCartCoupon,
    setCartOrder
} = mainSlice.actions;

export default mainSlice.reducer;