import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Col, Container, Row } from "react-bootstrap";

//
import SkeletopBox from "../globals/SkeletopBox";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function BanneredProductLoading({ windowSize}) {
    let items = [1,2,3,4,5,6,7,8]

  return (
    <div className='bannered-product bg-gray'>
      <div className="desktop-box d-none d-sm-block">
        <Container>
          <Row>
            <Col xl={2} className="desktop-banner d-none d-md-block">
              <SkeletopBox height={windowSize > 1200 ? 391 : 100}/>
            </Col>

            <Col xl={10}>
              <Row>
                <Col sm={4} md={3} className="mobile-banner d-block d-md-none">
                <SkeletopBox height={180}/>
                </Col>

                {items.map((item) => (
                  <Col sm={4} md={3} className="item-box" key={item}>
                    <SkeletopBox height={180}/>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Slider For Down 768px */}
      <div className="mobile-box d-block d-sm-none">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={10}
          breakpoints={{
            400: {
              slidesPerView: 1.8,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          <SwiperSlide>
            <div className="mobile-banner mb-0">
              <SkeletopBox height={180}/>
            </div>
          </SwiperSlide>
          {items.map((item) => (
            <SwiperSlide key={item}>
              <SkeletopBox height={180}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

BanneredProductLoading.propTypes = {
  windowSize: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(BanneredProductLoading);
