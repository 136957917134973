import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    windowSize: 0,
    topNavSwiperState: false,
    mobileHeaderState: false,
    blogTopicTitle: ''
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setWindowSize: (state, action) => {
            state.windowSize = action.payload;
        },
        changeTopNavSwiperState(state, action) {
            state.topNavSwiperState = action.payload;
        },
        changeMobileHeaderState(state, action) {
            state.mobileHeaderState = action.payload;
        },
        setBlogTopicTitle(state, action) {
            state.blogTopicTitle = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setWindowSize,
    changeTopNavSwiperState,
    changeMobileHeaderState,
    setBlogTopicTitle
} = themeSlice.actions;

export default themeSlice.reducer;