import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Button, Form, Spinner } from "react-bootstrap";

//
import { changePassword } from "../../redux/features/user/actions";
import { getCurrentUser } from "../..//redux/features/user/actions";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
  password_confirmation: yup
    .string()
    .required("لطفا تکرار رمز عبور خود را وارد کنید")
    .oneOf([yup.ref("password"), null], "رمز عبور ها باید یکسان باشند"),
});

function UserSetPassword({ getCurrentUser }) {
  const [loading, setLoading] = useState(false);

  /**
   * Change Password
   */
  const change_password = async (e) => {
    // start laoding
    setLoading(true);

    // request
    await changePassword(e).then(async (res) => {
      if (res) {
        await getCurrentUser();
      }
    });

    // stop loading
    setLoading(false);
  };

  return (
    <>
      <div className="need-info">
        <div className="cart-heading-text">
          برای تکمیل فرآیند خرید ابتدا می بایست رمز عبور خود را ایجاد کنید.
        </div>

        <Formik
          validationSchema={schema}
          onSubmit={change_password}
          initialValues={{
            password: "",
            password_confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Password */}
              <Form.Group className="form-group" controlId="validationFormik06">
                <Form.Label className="bold text-muted">رمز عبور</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Password Confirmation */}
              <Form.Group className="form-group" controlId="validationFormik07">
                <Form.Label className="bold text-muted">
                  تکرار رمز عبور
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password_confirmation"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={
                    touched.password_confirmation &&
                    !!errors.password_confirmation
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                variant="danger"
                size="lg"
                className="w-100 pill"
              >
                ایجاد رمز عبور{" "}
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

UserSetPassword.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(null, mapDispatchToProps)(UserSetPassword);
