import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { Container, Row, Col, Card } from "react-bootstrap";

//
import {
  getProduct,
  getProductPrices,
  getRelatedProducts,
} from "../../redux/features/main/actions";
import { changeMobileHeaderState } from "../../redux/features/theme/slice";
import { addFavorite, deleteFavorite } from "../../redux/features/user/actions";

//
import Breadcrumb from "../../components/globals/Breadcrumb";
import TitleSwiper from "../../components/globals/TitleSwiper";
import SkeletopBox from "../../components/globals/SkeletopBox";
//
import ShareProductModal from "../../components/category/ShareProductModal";
//
import WhyUs from "../../components/product-detail/WhyUs";
import MainInfo from "../../components/product-detail/MainInfo";
import MobileHeader from "../../components/product-detail/MobileHeader";
import StickySidebarHolder from "../../components/product-detail/StickySidebarHolder";
import ProductSellers from "../../components/product-detail/ProductSellers";
import MetaDecorator from "../../components/globals/MetaDecorator";

function ProductDetail({ changeMobileHeaderState, windowSize }) {
  let { slug } = useParams();
  const [seo, set_seo] = useSeo();

  const [breadcrump, setBreadcrump] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const [shareModal, setShareModal] = useState(false);

  // product
  const [product, setProduct] = useState({});
  const [productLoading, setProductLoading] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const [options, setOptions] = useState([]);
  const [productSellers, setProductSellers] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState({});

  /**
   * Set show mobile header
   * Disable Mobile Header
   * Display none header and navbar
   */
  useEffect(() => {
    changeMobileHeaderState(true);
    return () => {
      changeMobileHeaderState(false);
    };

    // eslint-disable-next-line
  }, []);

  /**
   * Set Breacrump
   */
  const create_breadcrump = (product) => {
    let category = product.category;
    let parent = category.parent;
    let array = [
      {
        id: category.id,
        type: "category",
        slug: category.slug,
        title: category.title,
      },
      {
        id: product.id,
        type: "product",
        slug: product.slug,
        title: product.title,
      },
    ];
    if (parent) {
      array.unshift({
        id: parent.id,
        type: "category-info",
        slug: parent.slug,
        title: parent.title,
      });
    }

    setBreadcrump(array);
  };

  /**
   * Update Is Favorite
   */
  const updateIsFavorite = () => {
    let is_favorite = product.is_favorite;

    let id = product.id;

    //
    if (is_favorite) {
      deleteFavorite(id).then((res) => {
        if (res) {
          let current = { ...product };
          current.is_favorite = !is_favorite;
          setProduct(current);
        }
      });
    } else {
      addFavorite(id).then((res) => {
        if (res) {
          let current = { ...product };
          current.is_favorite = !is_favorite;
          setProduct(current);
        }
      });
    }
  };

  /**
   * Get Related Products
   */
  const get_related_products = async (id) => {
    getRelatedProducts(id).then((res) => {
      if (res) {
        setRelatedProducts(res);
      }
    });
  };

  /**
   * Get Product
   */
  const get_product = async () => {
    setRelatedProducts([]);

    // start loading
    setProductLoading(true);

    // request
    await getProduct(slug).then(async (res) => {
      if (res) {
        setProduct(res);
        set_seo(res.seo, res.title);
        create_breadcrump(res);
        get_related_products(res.id);
        await set_product_options(res);
      }
    });

    // stop loading
    setProductLoading(false);
  };

  /**
   * Get Product Price
   */
  const set_product_options = async (product) => {
    if (product && product.options.length) {
      let selectedOptions = product.selected_option_value_ids;
      let newOptions = [];
      // create new array with selected parameter
      for (let i = 0; i < product.options.length; i++) {
        const element = product.options[i];
        let current = { ...element };
        let values = [];

        for (let i = 0; i < current.values.length; i++) {
          const attr = current.values[i];

          values.push({
            ...attr,
            selected: false,
          });
        }
        current.values = values;
        newOptions.push(current);
      }

      // set selected value of options as selected
      for (let i = 0; i < newOptions.length; i++) {
        const option = newOptions[i];
        if (selectedOptions) {
          for (let i = 0; i < option.values.length; i++) {
            const value = option.values[i];
            for (let i = 0; i < selectedOptions.length; i++) {
              const selected = selectedOptions[i];
              if (selected == value.id) {
                value.selected = true;
              }
            }
          }
        } else {
          option.values[0].selected = true;
        }
      }

      setOptions(newOptions);
      get_product_prices(newOptions);
    } else {
      get_product_prices([]);
    }
  };

  const updateOptions = (newOptions) => {
    setOptions(newOptions);

    get_product_prices(newOptions);
  };

  const get_product_prices = async (options) => {
    let option = "";
    let optionArray = [];

    if (options.length) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        for (let i = 0; i < option.values.length; i++) {
          const element = option.values[i];
          if (element.selected) {
            optionArray.push(element.id);
          }
        }
      }

      option = optionArray.toString();
    }

    await getProductPrices({ slug, option }).then((res) => {
      setProductSellers(res);
      if (res.length) {
        setSelectedSeller(res[0]);
      } else {
        setSelectedSeller({});
      }
    });
  };

  useEffect(() => {
    get_product();

    // eslint-disable-next-line
  }, [slug]);

  return (
    <div id="product-detail">
      <MetaDecorator seo={seo} />
      {showFilter && (
        <div className="click-box light" onClick={() => setShowFilter(false)} />
      )}

      {windowSize <= 768 && (
        <MobileHeader
          setShow={setShareModal}
          product={product}
          updateIsFavorite={updateIsFavorite}
        />
      )}
      <ShareProductModal
        slug={product.slug || ""}
        show={shareModal}
        setShow={setShareModal}
      />

      <Container>
        <div className="header-box d-none d-md-block">
          {productLoading ? (
            <div className="mb-4">
              <SkeletopBox height={20} />
            </div>
          ) : (
            <Breadcrumb array={breadcrump} />
          )}
        </div>

        {productLoading ? (
          <div className="mb-4">
            <SkeletopBox height={300} />
          </div>
        ) : (
          <MainInfo
            setShow={setShareModal}
            product={product}
            selectedSeller={selectedSeller}
            options={options}
            updateOptions={updateOptions}
            updateIsFavorite={updateIsFavorite}
          />
        )}

        {product.show_sellers && !!productSellers.length && (
          <Row className="gutter-b">
            <Col xl={12}>
              <Card className="sellers">
                <Card.Body>
                  <table className="item">
                    <tbody>
                      {productSellers.map((item) => (
                        <ProductSellers
                          key={item.id}
                          seller={item}
                          selectedSeller={selectedSeller}
                          setSelectedSeller={setSelectedSeller}
                        />
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <WhyUs />

        {!!relatedProducts.length && (
          <TitleSwiper text="محصولات مشابه" simple items={relatedProducts} />
        )}

        <StickySidebarHolder
          product={product}
          loading={productLoading}
          selectedSeller={selectedSeller}
        />
      </Container>
    </div>
  );
}

ProductDetail.propTypes = {
  changeMobileHeaderState: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMobileHeaderState,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
