import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Container, Card } from "react-bootstrap";

//
import { getStaticPage } from "../../redux/features/main/actions";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

function StaticPage(props) {
  let { slug } = useParams();
  let navigate = useNavigate();
  const [seo, set_seo] = useSeo();

  const [page, setPage] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * Get Static Page
   */
  const get_static_page = async () => {
    // start loading
    setLoading(true);

    // request
    await getStaticPage(slug).then((res) => {
      if (res) {
        setPage(res);
        set_seo(res.seo, res.title);
      } else {
        navigate("/not-found");
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_static_page();
  }, [slug]);

  return (
    <div id="static-page">
      <MetaDecorator seo={seo} />
      <Container>
        <div className="main-title">{page.title}</div>

        <Card className="dark-border no-radius">
          <Card.Body
            dangerouslySetInnerHTML={{ __html: page.body }}
          ></Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default StaticPage;
