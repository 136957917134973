import React, { useEffect, useState } from "react";

//
import { Form } from "react-bootstrap";

//
import {
  getNotiticationsConfig,
  updateNotiticationsConfig,
} from "../../redux/features/notification/actions";
import BoxLoading from "../globals/BoxLoading";
import NothingToShow from "../globals/NothingToShow";

function NotificationConfig() {
  const [loading, setLoading] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState({});

  const changeConfig = (eventName, driverName) => {
    let config = { ...notifications };

    config[eventName][driverName] = !config[eventName][driverName];

    setNotifications(config);

    updateConfig();
  };

  const updateConfig = async () => {
    let config = {};
    for (let d = 0; d < drivers.length; d++) {
      const driver = drivers[d];
      config[driver.name.toLowerCase()] = [];

      for (const [key, value] of Object.entries(notifications)) {
        if (value[driver.name.toLowerCase()]) {
          config[driver.name.toLowerCase()].push(key);
        }
      }

      if (!config[driver.name.toLowerCase()].length) {
        delete config[driver.name.toLowerCase()];
      }
    }

    await updateNotiticationsConfig({ config });
  };

  const get_notifications_config = async () => {
    // start loading
    setLoading(true);

    // request
    await getNotiticationsConfig().then((res) => {
      if (res) {
        let driversList = res.drivers.map((item) => {
          return { ...item, name: item.name.toLowerCase() };
        });
        setDrivers(driversList);
        setEvents(res.events);
        const notificationsObject = res.config;

        let lastNotification = {};

        if (!Array.isArray(notificationsObject)) {
          for (const [key, value] of Object.entries(notificationsObject)) {
            for (let i = 0; i < value.length; i++) {
              const element = value[i];
              if (lastNotification[element]) {
                lastNotification[element][key] = true;
              } else {
                lastNotification[element] = {};
                lastNotification[element][key] = true;
              }
            }
          }
        }

        const defaultNotifications = {};
        for (let e = 0; e < res.events.length; e++) {
          const event = res.events[e];
          defaultNotifications[event.name] = {};

          for (let d = 0; d < driversList.length; d++) {
            const driver = driversList[d];
            defaultNotifications[event.name][driver.name] = false;
          }
        }

        let newNotificatios = {};
        for (const [key, value] of Object.entries(defaultNotifications)) {
          if (lastNotification[key]) {
            newNotificatios[key] = Object.assign(
              defaultNotifications[key],
              lastNotification[key]
            );
          } else {
            newNotificatios[key] = value;
          }
        }

        setNotifications(newNotificatios);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_notifications_config();
  }, []);

  return (
    <div>
      {drivers.length ? (
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th style={{ minWidth: "200px", width: "100%" }}>فعالیت</th>
                {drivers.map((driver) => (
                  <th
                    key={driver.name}
                    style={{
                      minWidth: "120px",
                      width: "120px",
                      textAlign: "center",
                    }}
                  >
                    {driver.name}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {events.map((eventItem) => (
                <tr key={eventItem.name}>
                  <td>{eventItem.description}</td>
                  {drivers.map((driverItem) => (
                    <td key={driverItem.name}>
                      <div className="d-flex align-items-center justify-content-center">
                        {notifications[eventItem.name] && (
                          <Form.Check // prettier-ignore
                            type="switch"
                            checked={
                              notifications[eventItem.name][driverItem.name]
                            }
                            onChange={(e) =>
                              changeConfig(
                                eventItem.name,
                                driverItem?.name?.toLowerCase()
                              )
                            }
                          />
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NothingToShow text="موردی برای نمایش وجود ندارد" />
      )}

      {loading && <BoxLoading />}
    </div>
  );
}

export default NotificationConfig;
