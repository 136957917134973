import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//
import { Card, Col, Row, Button } from "react-bootstrap";

//
import BeforeText from "../globals/BeforeText";

//
import Related from "../../assets/img/related-article.jpg";

function RelatedArticles({ blogs, configs }) {
  let navigate = useNavigate();

  return (
    <div className="related-articles">
      <BeforeText text={`مطالب مرتبط در مجله ${configs.BRAND_NAME}`} />

      <Row className="blogs">
        {blogs.map((item) => (
          <Col md={6} className="blog gutter-b" key={item.id}>
            <Card>
              <Card.Body>
                <Link to={`/blog/post/${item.id}`}>
                  <Row>
                    <Col sm={3}>
                      <img
                        src={item.main_image}
                        alt="related"
                        className=" mb-4 mb-sm-0"
                      />
                    </Col>

                    <Col sm={9}>
                      <p className="title truncate">{item.title}</p>
                      <div>
                        <p
                          className="mb-1"
                          dangerouslySetInnerHTML={{ __html: item.body_short }}
                        ></p>
                        <Link to="/" className="text-info">
                          ادامه مطلب
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}

        <Col sm={8} md={6} className="d-block d-md-none m-auto">
          <Button
            variant="danger"
            className="see-all"
            onClick={() => navigate("/blog")}
          >
            همه پست های مجله {configs.BRAND_NAME} 
          </Button>
        </Col>
      </Row>
    </div>
  );
}

RelatedArticles.propTypes = {
  blogs: PropTypes.array.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(RelatedArticles);
