import React from "react";
import PropTypes from "prop-types";

//
import { Helmet } from "react-helmet";

function MetaDecorator({ seo }) {
  return (
    <Helmet>
      {seo.title && <title>{seo.title}</title>}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.canonical && <link rel="canonical" href={seo.canonical} />}
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}
    </Helmet>
  );
}

MetaDecorator.propTypes = {
  seo: PropTypes.object.isRequired,
};

export default MetaDecorator;
