import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";

//
import { Button, Col, Row } from "react-bootstrap";

//
import UnSuccess from "../assets/img/unSuccessfullPayment.png";
import Success from "../assets/img/successfullPayment.png";

const successText = "پرداخت موفق";
const failedText =
  "پرداخت ناموفق بوده است. در صورت وجود مشکل با پشتیبانی در تماس باشید";

function PaymentCallback(props) {
  let navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState({
    status: 1,
    amount: "",
    tief: "",
    code: "",
  });

  /**
   * Get Quesry From URL
   */
  const getQueryParams = () => {
    let quesris = { ...query };
    quesris.status = searchParams.get("status");
    quesris.tief = searchParams.get("tief");
    quesris.code = searchParams.get("code");
    quesris.amount = searchParams.get("amount");
    setQuery(quesris);
  };

  useEffect(() => {
    getQueryParams();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="payment-callback">
      <h5
        className={`mb-3 text-bold ${
          +query.status ? "text-success" : "text-danger"
        }`}
      >
        {+query.status ? "پرداخت ناموفق" : "پرداخت موفق"}
      </h5>

      {!+query.status && (
        <small>
          در صورتیکه مبلغی از حساب شما کسر شده باشد، در کمتر از 24 ساعت به حساب
          شما بازگردانده خواهد شد.
        </small>
      )}

      <img
        src={+query.status ? Success : UnSuccess}
        alt={+query.status ? "پرداخت ناموفق" : "پرداخت موفق"}
      />

      <Row className="w-100 justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <div className="item">
            <span>کد سفارش : </span>
            <span>{query.code}</span>
          </div>

          <div className="item">
            <span>شماره پیگیری : </span>
            <span>{query.tief}</span>
          </div>

          {+query.status && (
            <div className="item">
              <span>مبلغ پرداختی : </span>
              <span>{query.amount}</span>
            </div>
          )}
        </Col>
      </Row>

      <Button variant="outline-danger" className="pill" onClick={() => navigate('/panel/orders')}>
        مشاهده لیست سفارشات
      </Button>
    </div>
  );
}

PaymentCallback.propTypes = {};

export default PaymentCallback;
