import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import { Col, Container, Row } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function BanneredProduct({ windowSize, category, bgGray = false }) {
  return (
    <div className={[`bannered-product ${bgGray ? "bg-gray" : ""}`]}>
      <div className="desktop-box d-none d-sm-block ">
        <Container>
          <Row>
            <Col xl={2} className="desktop-banner d-none d-md-block">
              <Link to={`category-info/${category.id}`} title={category.title}>
                <img
                  alt={category.title}
                  className="lazyload"
                  src={
                    windowSize > 1200
                      ? category.vertical_cover_image
                      : category.horizontal_cover_image
                  }
                />
                <div className="homeBanerText">
                  <p>{category.title}</p>
                  <p>
                    <span>مشاهده همه محصولات</span>
                    <i className="fas fa-chevron-left"></i>
                  </p>
                </div>
              </Link>
            </Col>

            <Col xl={10}>
              <Row>
                <Col sm={4} md={3} className="mobile-banner d-block d-md-none">
                  <Link to={`category-info/${category.id}`} title={category.title}>
                    <img
                      alt={category.title}
                      className="lazyload"
                      src={category.cover_image}
                    />
                    <div className="homeBanerText">
                      <p>{category.title}</p>
                      <div>
                        <p>
                          <span>مشاهده همه محصولات</span>
                          <i className="fas fa-chevron-left"></i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </Col>

                {category.items.map((item) => (
                  <Col sm={4} md={3} className="item-box" key={item.id}>
                    <Link to={`category/${item.id}`}>
                      <div className="imageBox image-item-size">
                        <img
                          alt={item.title}
                          className="lazyload"
                          src={item.cover_image}
                        />
                      </div>
                      <p>{item.title}</p>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Slider For Down 768px */}
      <div className="mobile-box d-block d-sm-none">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={10}
          breakpoints={{
            400: {
              slidesPerView: 1.8,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          <SwiperSlide>
            <div className="mobile-banner mb-0">
              <Link to={`category-info/${category.id}`} title={category.title}>
                <img
                  alt={category.title}
                  className="lazyload"
                  src={category.cover_image}
                />
                <div className="homeBanerText">
                  <p>{category.title}</p>
                  <div>
                    <p>
                      <span>مشاهده همه محصولات</span>
                      <i className="fas fa-chevron-left"></i>
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          {category.items.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="item-box mb-0">
                <Link to={`category/${item.id}`}>
                  <div className="imageBox image-item-size">
                    <img
                      alt={item.title}
                      className="lazyload"
                      src={item.cover_image}
                    />
                  </div>
                  <p>{item.title}</p>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

BanneredProduct.propTypes = {
  windowSize: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
  bgGray: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(BanneredProduct);
