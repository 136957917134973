import React from "react";
import PropTypes from "prop-types";

//
import StickyBox from "react-sticky-box";
import { Col, Row } from "react-bootstrap";

//
import AllInfos from "./AllInfos";
import SidebarProduct from "./SidebarProduct";
//
import SkeletopBox from "../globals/SkeletopBox";

function StickySidebarHolder({ product, loading, selectedSeller }) {
  return (
    <div className="sticky-sidebar-holder">
      <Row>
        <Col xl={9}>
          {loading ? (
            <SkeletopBox height={500} />
          ) : (
            <AllInfos product={product} />
          )}
        </Col>
        <Col xl={3} className="d-none d-xl-block">
          <StickyBox>
            {loading ? (
              <SkeletopBox height={400} />
            ) : (
              <SidebarProduct
                product={product}
                selectedSeller={selectedSeller}
              />
            )}
          </StickyBox>
        </Col>
      </Row>
    </div>
  );
}

StickySidebarHolder.propTypes = {
  product: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedSeller: PropTypes.object.isRequired,
};

export default StickySidebarHolder;
