import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";
import useSeo from "../../hooks/useSeo";

//
import NumberFormat from "react-number-format";
import { Card, Col, Row } from "react-bootstrap";

//
import Product from "../../components/panel/Product";
import NothingToShow from "../../components/globals/NothingToShow";
import BoxLoading from "../../components/globals/BoxLoading";
import MetaDecorator from "../../components/globals/MetaDecorator";

//
import {
  deleteFavorite,
  getFavorites,
} from "../../redux/features/user/actions";

//
import ImageLoading from "../../assets/img/product/LoadingProduct.gif";

function Favorites({ windowSize }) {
  const [currencyTitle] = useCurrency();
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);

  /**
   * Get Favorites
   */
  const get_favorites = async () => {
    // start loading
    setLoading(true);

    // request
    await getFavorites().then((res) => {
      if (res) {
        setFavorites(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  const removeFavorite = (id) => {
    deleteFavorite(id).then((res) => {
      if (res) {
        let array = [...favorites];

        array = array.filter((item) => item.id !== id);

        setFavorites(array);
      }
    });
  };

  useEffect(() => {
    set_seo({}, "علاقه مندی ها");
    get_favorites();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div id="favorites">
        <div className="page-title mb-3 mt-5 mt-md-3">لیست علاقه مندی ها</div>

        {favorites.length ? (
          <>
            {windowSize > 768 ? (
              <Card className="dark-border">
                <Card.Body className="panel-item-box">
                  <table>
                    <thead>
                      <tr>
                        <th width="15%"></th>
                        <th width="20%"></th>
                        <th width="45%"></th>
                        <th width="20%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {favorites.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img
                              className="img-fluid"
                              src={item.main_image}
                              alt={item.title}
                              onerror={ImageLoading}
                            />
                          </td>
                          <td>
                            <p className="code">{item.code}</p>
                            <Link to={`/app/product/${item.id}`}>
                              {item.title}
                            </Link>
                          </td>

                          <td>
                            <p className="order-price d-flex align-items-center justify-content-center gap-1">
                              <span className="currency-toman">{currencyTitle()}</span>
                              <h6 className="price-Separate">
                                <NumberFormat
                                  value={+item.original_price}
                                  displayType="text"
                                  thousandSeparator={true}
                                  className="ms-1 text-bold text-black"
                                />
                              </h6>
                            </p>
                          </td>

                          <td className="text-start">
                            <p
                              className="text-danger pointer"
                              onClick={() => removeFavorite(item.id)}
                            >
                              حذف
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            ) : (
              <Row>
                {favorites.map((item) => (
                  <Col sm={4} key={item.id} className="gutter-b">
                    <Product
                      item={item}
                      favorite
                      price={item.original_price}
                      removeFavorite={removeFavorite}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </>
        ) : (
          <Card className="dark-border">
            <Card.Body>
              <NothingToShow text="مورد برای نمایش وجود ندارد" />

              {loading && <BoxLoading />}
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}

Favorites.propTypes = {
  windowSize: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(Favorites);
