import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

//
import BeforeText from "../globals/BeforeText";

//
import { Accordion, Card } from "react-bootstrap";
import { getFAQ } from "../../redux/features/main/actions";

function RelatedQuestions(props) {
  let { slug } = useParams();

  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * Get FAQs
   */
  const get_faqs = async () => {
    // start loading
    setLoading(true);

    // set variable
    let params = new URLSearchParams();
    params.set("category_id", slug);

    // request
    await getFAQ(params).then((res) => {
      if (res) {
        setFaqs(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_faqs();

    // eslint-disable-next-line
  }, []);

  let array = [
    {
      title: "سرخ کن های بدون ترموستات مناسب استفاده در چه جاهایی می باشد ؟",
      value:
        "مناسب برای استفاده در اغذیه فروشی های کوچک، بوفه ها و در کل مکان های کم مصرف می باشد.",
    },
    {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
    },
    {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
    },
    {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
    },
    {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
    },
  ];
  return (
    <>
      {!!faqs.length && (
        <div className="related-questions gutter-b">
          <BeforeText text="پرسش های متداول" />

          <Card className="no-border">
            <Card.Body className="py-0">
              <Accordion defaultActiveKey="0">
                {array.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.value}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

RelatedQuestions.propTypes = {};

export default RelatedQuestions;
