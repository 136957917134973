import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Breadcrumb({ array, home = true, main = "app" }) {
  return (
    <div className="breadcrumb">
      <p className="truncate">
        {home && <Link to="/app">خانه</Link>}
        {array.map((item) => (
          <Link to={`/${main}/${item.type}/${item.id}`} key={item.id}>
            {item.title}
          </Link>
        ))}
      </p>
    </div>
  );
}

Breadcrumb.propTypes = {
  array: PropTypes.array.isRequired,
  main: PropTypes.string,
};

export default Breadcrumb;
