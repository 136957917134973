import React from "react";
import PropTypes from "prop-types";

function Review({product}) {
  return (
    <div className="review">
      <p className="title">نقد و بررسی</p>

      <div className="right-space">
        <p className="text-bolder">
          توضیحات {product.title}
        </p>

        <div className="review" dangerouslySetInnerHTML={{ __html: product.review }}>
          
        </div>
      </div>
    </div>
  );
}

Review.propTypes = {
  product: PropTypes.string.isRequired,
};

export default Review;
