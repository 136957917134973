import React from "react";
import PropTypes from "prop-types";

//
import { Link } from "react-router-dom";

function ParallelogramBox({ url, text, ellipsis = false, gray = false }) {
  return (
    <>
      {url ? (
        <Link
          to={url}
          className={`parallelogram text-white ${gray ? "gray" : ""}`}
        >
          <p className={ellipsis ? "truncate" : ""}>{text}</p>
        </Link>
      ) : (
        <a className={`parallelogram text-white ${gray ? "gray" : ""}`}>
          <p className={ellipsis ? "truncate" : ""}>{text}</p>
        </a>
      )}
    </>
  );
}

ParallelogramBox.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  ellipsis: PropTypes.bool,
  gray: PropTypes.bool,
};

export default ParallelogramBox;
