import React from "react";
import PropTypes from "prop-types";

//
import SkeletopBox from "../globals/SkeletopBox";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

function MainSlider({ loading, banners }) {
  return (
    <div className="main-slider">
      {loading ? (
        <SkeletopBox height={250} />
      ) : (
        <Swiper
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
        >
          {banners.slider &&
            banners.slider.map((item) => (
              <SwiperSlide key={item.id}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img src={item.image} alt="slider" />
                </a>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
}

MainSlider.propTypes = {
  loading: PropTypes.bool.isRequired,
  banners: PropTypes.object.isRequired,
};

export default MainSlider;
