import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

//
import { getConfigs } from "../redux/features/main/actions";
import { getBlogCategories } from "../redux/features/blog/actions";

//
import { Col, Container, Row } from "react-bootstrap";

//
import BlogHeader from "./components/blog/BlogHeader";
import BlogNavbar from "./components/blog/BlogNavbar";
import BlogSidebar from "./components/blog/BlogSidebar";
import MainLoading from "../components/globals/MainLoading";

function Blog({
  blogTopicTitle,
  configs,
  getConfigs,
  blogCategories,
  getBlogCategories,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    if (!blogCategories.length) {
      await getBlogCategories();
    }
    if (!Object.keys(configs).length) {
      await getConfigs();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="blog">
      {loading && <MainLoading />}

      <BlogHeader />
      <BlogNavbar />
      <Container>
        {blogTopicTitle && (
          <h5 className="topic-title pb-5">{blogTopicTitle}</h5>
        )}
        <Row>
          <Col md={9}>
            <Outlet />
          </Col>
          <Col md={3} className="sidebar-holder">
            <BlogSidebar />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Blog.propTypes = {
  blogTopicTitle: PropTypes.string.isRequired,
  configs: PropTypes.object.isRequired,
  blogCategories: PropTypes.array.isRequired,
  getBlogCategories: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getConfigs,
  getBlogCategories,
};

const mapStateToProps = (state) => ({
  blogTopicTitle: state.themeReducer.blogTopicTitle,
  configs: state.mainReducer.configs,
  blogCategories: state.blogReducer.blogCategories,
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
