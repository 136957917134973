import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import HeaderOffconvas from "./HeaderOffconvas";
import { Spinner, Badge } from "react-bootstrap";
import { searchProduct } from "../../redux/features/main/actions";

function Header({ show, user, categories, configs, cart }) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [showResult, setShowResult] = useState(false);

  /**
   * Search Products
   */
  const search_product = async () => {
    if (search) {
      // start loading
      setLoading(true);

      // variable
      let params = new URLSearchParams();
      params.set("search_query", search);
      params.set("page_size", 20);
      params.set("page", 1);

      // request
      await searchProduct(params).then((res) => {
        if (res) {
          setSearchResult(res.result);
        }
      });

      // stop loading
      setLoading(false);
    }
  };

  useEffect(() => {
    search_product();

    // eslint-disable-next-line
  }, [search]);

  return (
    <header id="head_header" className={!show ? "d-none" : ""}>
      {showResult && (
        <div className="click-box" onClick={() => setShowResult(false)}></div>
      )}

      <div className="header-top">
        <div className="container position-relative">
          <HeaderOffconvas />
          {/* Logo */}
          <Link
            className="head_logo_a"
            style={{ background: `url(${configs.LOGO_HEADER})` }}
            to="/app"
          ></Link>

          {/* Basket Icon */}
          <div className="cart-user">
            <Link to="/cart">
              {!!cart.length && <Badge  bg="primary" className="cart-badge">{cart.length}</Badge>}
              <i className="fas fa-dolly"></i>
              <p>سبدخرید</p>
            </Link>
          </div>

          {/* Login Icon */}
          <div className="cart-user">
            <Link to={user.id ? "/panel" : "/auth/login"}>
              <i className="fas fa-user"></i>
              <p>{user.id ? "پروفایل" : "ورود"}</p>
            </Link>
          </div>
          {/* Mobile Toggle */}
          <div
            aria-controls="navbar-main-menu"
            aria-expanded="false"
            className="navbar-toggler  navbar-menu-button"
            data-target="#navbar-main-menu"
            data-toggle="collapse"
            role="button"
          >
            <div className="list-menu vnicon-list-menu"></div>
            <div className="exit-menu vnicon-exit-menu"></div>
          </div>
          {/* Search Bar */}
          <div className={`header-search-btn ${showResult ? "active" : ""}`}>
            <i
              className="fas fa-search"
              onClick={() => setShowResult(true)}
            ></i>

            <div className="search-field">
              <ul className="search-res-parent">
                {search ? (
                  <>
                    {loading ? (
                      <div className="loading">
                        <Spinner
                          animation="border"
                          variant="black"
                          size="sm"
                          className="mb-2"
                        />
                        در حال جستجو
                      </div>
                    ) : (
                      <>
                        {searchResult.length ? (
                          <>
                            {searchResult.map((item) => (
                              <li
                                className="search-result"
                                key={item.id}
                                onClick={() => setShowResult(false)}
                              >
                                <Link to={`/app/product/${item.id}`}>
                                  {item.title}
                                </Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <li className="search-result">
                            نتایج جستجو برای {search}
                          </li>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <li className="popular-search">
                    <h3 className="popular-title">دسترسی سریع</h3>
                    {categories.map((item) => (
                      <Link
                        onClick={() => setShowResult(false)}
                        key={item.id}
                        className="popular-tag"
                        to={`/app/category-info/${item.id}`}
                        title={item.title}
                      >
                        {item.title}
                      </Link>
                    ))}
                  </li>
                )}
              </ul>

              <input
                autoComplete="off"
                className="search-input"
                id="Search-Text"
                name="search"
                placeholder={configs.SEARCH_PLACEHOLDER}
                type="text"
                onClick={() => setShowResult(true)}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </header>
  );
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  configs: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  categories: state.mainReducer.categories,
  configs: state.mainReducer.configs,
  cart: state.cartReducer.cart,
});

export default connect(mapStateToProps)(Header);
