import React from "react";
import PropTypes from "prop-types";

function NothingToShow({ text }) {
  return (
    <div id="nothing-to-show">
      <p>{text}</p>
    </div>
  );
}

NothingToShow.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NothingToShow;
