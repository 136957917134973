import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Chart from "react-apexcharts";

import useCurrency from "../../../hooks/useCurrency";

//
import { getProductPriceChart } from "../../../redux/features/main/actions";

function PriceChart({ product }) {
  const [currencyTitle] = useCurrency();

  const [key, setKey] = useState(0);
  const [option, setOption] = useState({
    series: [
      {
        name: "قیمت",
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    markers: {
      size: 5,
    },
    stroke: {
      width: 1.5,
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      y: {
        title: {
          formatter: (seriesName) => currencyTitle(),
        },
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return `${value} `;
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: 50,
        style: {
          colors: "#666666",
          fontSize: "10px",
        },
      },
      title: {
        text: `قیمت (${currencyTitle()})`,
        rotate: -90,
        offsetX: -100,
        offsetY: 0,
        style: {
          fontSize: "11px",
          fontFamily: "iranyekan",
        },
      },
    },
    xaxis: {
      tickPlacement: "between",
      labels: {
        style: {
          colors: "#666666",
          fontSize: "10px",
        },
      },
      categories: [],
    },
    grid: {
      padding: {
        left: 100,
      },
    },
  });

  /**
   * Get Product Price Chart
   */
  const get_product_price_chart = () => {
    if (product.id) {
      getProductPriceChart(product.id).then((res) => {
        if (res) {
          let oldOption = { ...option };

          oldOption.series[0].data = res.data;

          oldOption.xaxis.categories = res.labels;

          setOption(oldOption);

          setKey(key + 1);
        }
      });
    }
  };

  useEffect(() => {
    get_product_price_chart();
  }, [product]);

  return (
    <div className="price-chart">
      <p className="title">نمودار قیمت</p>

      <Chart
        options={option}
        series={option.series}
        type="line"
        height={320}
        width={"100%"}
        key={key}
      />
    </div>
  );
}

PriceChart.propTypes = {};

export default PriceChart;
