import React, { useState } from "react";
import PropTypes from "prop-types";

//
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";

//
import { changePassword } from "../../redux/features/user/actions";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
  password_confirmation: yup
    .string()
    .required("لطفا تکرار رمز عبور خود را وارد کنید")
    .oneOf([yup.ref("password"), null], "رمز عبور ها باید یکسان باشند"),
});

function ChangePassword(props) {
  const [loading, setLoading] = useState(false);

  /**
   * Change Password
   */
  const change_password = async (e) => {
    // start laoding
    setLoading(true);

    // request
    await changePassword(e);

    // stop loading
    setLoading(false);
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={change_password}
        initialValues={{
          password: "",
          password_confirmation: "",
        }}
      >
        {({ handleSubmit, handleChange, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              {/* New Password */}
              <Col md={6}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik01"
                >
                  <Form.Label className="bold">
                    کلمه عبور جدید
                    <span className="text-danger me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    className="form-control-solid big"
                    onChange={handleChange}
                    autoComplete="off"
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/* New Password Confirmation */}
              <Col md={6}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik02"
                >
                  <Form.Label className="bold">
                    تکرار کلمه عبور جدید
                    <span className="text-danger me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password_confirmation"
                    className="form-control-solid big"
                    onChange={handleChange}
                    autoComplete="off"
                    isInvalid={
                      touched.password_confirmation &&
                      !!errors.password_confirmation
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password_confirmation}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button
              type="submit"
              variant="primary"
              className="text-white submit-button d-block me-auto"
            >
              تغییر کلمه عبور
              {loading && (
                <div className="spinner-holder">
                  <Spinner animation="border" variant="white" size="sm" />
                </div>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ChangePassword.propTypes = {};

export default ChangePassword;
