import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Card, Col, Container, Row } from "react-bootstrap";

//
import StepsNavbar from "./StepsNavbar";
import CartProducts from "./CartProducts";
import SendMethods from "./SendMethods";
import PaymentMethods from "./PaymentMethods";
import ShowSplitedItems from "./ShowSplitedItems";

import RegisterPassword from "../../pages/auth/RegisterPassword";
import Login from "../../pages/auth/Login";
import OtpRecovery from "../../pages/auth/OtpRecovery";
import UserNeedInfo from "../../pages/auth/UserNeedInfo";
import UserSetPassword from "../../pages/auth/UserSetPassword";

//
import { cartSteps } from "../../constances";

function CartBox({ user }) {
  const [step, setStep] = useState(1);
  const [authStep, setAuthStep] = useState("register");

  useEffect(() => {
    if (user.id) {
      if (user.first_name && !user.has_password) {
        setAuthStep("noPassword");
      } else if (!user.first_name) {
        setAuthStep("noName");
      } else if (!user.name && !user.has_password) {
        setAuthStep("noInfo");
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <Container>
        <Card className="cart-holder box-shadow">
          <Card.Body>
            <StepsNavbar step={step} steps={cartSteps} setStep={setStep} />

            <Card className="cart-container black-border">
              <Card.Body>
                {step === 1 && (
                  <CartProducts
                    step={step}
                    nextStep={() => setStep(step + 1)}
                  />
                )}
                {step === 2 && (
                  <>
                    {user.first_name && user.has_password ? (
                      <SendMethods
                        step={step}
                        nextStep={() => setStep(step + 1)}
                      />
                    ) : (
                      <Row className="justify-content-center">
                        <Col md={6}>
                          {authStep === "register" && (
                            <RegisterPassword cart setAuthStep={setAuthStep} />
                          )}

                          {authStep === "login" && (
                            <Login cart setAuthStep={setAuthStep} />
                          )}

                          {authStep === "forget" && (
                            <OtpRecovery cart setAuthStep={setAuthStep} />
                          )}

                          {authStep === "noInfo" && (
                            <UserNeedInfo cart password />
                          )}

                          {authStep === "noName" && <UserNeedInfo cart />}

                          {authStep === "noPassword" && <UserSetPassword />}
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {step === 3 && (
                  <ShowSplitedItems
                    step={step}
                    nextStep={() => setStep(step + 1)}
                  />
                )}
                {step === 4 && (
                  <PaymentMethods
                    step={step}
                    nextStep={() => setStep(step + 1)}
                  />
                )}
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Container>
    </main>
  );
}

CartBox.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(CartBox);
