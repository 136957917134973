import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Get Currencies
 */
export const getCurrencies = createAsyncThunk("getCurrencies", async () => {
  try {
    const { data } = await axios.get("common/currencies");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Get Configs
 */
export const getConfigs = createAsyncThunk("getConfigs", async () => {
  try {
    const { data } = await axios.get("configs");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Get Footer Menus
 */
export const getFooterMenus = createAsyncThunk("getFooterMenus", async () => {
  try {
    const { data } = await axios.get("menu-items/footer");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Get Categories
 */
export const getCategories = createAsyncThunk("getCategories", async () => {
  try {
    const { data } = await axios.get("categories");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Get Featured Products
 */
export const getFeaturedProducts = async () => {
  try {
    const { data } = await axios.get("products/featured");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Home Banners
 */
export const getBanners = async () => {
  try {
    const { data } = await axios.get("banners");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Category Blocks
 */
export const getCategoryBlocks = async () => {
  try {
    const { data } = await axios.get("categories/blocks");

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Category Info
 * @param {category slug} payload
 */
export const getCategoryInfo = async (payload) => {
  try {
    const { data } = await axios.get(`categories/${payload}`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Search Product
 * @param {search params} payload
 */
export const searchProduct = async (payload) => {
  try {
    const { data } = await axios.get(`products/search`, {
      params: payload,
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product
 * @param {product slug} payload
 */
export const getProduct = async (payload) => {
  try {
    const { data } = await axios.get(`products/${payload}`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Comment
 * @param {product slug , page} payload
 */
export const getProductComments = async (payload) => {
  try {
    const { data } = await axios.get(`products/${payload.slug}/comments`, {
      params: {
        page: payload.page,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Set Product Comment Question
 * @param {product_id, type: questions || comments, body} payload
 */
export const setProductCommentQuestion = async (payload) => {
  try {
    await axios.post(`products/${payload.id}/${payload.type}`, payload.content);

    //
    toast.success(
      `${payload.type === "questions" ? "پرسش" : "نظر"} شما با موفقیت ارسال شد`,
      {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"></i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons"></i>
        ),
      }
    );

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Questions
 * @param {product slug , page} payload
 */
export const getProductQuestions = async (payload) => {
  try {
    const { data } = await axios.get(`products/${payload.slug}/questions`, {
      params: {
        page: payload.page,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Prices
 * @param {product slug, selected options} payload
 */
export const getProductPrices = async (payload) => {
  try {
    const { data } = await axios.get(
      `products/${payload.slug}/prices/${payload.option}`
    );

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Price Chart
 * @param {product slug} payload
 */
export const getProductPriceChart = async (payload) => {
  try {
    const { data } = await axios.get(`products/${payload}/price-chart`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Related Products
 * @param {product id} payload
 */
export const getRelatedProducts = async (payload) => {
  try {
    const { data } = await axios.get(`products/${payload}/related`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get FAQs
 * @param {categury_id or type: seller || customer} payload
 * @returns
 */
export const getFAQ = async (payload) => {
  try {
    const { data } = await axios.get("frequent-questions", {
      params: payload,
    });

    //
    let faq = data;

    //
    return faq;
  } catch (error) {
    return false;
  }
};

/**
 * Get Static Page
 * @param {page slug} payload
 */
export const getStaticPage = async (payload) => {
  try {
    const { data } = await axios.get(`pages/${payload}`);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Contries
 */
export const getCountries = async () => {
  try {
    const { data } = await axios.get("locations/countries");

    //
    let countries = data;
    let list = [];

    //
    for (let i = 0; i < countries.length; i++) {
      const element = countries[i];
      list.push({
        label: element.title,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};

/**
 * Get Provinces OF Country
 * @param {country_id} payload
 */
export const getProvinces = async (payload) => {
  try {
    const { data } = await axios.get(
      `locations/countries/${payload}/provinces`
    );

    //
    let province = data;
    let list = [];

    //
    for (let i = 0; i < province.length; i++) {
      const element = province[i];
      list.push({
        label: element.name,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};

/**
 * Get Cities OF Province
 * @param {province_id} payload
 */
export const getCities = async (payload) => {
  try {
    const { data } = await axios.get(`locations/provinces/${payload}/cities`);

    //
    let cities = data;
    let list = [];

    //
    for (let i = 0; i < cities.length; i++) {
      const element = cities[i];
      list.push({
        label: element.name,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};
