import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import { Col, Container, Row } from "react-bootstrap";

//
import SkeletopBox from "../globals/SkeletopBox";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function MiddleBanners({ banners, loading, categories }) {
  return (
    <div className="home-top-box middle-banner">
      {/* Box For Up 768px */}
      <Container>
        <Row className="d-none d-md-flex">
          {loading ? (
            <>
              <Col>
                <SkeletopBox height={150} />
              </Col>
              <Col>
                <SkeletopBox height={150} />
              </Col>
              <Col>
                <SkeletopBox height={150} />
              </Col>
              <Col>
                <SkeletopBox height={150} />
              </Col>
            </>
          ) : (
            <>
              {banners.hometop &&
                banners.hometop.map((item) => (
                  <Col key={item.id} className="item banner">
                    {item.link.includes("http") ? (
                      <a href={item.link} className="w-100">
                        <img src={item.image} alt="image-banner" />
                      </a>
                    ) : (
                      <Link
                        to={
                          item.link.charAt(0) == "/"
                            ? item.link
                            : `/${item.link}`
                        }
                        className="w-100"
                      >
                        <img src={item.image} alt="image-banner" />
                      </Link>
                    )}
                  </Col>
                ))}
            </>
          )}
        </Row>
      </Container>

      {/* Small Box For Donw 576px */}
      {/* <div className="mobile-category-site">
        <Container>
          <Swiper slidesPerView={3} spaceBetween={10}>
            {categories.map((item) => (
              <SwiperSlide key={item.id}>
                <Link to={`category-info/${item.id}`} title={item.title}>
                  {item.cover_image && item.cover_image !== "empty" ? (
                    <img alt={item.title} src={item.cover_image} />
                  ) : (
                    <SkeletopBox width={55} height={55} borderRadius={50} />
                  )}
                  <div className="mobile-category-site-title">{item.title}</div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div> */}

      {/* Slider For Down 768px */}
      <div className="d-block d-md-none banner-slider-box">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={10}
          breakpoints={{
            400: {
              slidesPerView: 1.8,
            },
            500: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
          }}
        >
          {loading ? (
            <>
              <SwiperSlide>
                <div className="item banner">
                  <SkeletopBox height={177} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item banner">
                  <SkeletopBox height={177} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item banner">
                  <SkeletopBox height={177} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item banner">
                  <SkeletopBox height={177} />
                </div>
              </SwiperSlide>
            </>
          ) : (
            <>
              {banners.hometop &&
                banners.hometop.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="item banner">
                      <Link to={item.link}>
                        <img src={item.image} alt="image-banner" />
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
            </>
          )}
        </Swiper>
      </div>
    </div>
  );
}

MiddleBanners.propTypes = {
  loading: PropTypes.bool.isRequired,
  banners: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.mainReducer.categories,
});

export default connect(mapStateToProps)(MiddleBanners);
