import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function UserInfo({ user }) {
  return (
    <Card className="no-radius dark-border user-info">
      <Card.Body>
        <Link to="profile" className="text-primary mb-3 d-inline-block">
          ویرایش پروفایل
        </Link>
        <Row>
          
          <Col md={6} className="mb-3">
            <span>نام و نام خانوادگی : </span>
            <span>
              {user.first_name} {user.last_name}
            </span>
          </Col>

          <Col md={6} className="mb-3">
            <span>ایمیل : </span>
            <span>{user.email}</span>
          </Col>

          <Col md={6} className="mb-3 mb-md-0">
            <span>کد ملی : </span>
            <span>{user.identify_code}</span>
          </Col>

          <Col md={6} className="mb-0">
            <span>تلفن همراه : </span>
            <span>{user.mobile}</span>
          </Col>

        </Row>
      </Card.Body>
    </Card>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProp)(UserInfo);
