import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { setUser } from "../../redux/features/user/slice";
import { getCurrentUser, updateUserInfo } from "../../redux/features/user/actions";
import { getAddress } from "../../redux/features/user/actions";

//
import Select from "react-select";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";

// React Dropzone
import { useDropzone } from "react-dropzone";

//
import AddImageSvg from "../../assets/img/addphoto.svg";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  company_name: yup.string().required(),
  billing_address: yup.object().required(),
});

function UpdateLegalInfo({ user,setUser,  getCurrentUser }) {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false)

  const [address, setAddress] = useState([]);
  const [initialValues, setInitialValues] = useState({
    company_name: "",
    billing_address: {},
  });

  /**
   * Update Legal Info
   */
  const update_legal = async (e) => {
    console.log(e);
    // start loading 
    setBtnLoading(true)

    // variable
    let fd = new FormData();
    fd.append("type", "legal");
    fd.append("_method", "put");
    fd.append('billing_address_id' , e.billing_address.value)
    fd.append('company_name', e.company_name);

    if (files.length) {
      fd.append("identify_file", files[0]);
    }

    // request
    await updateUserInfo({fd}).then((res) => {
      if (res) {
        setUser(res);
        setFiles([]);
      }
    });

    // stop loading
    setBtnLoading(false);

  };

  const setValue = (e) => {
    let values = { ...initialValues };
    let name = e.target.name;
    let value = e.target.value;

    values[name] = value;

    setInitialValues(values);
  };

  const changeAnswerValue = (name, value) => {
    let values = { ...initialValues };

    values[name] = value;

    setInitialValues(values);
  };

  const set_initial_values = () => {
    let initial = { ...initialValues };
    initial.company_name = user.company_name;

    if (user.billing_address_id) {
      for (let i = 0; i < address.length; i++) {
        const element = address[i];
        if (element.id === user.billing_address_id) {
          initial.billing_address = {
            value: element.id,
            label: element.title,
          };
        }
      }
    }

    setInitialValues(initial);
  };

  const get_address = () => {
    getAddress().then((res) => {
      if (res) {
        let array = [];

        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          array.push({
            value: element.id,
            label: element.title,
          });
        }

        setAddress(array);
      }
    });
  };

  /**
   * Dropzone
   */
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  /**
   * Remove Image
   */
  const removeImage = (image) => {
    let filesArray = [...files];

    filesArray = filesArray.filter((item) => item != image);

    setFiles(filesArray);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(async () => {
    setLoading(true);
    await getCurrentUser();
    await get_address();
    set_initial_values();
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="update-legal-info">
      <Formik
        validationSchema={schema}
        onSubmit={update_legal}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, touched, errors, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              {/* Company Name */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik01"
                >
                  <Form.Label className="bold">
                    نام سازمان
                    <span className="text-danger me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    className="form-control-solid big"
                    onChange={(e) => {
                      handleChange(e);
                      setValue(e);
                    }}
                    autoComplete="off"
                    value={values.company_name}
                    isInvalid={touched.company_name && !!errors.company_name}
                  />
                </Form.Group>
              </Col>

              <Col md={6} lg={4}>
                {/* Country */}
                <Form.Group
                  className="form-group"
                  controlId="validationFormik04"
                >
                  <Form.Label className="bold">
                    آدرس صورت حساب
                    <span className="text-danger me-1">*</span>
                  </Form.Label>
                  <Select
                    className="react-select"
                    isRtl={true}
                    isSearchable={true}
                    name="billing_address"
                    options={address}
                    placeholder=""
                    value={values.billing_address}
                    noOptionsMessage={() => "آدرس یافت نشد"}
                    onChange={(e) => changeAnswerValue("billing_address", e)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <section className="dropzone">
              <div>
                {files.length ? (
                  <div className="thump-box">
                    {files.map((file, index) => (
                      <div className="thump" key={file.name}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="item">
                                  <img src={file.preview} alt="" />
                                </div>
                              </td>
                              <td className="text-end">
                                <span className="size">
                                  {formatBytes(file.size)}
                                </span>
                              </td>
                              <td>
                                <div className="icons">
                                  <i
                                    className="fas fa-times-circle text-danger"
                                    onClick={() => removeImage(file)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div {...getRootProps({ className: "input-text" })}>
                    <input {...getInputProps()} />
                    <img src={AddImageSvg} alt="add-image" />
                    <p className="title">مدارک</p>
                    <Button variant="dark">اضافه کردن</Button>
                  </div>
                )}
              </div>
            </section>

            <Button
              type="submit"
              variant="primary"
              className="text-white submit-button d-block me-auto"
            >
              ذخیره
              {btnLoading && (
                <div className="spinner-holder">
                  <Spinner animation="border" variant="white" size="sm" />
                </div>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

UpdateLegalInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setUser,
  getCurrentUser,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLegalInfo);
