import React, { useEffect } from "react";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Tab, Tabs } from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";
import OrdersTab from "../../components/panel/OrdersTab";

function Orders(props) {
  const [seo, set_seo] = useSeo();

  useEffect(async () => {
    set_seo({}, "سفارشات");

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />
      <div id="orders">
        <div className="page-title mb-3 mt-5 mt-md-3">لیست سفارشات</div>

        <Card className="dark-border ">
          <Tabs defaultActiveKey="not-expired-orders">
            <Tab eventKey="not-expired-orders" title="سفارشات">
              <OrdersTab />
            </Tab>
            <Tab eventKey="expired-orders" title="سفارشات منقضی شده">
              <OrdersTab expiredOrders />
            </Tab>
          </Tabs>
        </Card>
      </div>
    </>
  );
}

export default Orders;
