import React from "react";
import PropTypes from "prop-types";

//
import BeforeText from "./BeforeText";

//
import Product from "../category/Product";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function TitleSwiper({ items,text, simple = false }) {

  return (
    <div className="title-swiper">
      <BeforeText text={text} simple={simple} />

      <Swiper
        slidesPerView={1.2}
        spaceBetween={10}
        breakpoints={{
          400: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 6,
          },
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <Product item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

TitleSwiper.propTypes = {
  text: PropTypes.string.isRequired,
  simple: PropTypes.bool,
};

export default TitleSwiper;
