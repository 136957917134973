import React, { useEffect, useState } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";

//
import NumberFormat from "react-number-format";

//
import { chargeUserWallet } from "../../redux/features/user/actions";

function WalletCardSalable({
  item,
  walletsIdsList,
  refreshWallets,
  openConvertModal,
}) {
  const [amount, setAmount] = useState("");
  const [selectedConfig, setSelectedConfig] = useState("");
  const [configList, setConfigList] = useState([]);
  const [loading, setLoading] = useState(false);

  const chargeWallet = async () => {
    // start loading
    setLoading(true);

    // variable
    const id = item.id;
    let content = {
      config_key: selectedConfig,
      amount: amount,
    };

    // request
    await chargeUserWallet({ id, content }).then((res) => {
      if (res) {
        refreshWallets();
      }
    });

    // stop loading
    setLoading(false);
  };

  const setConfigsList = () => {
    let list = [];
    for (const [key, value] of Object.entries(item.currency.config_decode)) {
      let currency_value = [];
      for (let i = 0; i < value.length; i++) {
        const currency = value[i];
        currency_value.push(
          `${walletsIdsList[currency.currency_id]} ${currency.amount}`
        );
      }

      let stringed_currency_value = currency_value.toString();

      let replaced_stringed_currency_value = stringed_currency_value.replaceAll(
        ",",
        " + "
      );

      list.push({
        id: key,
        values: `${item.currency.title} 1 = ${replaced_stringed_currency_value}`,
      });
    }

    setConfigList(list);
  };

  useEffect(() => {
    setConfigsList();
  }, []);

  return (
    <Card className="wallet-card">
      <Card.Body className="p-4">
        <div className="d-flex align-items-center justify-content-between title">
          <h5 className="text-white ">{item.currency.title}</h5>

          <small
            className="text-white pointer"
            onClick={() => openConvertModal(item.id)}
          >
            تبدیل موجودی
          </small>
        </div>

        <p className="wallet-item-title text-white">
          موجودی {item.currency.postfix}:{" "}
          <NumberFormat
            value={+item.amount}
            displayType="text"
            thousandSeparator={true}
            className="price text-bold"
          />{" "}
          {item.currency.title}
        </p>

        <div className="w-100 mt-4 mb-3">
          <Form.Select
            className="form-control-solid"
            autoComplete="off"
            value={selectedConfig}
            onChange={(e) => setSelectedConfig(e.target.value)}
            readOnly={item.currency.status === "disable"}
          >
            <option>مدل تبدیل را انتخاب کنید</option>
            {configList.map((config) => (
              <option value={config.id} key={config.id}>
                {config.values}
              </option>
            ))}
          </Form.Select>
        </div>

        <div className="w-100 mb-3">
          <Form.Control
            type="number"
            min="0.0000001"
            step="0.0000001"
            className="form-control-solid number"
            placeholder="مقدار"
            autoComplete="off"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            readOnly={item.currency.status === "disable"}
          />
        </div>

        <Button
          variant="white"
          className="w-100"
          disabled={loading || !+amount || !selectedConfig}
          onClick={() => chargeWallet()}
        >
          افزایش اعتبار
          {loading && (
            <div className="spinner-holder">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          )}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default WalletCardSalable;
