import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//
import { getConfigs } from "../redux/features/main/actions";

//
import { Button } from "react-bootstrap";

function NotFound({ configs, getConfigs }) {
  let navigate = useNavigate();

  useEffect(() => {
    getConfigs();

    // eslint-disable-next-line
  }, []);

  return (
    <div id="not-found">
      {configs.LOGO_HEADER && <img src={configs.LOGO_HEADER} alt="logo" />}

      <h5 className="mt-5 mb-4 text-center">صفحه مورد نظر پیدا نشد</h5>

      <Button
        variant="outline-danger"
        className="pill"
        onClick={() => navigate("/app")}
      >
        بازگشت
      </Button>
    </div>
  );
}

NotFound.propTypes = {
  configs: PropTypes.object.isRequired,
  getConfigs: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getConfigs,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
