import { useState } from "react";

const useSeo = () => {
    const [seo, setSeo] = useState({});

    const set_seo = (seo, title) => {
        if (seo) {
            const { title: seoTitle, description, keywords, canonical } = seo;

            setSeo({
                title: seoTitle ? seoTitle : title,
                description,
                keywords,
                canonical: canonical ? canonical : window.location.href,
            });
        } else {
            setSeo({
                title,
                canonical: window.location.href,
            });
        }
    };

    return [seo, set_seo];
};

export default useSeo;