import React from "react";
import PropTypes from "prop-types";

//
import { Card } from "react-bootstrap";

//
import BeforeText from "../globals/BeforeText";
import SkeletopBox from "../globals/SkeletopBox";

function DescriptionBox({ info, loading }) {
  return (
    <div className="description-box gutter-b">
      <BeforeText text={info.title} />

      {loading ? (
        <SkeletopBox height={200} />
      ) : (
        <Card className="box-shadow">
          <Card.Body
            dangerouslySetInnerHTML={{ __html: info.description }}
          ></Card.Body>
        </Card>
      )}
    </div>
  );
}

DescriptionBox.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DescriptionBox;
