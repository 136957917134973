import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Add To Cart
 * @param {product} payload
 */
export const addToCart = async (payload) => {
  try {
    const { data } = await axios.post("cart/items", payload);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Cart
 * @param {coupon} payload
 */
export const getCart = createAsyncThunk("getCart", async () => {
  try {
    const { data } = await axios.get("cart/items");

    //
    return data;
  } catch (error) {
    return false;
  }
});

/**
 * Change Cart Item Quantity
 */
export const changeItemQuantity = async (payload) => {
  try {
    await axios.patch("cart/items", payload);

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Delete Cart Item
 * @param {cart item id} payload
 */
export const deleteCartItem = createAsyncThunk(
  "deleteCartItem",
  async (payload) => {
    try {
      await axios.delete(`cart/items/${payload}`);

      //
      return payload;
    } catch (error) {
      return false;
    }
  }
);

/**
 * Get Splited Order Items
 *  @param {coupon , customer_address_id} payload
 */
export const getSplitedOrderItems = async (payload) => {
  try {
    const { data } = await axios.get("cart/items/split", {
      params: {
        customer_address_id: payload.customer_address_id,
        coupon: payload.coupon,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Finalize Cart
 */
export const finalizeCart = async (payload) => {
  try {
    const { data } = await axios.post("cart/finalize", payload);

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Gateways
 * @param {customer_address_id} payload
 */
export const getGateways = async (payload) => {
  try {
    const { data } = await axios.get("common/gateways", {
      params: {
        customer_address_id: payload,
      },
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Pay Order
 * @param {order_id, module_name} payload
 */
export const payOrder = async (payload) => {
  try {
    const { data } = await axios.get(
      `profile/orders/pay/${payload.order_id}/${payload.module_name}`
    );

    //
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * Offline Payment
 * @param {order_id, module_name, content} payload
 */
export const offlinePayment = async (payload) => {
  try {
    await axios.post(
      `profile/orders/pay-offline/${payload.order_id}/${payload.module_name}`,
      payload.content
    );

    //
    toast.success(`اطلاعات پرداخت شما برای مدیریت ارسال شد`, {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"></i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"></i>
      ),
    });

    //
    return true;
  } catch (error) {
    return false;
  }
};

export const paymentWithWallet = async (payload) => {
  try {
    const { data } = await axios.post(
      `profile/orders/pay-wallet/${payload.order_id}/`,
      payload.content
    );

    //
    toast.success(`پرداخت با کیف پول شما با موفقیت انجام شد`, {
      theme: "colored",
      icon: <i className="fas fa-bell toast_icons"></i>,
      closeButton: (
        <i className="fas fa-times-circle toast_close_button toast_icons"></i>
      ),
    });

    //
    return data;
  } catch (error) {
    return false;
  }
};
