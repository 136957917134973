import React, { useState } from "react";
import PropTypes from "prop-types";

//
import { Button, Card, Form, Spinner } from "react-bootstrap";

//
import { findBoxOptions } from "../../constances.js";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  answer: yup.string().required(),
  description: yup.string().required(),
});

function FindBox(props) {
  const [loading, setLoading] = useState(false);
  const [answerType, setAnswerType] = useState("");
  const [initialValues, setInitialValues] = useState({
    answer: "",
    description: "",
  });

  const changeAnswerValue = (e) => {
    let value = +e.target.value;
    let values = { ...initialValues };

    if (value) {
      values.answer = value;
    } else {
      values.answer = "";
    }

    setInitialValues(values);
  };

  const send_answer = (e) => {
    console.log(e);
  };

  return (
    <Card className="find-box">
      <Card.Body>
        <div className="question">
          <p className="ms-0 ms-sm-4">آیا موردی را که به دنبالش بودید، پیدا کردید؟</p>

          <div className="actions">
            <div
              className={`item ${answerType === "yes" ? "active" : ""}`}
              onClick={() => setAnswerType("yes")}
            >
              بله
            </div>
            <div
              className={`item ${answerType === "no" ? "active" : ""}`}
              onClick={() => setAnswerType("no")}
            >
              خیر
            </div>
          </div>
        </div>

        {answerType && (
          <div className="content">
            <Formik
              validationSchema={yup.object().shape(
                answerType === "yes"
                  ? {
                      answer: yup.string().required(),
                      description: yup.string().required(),
                    }
                  : {
                      description: yup.string().required(),
                    }
              )}
              onSubmit={send_answer}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ handleSubmit, handleChange, touched, errors, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {answerType === "yes" && (
                    <Form.Group
                      className="form-group"
                      controlId="validationFormik01"
                    >
                      <Form.Select
                        className="form-control right-border"
                        onChange={changeAnswerValue}
                        name="answer"
                        isInvalid={touched.answer && !!errors.answer}
                      >
                        {findBoxOptions.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}

                  <Form.Group
                    className="form-group"
                    controlId="validationFormik02"
                  >
                    <Form.Label>
                      ما دوست داریم راجع به تجربه جستجو از شما بشنویم . نظرات
                      شما به ما کمک می کند سایت را بهبود ببخشیم.
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="4"
                      type="text"
                      name="description"
                      onChange={handleChange}
                      autoComplete="off"
                      className="right-border"
                      values={values.description}
                      isInvalid={touched.description && !!errors.description}
                    />
                  </Form.Group>

                  <Button type="submit" variant="primary" className="text-white">
                    ارسال
                    {loading && (
                      <div className="spinner-holder">
                        <Spinner animation="border" variant="white" size="sm" />
                      </div>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

FindBox.propTypes = {};

export default FindBox;
