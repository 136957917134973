import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { Card, Col, Row, Spinner, Dropdown } from "react-bootstrap";
import NumberFormat from "react-number-format";

//
import ParallelogramBox from "../globals/ParallelogramBox";
import InfoItems from "./InfoItems";
import DeliveryType from "./DeliveryType";
import ImageGallery from "./ImageGallery";
import ProductInquireModal from "./infos/ProductInquireModal";

//
import { addToCart } from "../../redux/features/cart/actions";

import { sendQoute } from "../../redux/features/user/actions";
import { connect } from "react-redux";

function MainInfo({
  user,
  setShow,
  product,
  selectedSeller,
  options,
  updateOptions,
  updateIsFavorite,
}) {
  let navigate = useNavigate();

  const [currencyTitle] = useCurrency();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);

  const [productInquireModalShow, setProductInquireModalShow] = useState(false);

  const scrollToPriceChart = () => {
    let element = document.getElementById("price-chart");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const changeCount = (type) => {
    if (type === "plus") {
      if (selectedSeller.inventory) {
        if (count < +selectedSeller.inventory) {
          setCount(count + 1);
        }
      } else {
        setCount(count + 1);
      }
    }

    if (type === "minus" && count != 1) setCount(count - 1);
  };

  const changeAttr = (parent, index) => {
    let attr = [...options];

    for (let i = 0; i < attr[parent].values.length; i++) {
      const element = attr[parent].values[i];
      element.selected = false;
    }

    attr[parent].values[index].selected = !attr[parent].values[index].selected;

    updateOptions(attr);
  };

  const showSelectedOption = (index) => {
    let array = options[index].values;

    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.selected) {
        return element.value;
      }
    }
  };

  /**
   * Add To Cart
   */
  const add_to_cart = async () => {
    // variable
    let content = {
      product_price_id: selectedSeller.id,
      quantity: count,
    };

    // request
    await addToCart(content).then((res) => {
      if (res) {
        navigate("/cart");
      }
    });
  };

  /**
   * Send Product Inquire
   */
  const send_product_inquire = async (user) => {
    setProductInquireModalShow(false);

    if (user.id) {
      // start loading
      setLoading(true);

      // variable
      let content = {
        quantity: count,
        product_id: product.id,
      };

      if (options.length) {
        let optionArray = [];
        for (let i = 0; i < options.length; i++) {
          const option = options[i];
          for (let i = 0; i < option.values.length; i++) {
            const element = option.values[i];
            if (element.selected) {
              optionArray.push(element.id);
            }
          }
        }
        content.option_value_ids = optionArray.toString();
      }

      // request
      await sendQoute(content);

      // stop loading
      setLoading(false);
    } else {
      setProductInquireModalShow(true);
    }
  };

  /**
   * Update Count After Selected Seller Change
   */
  const updateCountAfterSellerChange = () => {
    if (selectedSeller.inventory) {
      if (count > +selectedSeller.inventory) {
        setCount(+selectedSeller.inventory);
      }
    }
  };

  useEffect(() => {
    updateCountAfterSellerChange();
  }, [selectedSeller]);

  return (
    <Card className="main-info gutter-b">
      <Card.Body>
        <Row>
          {/* Product Image */}
          <Col md={6}>
            {product.galleries && product.galleries.length ? (
              <ImageGallery gallery={product.galleries} />
            ) : (
              <img
                src={product.main_image}
                alt={product.title}
                className="main-image"
              />
            )}
          </Col>

          {/* Product Info */}
          <Col md={6}>
            <Row>
              <Col md={11}>
                {!selectedSeller.id && (
                  <div className="no-inventory">ناموجود</div>
                )}
                <div className="title-code">
                  {product.category && (
                    <ParallelogramBox
                      url={`/app/category/${product.category.id}`}
                      text={product.category.title}
                      ellipsis
                    />
                  )}
                  {product.code && (
                    <ParallelogramBox text={product.code} gray />
                  )}
                </div>

                <p className="product-title">{product.title}</p>

                {selectedSeller.id && (
                  <div className="d-flex d-md-none align-items-center justify-content-end flex-column">
                    {selectedSeller.discount_price && (
                      <div className="d-flex align-items-center align-self-end text-decoration-line-through text-danger gap-1">
                        <small>{currencyTitle()}</small>
                        <h5>
                          <NumberFormat
                            value={+selectedSeller.discount_price}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </h5>
                      </div>
                    )}

                    <div className="d-flex align-items-center align-self-end gap-1">
                      <small>{currencyTitle()}</small>
                      <h4 className="text-black">
                        <NumberFormat
                          value={+selectedSeller.original_price}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      </h4>
                    </div>
                  </div>
                )}

                <InfoItems infos={product.category_attributes || {}} />
              </Col>

              <Col md={1} className="features d-none d-md-flex">
                <div className="share" onClick={() => setShow(true)}>
                  <i className="fas fa-share-alt"></i>
                </div>

                <div>
                  <i
                    className="fas fa-chart-line"
                    onClick={() => scrollToPriceChart()}
                  ></i>
                </div>

                <div>
                  <i
                    onClick={updateIsFavorite}
                    className={`fas fa-heart ${
                      product.is_favorite ? "text-danger" : ""
                    }`}
                  ></i>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={7}>
                <DeliveryType />
              </Col>
              {product.catalog_file && (
                <Col sm={5} className="mt-3 mt-sm-0">
                  <div className="catalogue">
                    <h6>
                      <a
                        href={product.catalog_file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        دریافت کاتالوگ
                      </a>
                    </h6>
                  </div>
                </Col>
              )}
            </Row>

            {!!options.length && (
              <Row className="options-dropdown mt-3">
                {options.map((item, index) => (
                  <>
                    {item.values.length > 1 && (
                      <Col xs="6" className="item" key={index}>
                        <span>{item.title}</span>
                        <Dropdown>
                          <Dropdown.Toggle variant="light">
                            {showSelectedOption(index)}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {item.values.map((item, i) => (
                              <Dropdown.Item
                                key={i}
                                onClick={() => changeAttr(index, i)}
                              >
                                {item.value}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}

            <Row>
              <Col
                md={selectedSeller.id ? 7 : 12}
                xxl={selectedSeller.id ? 4 : 5}
                className="order-0"
              >
                <div className="count-box mt-3">
                  <div className="counter">
                    <div className="icon-holder">
                      <i
                        className="fas fa-plus boxed-icon "
                        onClick={() => changeCount("plus")}
                      ></i>
                    </div>
                    <span className="text-center">{count}</span>
                    <div className="icon-holder text-start">
                      <i
                        className="fas fa-minus boxed-icon "
                        onClick={() => changeCount("minus")}
                      ></i>
                    </div>
                  </div>
                  {product.packaging && (
                    <p className="mx-1">
                      <span>{product.packaging.title}</span>
                      <span className="mx-1">{+product.packaging.stepper}</span>
                      <span>{product.packaging.measurement_title}</span>
                    </p>
                  )}
                </div>
              </Col>
              <Col
                md={12}
                xxl={selectedSeller.id ? 5 : 7}
                className="order-2 order-xxl-1"
              >
                <div className="shopping-btn-holder mt-0 mt-md-3">
                  {selectedSeller.id ? (
                    <div className="shopping-btn" onClick={() => add_to_cart()}>
                      <span>
                        <i className="fas fa-dolly"></i>
                      </span>
                      خرید آنلاین
                      {loading && (
                        <div className="spinner-holder">
                          <Spinner
                            animation="border"
                            variant="white"
                            size="sm"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="shopping-btn blue"
                      onClick={() => send_product_inquire(user)}
                    >
                      <span>
                        <i className="fas fa-bell"></i>
                      </span>
                      استعلام قیمت
                      {loading && (
                        <div className="spinner-holder">
                          <Spinner
                            animation="border"
                            variant="white"
                            size="sm"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Col>
              {selectedSeller.id && (
                <Col
                  md={5}
                  xxl={3}
                  className="d-none d-md-flex justify-content-end flex-column order-1 order-xxl-2"
                >
                  {selectedSeller.discount_price && (
                    <div className="d-flex align-items-center align-self-end text-decoration-line-through text-danger gap-1">
                      <small>{currencyTitle()}</small>

                      <h5>
                        <NumberFormat
                          value={+selectedSeller.discount_price}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      </h5>
                    </div>
                  )}

                  <div className="d-flex align-items-center align-self-end gap-1">
                    <small>{currencyTitle()}</small>
                    <h5 className="text-black">
                      <NumberFormat
                        value={+selectedSeller.original_price * count}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </h5>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <ProductInquireModal
        show={productInquireModalShow}
        setShow={setProductInquireModalShow}
        sendProductInquire={send_product_inquire}
      />
    </Card>
  );
}

MainInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  selectedSeller: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updateIsFavorite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(MainInfo);
