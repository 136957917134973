import { createSlice } from "@reduxjs/toolkit";
import { getNotificationsUnreadCount } from "./actions";

const initialState = {
  unreaCount: 0,
};

export const mainSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    changeNotificationCount: (state, action) => {
      state.unreaCount = action.payload;
    },
  },

  extraReducers: {
    [getNotificationsUnreadCount.fulfilled]: (state, action) => {
      let count = action.payload;

      if (count) {
        state.unreaCount = count;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeNotificationCount } = mainSlice.actions;

export default mainSlice.reducer;
