import { createSlice } from "@reduxjs/toolkit";
import { getBlogCategories } from "./actions";

const initialState = {
    blogCategories: [],
};

export const mainSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {},

    extraReducers: {
        [getBlogCategories.fulfilled]: (state, action) => {
            let categories = action.payload;

            if (categories) {
                state.blogCategories = categories;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {} = mainSlice.actions;

export default mainSlice.reducer;