import React, { useEffect } from "react";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Tab, Tabs } from "react-bootstrap";

//
import UpdateUserInfo from "../../components/panel/UpdateUserInfo";
import ChangeAvatar from "../../components/panel/ChangeAvatar";
import UpdateLegalInfo from "../../components/panel/UpdateLegalInfo";
import ChangePassword from "../../components/panel/ChangePassword";
import MetaDecorator from "../../components/globals/MetaDecorator";
function UserInfo(props) {
  const [seo, set_seo] = useSeo();

  useEffect(() => {
    set_seo({}, "ویرایش پروفایل");

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div className="page-title">پروفایل</div>

      <Card className="no-radius dark-border" id="update-user">
        <Tabs
          defaultActiveKey="personal_info"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="personal_info" title="اطلاعات شخصی">
            <UpdateUserInfo />
          </Tab>
          <Tab eventKey="profile_avatar" title="تصویر پروفایل">
            <ChangeAvatar />
          </Tab>
          <Tab eventKey="legal_info" title="اطلاعات حقوقی">
            <UpdateLegalInfo />
          </Tab>
          <Tab eventKey="change_pass" title="تغییر کلمه عبور">
            <ChangePassword />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}


export default UserInfo;
