import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser } from "./actions";

const initialState = {
    user: {},
    token: "",
};

export const userSlice = createSlice({
    name: "User",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem("user", JSON.stringify(action.payload));
        },

        setToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem("token", action.payload);
        },

        logoutUser: (state, action) => {
            state.user = "";
            localStorage.removeItem("user");

            state.token = "";
            localStorage.removeItem("token");
        },
    },

    extraReducers: {
        [getCurrentUser.fulfilled]: (state, action) => {
            let user = action.payload;

            state.user = user;
            localStorage.setItem("user", JSON.stringify(user));
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUser, setToken, logoutUser } = userSlice.actions;

export default userSlice.reducer;