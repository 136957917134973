import React, { useEffect, useMemo, useState } from "react";

//
import { Button, Form, Modal, Spinner } from "react-bootstrap";

//
import NumberFormat from "react-number-format";
import BeforeText from "../globals/BeforeText";

//
import { Decimal } from "decimal.js";

//
import { convertWalletBalance } from "../../redux/features/user/actions";

function ConvertWalletBalanceModal({
  wallets,
  selectedWalletId,
  show,
  setShow,
  refreshWallets,
}) {
  const [loading, setLoading] = useState(false);
  const [fromWallet, setFromWallet] = useState("");
  const [toWallet, setToWallet] = useState("");
  const [amount, setAmount] = useState("");

  const switchFields = () => {
    setFromWallet(toWallet);
    setToWallet(fromWallet);
  };

  const onFromWalletSelected = (id) => {
    if (id == toWallet) {
      switchFields();
    } else {
      setFromWallet(id);
    }
  };

  const onToWalletSellected = (id) => {
    if (id == fromWallet) {
      switchFields();
    } else {
      setToWallet(id);
    }
  };

  const fromWalletInfo = useMemo(() => {
    let info = {};

    if (fromWallet) {
      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        if (wallet.id == fromWallet) {
          info = wallet;

          break;
        }
      }
    }

    return info;
  }, [fromWallet]);

  const toWalletInfo = useMemo(() => {
    let info = {};

    if (toWallet) {
      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        if (wallet.id == toWallet) {
          info = wallet;

          break;
        }
      }
    }

    return info;
  }, [toWallet]);

  const convertAmount = useMemo(() => {
    let convertedAmount = new Decimal(0);

    if (amount && fromWalletInfo.currency && toWalletInfo.currency) {
      let fromConvertRate = +fromWalletInfo?.currency?.convert_rate;
      let toConvertRate = +toWalletInfo?.currency?.convert_rate;

      let from_to_base = new Decimal(+amount).times(fromConvertRate);
      convertedAmount = new Decimal(from_to_base).dividedBy(toConvertRate);
    }
    return +convertedAmount.toString();
  }, [amount, fromWalletInfo, toWalletInfo]);

  const btnDisable = () => {
    if (
      loading ||
      !amount ||
      !toWallet ||
      !fromWallet ||
      +amount > +fromWalletInfo.amount
    )
      return true;

    return false;
  };

  const resetForm = () => {
    setAmount("");
    setFromWallet("");
    setToWallet("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // start loading
    setLoading(true);

    // set variables
    let content = {
      from_wallet_id: fromWallet,
      to_wallet_id: toWallet,
      amount: amount,
    };

    // request
    await convertWalletBalance(content).then((res) => {
      if (res) {
        resetForm();
        setShow(false);
        refreshWallets();
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      setFromWallet(selectedWalletId);
    } else {
      resetForm();
    }
  }, [show]);

  return (
    <Modal backdrop="static" centered show={show} onHide={() => setShow(false)}>
      <Modal.Header
        closeButton
        className="no-border absolute-close pb-0 modal-header"
      >
        <BeforeText text="تبدیل موجودی" small />
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <div className="w-100 mb-3">
            <Form.Select
              className="form-control"
              value={fromWallet}
              onChange={(e) => onFromWalletSelected(e.target.value)}
            >
              <option>از کیف پول</option>
              {wallets.map((wallet) => (
                <option value={wallet.id} key={`from_${wallet.id}`}>
                  {wallet.currency.title}
                </option>
              ))}
            </Form.Select>

            {fromWalletInfo.currency && (
              <small
                className="text-hover-primary pointer"
                onClick={() => setAmount(fromWalletInfo.amount)}
              >
                موجودی{" "}
                <NumberFormat
                  value={+fromWalletInfo.amount}
                  displayType="text"
                  thousandSeparator={true}
                />{" "}
                {fromWalletInfo.currency.title}
              </small>
            )}
          </div>

          <div className="w-100 mb-3">
            <Form.Select
              className="form-control"
              value={toWallet}
              onChange={(e) => onToWalletSellected(e.target.value)}
            >
              <option>به کیف پول</option>
              {wallets.map((wallet) => (
                <option value={wallet.id} key={`to_${wallet.id}`}>
                  {wallet.currency.title}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="w-100 mb-3">
            <Form.Control
              type="number"
              min="0.01"
              step="0.01"
              className="form-control number"
              placeholder="مبلغ"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              autoComplete="off"
            />
            {toWalletInfo.currency && (
              <small className="convert-amount">
                معادل{" "}
                <NumberFormat
                  value={convertAmount}
                  displayType="text"
                  thousandSeparator={true}
                  className="price text-bold"
                />{" "}
                {toWalletInfo.currency.title}
              </small>
            )}
          </div>

          <Button
            type="submit"
            variant="primary"
            className="w-100"
            disabled={btnDisable()}
          >
            تبدیل
            {loading && (
              <div className="spinner-holder">
                <Spinner animation="border" variant="white" size="sm" />
              </div>
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ConvertWalletBalanceModal;
