import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

//
import { Button, Form, Spinner } from "react-bootstrap";

//
import BeforeText from "../../components/globals/BeforeText";

//
import { Formik } from "formik";
import * as yup from "yup";
import {
  emailRecoverPassword,
  sendRecoveryEmail,
} from "../../redux/features/user/actions";

//
const schema_step_1 = yup.object().shape({
  email: yup
    .string()
    .email("ایمیل وارد شده صحیح نیست")
    .required("لطفا ایمیل خود را وارد کنید"),
});

const schema_step_2 = yup.object().shape({
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
  password_confirmation: yup
    .string()
    .required("لطفا تکرار رمز عبور خود را وارد کنید")
    .oneOf([yup.ref("password"), null], "رمز عبور ها باید یکسان باشند"),
});

function EmailRecovery(props) {
  let { token } = useParams();
  let navigate = useNavigate();

  //
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setStep(2);
    }

    // eslint-disable-next-line
  }, []);

  /**
   * Send Email
   */
  const send_email = async (e) => {
    // start loading
    setLoading(true);

    // request
    await sendRecoveryEmail(e);

    // stop loading
    setLoading(false);
  };

  /**
   * Change Password
   */
  const change_password = async (e) => {
    // start loading
    setLoading(true);

    // variable
    let content = e;

    // request
    emailRecoverPassword({ token, content }).then((res) => {
      if (res) {
        setLoading(false);
        navigate("/auth/login");
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="recovery">
      <BeforeText text="بازیابی رمز عبور با ایمیل" />

      {step === 1 ? (
        <Formik
          validationSchema={schema_step_1}
          key={step}
          onSubmit={send_email}
          initialValues={{
            email: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Email */}
              <Form.Group className="form-group" controlId="validationFormik03">
                <Form.Label className="bold text-muted">ایمیل</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                variant="danger"
                size="lg"
                className="w-100 pill"
              >
                ارسال کد تایید
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          validationSchema={schema_step_2}
          key={step}
          onSubmit={change_password}
          initialValues={{
            password: "",
            password_confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Password */}
              <Form.Group className="form-group" controlId="validationFormik06">
                <Form.Label className="bold text-muted">رمز عبور</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Password Confirmation */}
              <Form.Group className="form-group" controlId="validationFormik07">
                <Form.Label className="bold text-muted">
                  تکرار رمز عبور
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password_confirmation"
                  className="form-control-solid number"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={
                    touched.password_confirmation &&
                    !!errors.password_confirmation
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                variant="danger"
                size="lg"
                className="w-100 pill"
              >
                تغییر رمز عبور
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      )}

      <p
        className="pointer mt-3 text-danger d-inline-block"
        onClick={() => navigate("/auth/otp-recovery")}
      >
        بازیابی با موبایل
      </p>
    </div>
  );
}

EmailRecovery.propTypes = {};

export default EmailRecovery;
