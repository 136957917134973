import React from "react";
import PropTypes from "prop-types";

function StepsNavbar({ step, steps, setStep, status }) {
  /**
   * Width Calculator
   */
  const widthCalculate = (step, side) => {
    let length = steps.length;
    if (step === 1 && side === "right") return `-${100 / (length - 1) / 2}%`;
    if (step === length && side === "left")
      return `-${100 / (length - 1) / 2}%`;
  };

  /**
   * Right Calc
   */
  const rightCalc = (step) => {
    let length = steps.length;
    if (step !== 1 && step !== length)
      return `${(100 / (length - 1)) * (step - 1)}%`;
  };

  const stepName = (item) => {
    if (item.step === 3) {
      return status === "canceled" ? item.otherName : item.name;
    }

    if (item.step === 5) {
      return status === "approved" ? item.otherName : item.name;
    }

    return item.name;
  };

  return (
    <div className="step-container">
      <div className="roads">
        {steps
          .filter((item) => item.step !== 1)
          .map((item) => (
            <div
              key={item.step}
              className={`step-road-label ${
                item.step <= step ? "active" : ""
              } `}
              style={{
                width: `${100 / (steps.length - 1)}%`,
              }}
            ></div>
          ))}
      </div>

      <div className="steps">
        {steps.map((item) => (
          <div
            key={item.step}
            className={`step-box ${item.step <= step ? "active" : ""} ${
              item.step === step ? "last-active" : ""
            }`}
            style={{
              width: `${100 / (steps.length - 1)}%`,
              marginRight: widthCalculate(item.step, "right"),
              marginLeft: widthCalculate(item.step, "left"),
              right: `calc(${rightCalc(item.step)} - 58px)`,
              left: item.step === steps.length ? "0" : "",
            }}
          >
            {!item.image ? (
              <>
                <p
                  className={`${item.step <= step ? "pointer" : ""} ${
                    item.step === step ? "d-block" : ""
                  }`}
                  onClick={() => item.step <= step && setStep(item.step)}
                >
                  {item.name}
                </p>
                <span
                  className={item.step <= step ? "pointer" : ""}
                  onClick={() => item.step <= step && setStep(item.step)}
                ></span>
              </>
            ) : (
              <>
                <img
                  className={item.step === step ? "d-block" : ""}
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/images/payment-steps/step-${item.step}.png`
                  }
                  alt=""
                />
                <span></span>
                <p className={item.step === step ? "d-block" : ""}>
                  {stepName(item)}
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

StepsNavbar.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
};

export default StepsNavbar;
